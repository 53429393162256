body {
  font-family: $font_main;
  color: $color_main;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

.waypoint {
  @include size(100% 1px);
  background: transparent;
  @include position(absolute, 0 0 null null);
  z-index: 10;
}

@import "typo.scss";
@import "header.scss";
@import "footer.scss";
@import "layout.scss";