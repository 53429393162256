.node-join-our-team-page {
  margin-bottom: 40px;

  .header {
    position: relative;
    background-size: cover;
    background-position: center bottom;
    z-index: 1;
    @include pad(145px 20px);

    &:after {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: rgba(255,255,255,0.69);
      z-index: -1;
    }

    .content {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;

      .title {
        font-weight: lighter;
        margin-bottom: 15px;
        font-size: em(43);
        line-height: 1.21;
        letter-spacing: -0.38px;
      }

      .text {
        font-size: em(14);
        line-height: 1.64;
      }
    }

    @include media($mobile) {
      @include pad(73px 30px 118px);

      .content {
        max-width: 100%;

        .title {
          margin-bottom: 23px;
          font-size: em(30);
          line-height: 1.06;
          letter-spacing: -0.27px;
        }

        .text {
          font-size: em(12);
          line-height: 1.6;
        }
      }
    }
  }

  .job-listing {
    max-width: 700px;
    margin: 0 auto;
    position: relative;

    .total-row {
      @include pad(24px 0 17px);
      margin-bottom: 40px;
      border-bottom: 1px solid $gray_light;
      font-family: $font_helvetica;
      color: $dark_grayish_violet;
      font-size: em(14);
    }

    .job {
      @include pad(24px 0);
      border-bottom: 1px solid $gray_light;

      &:last-of-type {
        margin-bottom: 25px;
      }
    }

    .title {
      font-weight: lighter;
      font-size: em(21);
      margin-bottom: 8px;
      line-height: 1.33;
      letter-spacing: -0.19px;
    }

    .date {
      font-size: em(13);
      margin-bottom: 21px;
      line-height: 0.9;
      letter-spacing: -0.12px;
    }

    .text {
      font-size: em(13);
      color: $dark_grayish_violet;
      margin-bottom: 25px;
      line-height: 1.5;
      max-width: 650px;
    }

    .link > a {
      text-decoration: none;
      color: $dark_very_grayish_violet;

      &:hover {
        color: $moderate_magenta;
      }
    }

    @include media($mobile) {
      max-width: 100%;
      @include pad(0 15px);

      .total-row {
        @include pad(9px 7px);
        margin-bottom: 0;
      }

      .job {
        @include pad(30px 16px);

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .title {
        font-size: em(19);
        margin-bottom: 14px;
      }

      .date {
        font-size: em(12);
      }

      .text {
        font-size: em(12);
        margin-bottom: 16px;
        line-height: 1.6;
        max-width: 100%;
      }

      .link > a {
        font-size: em(12);
      }
    }
  }

  @include media($mobile) {
    margin-bottom: 0;
  }
}