.cart-container,
.cart-empty-page {

  .messages.status {
    display: none;
  }

  @include pad(76px 0 100px);
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
  @include media($mobile) {
    padding: 35px 35px 0;
    border-top: solid 1px rgba(247, 247, 247, 1);
  }

  h1 {
    margin: 0;
    line-height: 1.44;
    letter-spacing: -0.32px;
    font-size: em(36);
    text-align: left;
  }

  h3 {
    line-height: 1.8;
    letter-spacing: -0.2px;
    font-size: em(22);
    @include media($mobile) {
      text-align: left;
      margin-top: 10px;
      width: calc(100% - 120px);
    }
  }

  h4 {
    line-height: 1.67;
    font-size: em(15);
    margin-bottom: 10px;
    @include media($mobile) {
      font-weight: lighter;
    }
  }

  div.cart-block {
    margin: 27px 0;
    width: 100%;
    @include media($mobile) {
      margin-bottom: 0;
    }
    ul {
      list-style: none;
      @include media($mobile) {
        margin-bottom: 0;
      }
    }

    ul.head {
      border-top: solid 1px $gray_light;
      display: block;
      overflow: hidden;
      padding-left: 0;

      li {
        padding: 20px 0 42px 0;
        font-weight: 600;
        font-size: em(15);
        display: block;
        float: left;

        &:nth-of-type(1) {
          width: 60%;
          text-align: left;
          @include media($mobile) {
            width: 100%;
            padding-bottom: 10px;
            font-size: em(15);
            font-weight: bold;
          }
        }
        &:nth-of-type(2) {
          width: 20%;
          text-align: center;
          @include media($mobile) {
            display: none;
          }
        }
        &:nth-of-type(3) {
          width: 20%;
          text-align: center;
          @include media($mobile) {
            display: none;
          }
        }

      }
    }

    ul.cart-line {
      border-top: none;
      vertical-align: top;
      p {
        line-height: 1.8;
      }
      @include media($mobile) {
        overflow: hidden;
        border-bottom: solid 1px $gray_light;
        margin: 0;
        &:first-of-type {
          margin: 0;
        }
        &:last-of-type {
          margin: 0;
        }
      }
      li {
        display: block;
        float: left;
        text-align: left;
        margin-bottom: 25px;

        &:nth-of-type(1) {
          width: 60%;
          @include media($mobile) {
            width: 100%;
            position: relative;
            .cart-action {
              position: absolute;
              top: 12px;
              right: 0;
            }
          }
        }
        &:nth-of-type(2) {
          text-align: center;
          width: 20%;
          @include media($mobile) {
            text-align: left;
            font-size: em(15);
          }

        }
        &:nth-of-type(3) {
          text-align: center;
          width: 20%;
          @include media($mobile) {
            text-align: left;
            padding-bottom: 35px;
            font-size: em(15);
          }

        }
      }
    }
  }
  .cart-line {
    margin: 0;
    padding: 0;
    @include media($mobile) {
      display: block;
    }
  }

  .totals-line {
    padding: 20px 0;
    border-bottom: solid 1px #f2f2f2;
    width: 100%;
    overflow: hidden;
    margin-bottom: 52px;
    .discount-container {
      float: left;
      width: 37%;
      .form-text {
        background: transparent;
        border: solid 1px #f2f2f2;
        padding: 18px;
        font-size: rem(16px);
        width: 284px;
        font-weight: lighter;
      }
      .form-submit {
        display: none;
      }
      @include media($mobile) {
        display: block;
        float: none;
        width: 100%;
        text-align: center;
      }
      .messages {
        background: #fff;
        border: none;
        font-size: em(12);
        padding: 0;
        margin: 0;
        .close {
          float: right;
          text-decoration: none;
          color: $gray;
        }
      }
    }
    .cart-subtotal-container {
      text-align: right;
      padding-top: 15px;
      @include media($mobile) {
        h3 {
          text-align: center;
          width: 100%;
        }
      }

    }
  }
  .cart-checkout-container {
    text-align: right;
    @include media($mobile) {
      text-align: center;
    }

    .default-button {
      display: inline-block;
    }
  }
  .mobile-only-label {
    display: none;
    @include media($mobile) {
      display: block;
      font-size: em(15);
      text-align: left;
      font-weight: bold;
    }
  }
}
