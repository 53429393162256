h1, h2, h3,
h4, h5, h6, p {
  margin: 0;
}

h1 {
  font-size: em(36px);
  font-weight: 100;
  line-height: 1.44;
  margin:0;
}

h2 {
  font-size: em(27px);
  line-height:1.03;
  font-weight: normal;
  margin:0 0 17px 0;

  &.dark {
    font-size: em(16px);
  }

  &.dark-bolder {
    font-size: em(36px);
    line-height: 1.36;
    font-weight: 100;
  }
}

h3 {
  font-size: em(21px);
  color:rgba(69,65,71,1);
  font-weight: normal;

  &.dark {
    font-size: em(22px);
    line-height: 1.27;
  }
}

h4 {
  font-size:em(15px);
  font-weight: normal;
}

p {
  font-size: em(15px);
  margin-bottom: 26px;
  line-height: 1.67;

  pre {
    font-size: em(21px);
    margin: 0 0 34px 0;
    line-height: 1.29;
  }

  &:last-of-type {
    margin:0;
  }

  &.light {
    font-size: em(15px);
    font-weight: normal;
    line-height: 1.67;
    margin:0;
  }

  &.small-light {
    font-size: em(13px);
    line-height: 1.36;
  }

  &.larger {
    font-size: em(18px);
    font-weight: normal;
    line-height: 1.39;
    margin:0;
  }
}

.no-margin {
  margin:0;
}

.right {
  float: right;
}