.slick-dots {
  margin: 0 auto;
  padding: 0;
  @include transition(all 333ms linear);
  @include position(absolute, null 0 -20px 0);

  li {
    display: inline-block;
    @include size(2px);
    background: $dark_grayish_violet;
    vertical-align: middle;
    margin: 0 3.5px;
    border-radius: 50%;
    @include transition(all 333ms linear);

    &.slick-active {
      @include transform(scale(2));
      background: $moderate_magenta;
    }

    button {
      font-size: 0;
      opacity: 0;
      display: none;
    }
  }
}

.slick-slide {
  height: inherit !important;
}

.our-team-slider-wrapper {
  opacity: 0;
  visibility: hidden;
  background: rgba(90, 77, 102, 0.9);
  @include position(fixed, 0 null null 0);
  @include size(100%);
  z-index: 200;
  @include transition(all 333ms linear);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .btn-close {
    z-index: 1;
    cursor: pointer;
    @include size(35px);
    background: url('../../images/build/btn-close.png') no-repeat center;
    background-size: 100%;
    @include position(absolute, 45px 50px null null);
  }

  .our-team-slider {
    height: 100%;

    & > div {
      height: 100%;
      padding-top: 48px;
    }
  }

  .our-team-slider-arrow {
    font-size: em(15);
    color: $white;
    cursor: pointer;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    display: inline-block;
    @include transition(all 333ms linear);

    &:after {
      content: '';
      @include size(13px);
      border-radius: 50%;
      margin: auto;
      background: url('../../images/build/arrow.svg') no-repeat center $moderate_magenta;
      background-size: 4px auto;
      @include position(absolute, 0 null 0 null);
    }

    &.btn-next {
      left: 55%;
      padding-right: 21.5px;

      &:after {
        right: 0;
      }
    }

    &.btn-prev {
      left: 40%;
      padding-left: 21.5px;

      &:after {
        left: 0;
        @include transform(rotate(180deg));

      }
    }

    &.slick-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      //visibility: hidden;
    }
  }

  .view-content {
    max-width: 855px;
    margin: 0 auto;
    padding-bottom: 85px;
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
  }

  .item {
    @include clearfix();
    width: 100%;
    @include pad(20px);
  }

  .image {
    position: relative;
    width: 33%;
    float: left;
    margin-right: 7%;
    border-radius: 50%;
    border: 13px solid $violet_dark_grayish;
    overflow: hidden;

    .bg {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include size(100%);
      position: absolute;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .content {
    width: 60%;
    float: left;
    color: $white;
  }

  .type {
    font-size: em(15);
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1.53;
    color: $white;
  }

  .name {
    font-size: em(36);
    font-weight: normal;
    line-height: 1.44;
    letter-spacing: -0.32px;
    margin-bottom: 4px;
  }

  .job-title {
    font-size: em(21);
    padding-bottom: 32px;
    font-weight: lighter;
    line-height: 1.29;
    border-bottom: 1px solid $gray;
    margin-bottom: 31px;
  }

  .text {
    font-size: em(14);
    line-height: 1.5;
    color: $gary_very_light;
  }

  .link {
    display: none;
  }

  @include media($mobile) {

    .btn-close {
      @include size(24px);
      top: 28px;
      right: 23px;
      z-index: 999;
    }

    .item {
      @include pad(35px);
    }

    .image {
      position: relative;
      width: 145px;
      float: none;
      margin-right: 0;
      margin-bottom: 34px;
      border-width: 7px;
    }

    .content {
      width: 100%;
      float: none;
      overflow-y: auto;
      max-height: 230px;
    }

    .type {
      font-size: em(12);
      margin-bottom: 11px;
    }

    .name {
      font-size: em(27);
      line-height: 1.11;
      letter-spacing: -0.24px;
      margin-bottom: 6px;
    }

    .job-title {
      font-size: em(19);
      padding-bottom: 15px;
      line-height: 1.15;
      margin-bottom: 15px;
    }

    .text {
      font-size: em(12);
      line-height: 1.6;
    }

    .our-team-slider-arrow {
      font-size: em(13);

      &.btn-next {
        left: initial;
        right: 35px;
      }

      &.btn-prev {
        left: 35px;
      }

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }

    .view-content {
      max-width: 100%;
      padding-bottom: 25px;
    }
  }
}
