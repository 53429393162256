.article-header {
  background-size: cover;
  background-position: center;
  position: relative;
  @include clearfix();
  z-index: 0;

  &:before {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: rgba(250, 250, 250, 0.9);
    z-index: -1;
  }

  .content {
    @include pad(25px 0 70px);
    @include outer-container(1600px);

    @include media($desktop) {
      @include pad(25px 20px 70px);
      @include outer-container(1200px);
    }
  }

  .breadcrumb {
    margin-bottom: 62px;
    position: relative;
    left: 0;
    top: 0;
    @include shift(1.7);

    @include media($desktop) {
      @include shift(0);
    }
  }

  .image {
    @include shift(2);
    @include span-columns(2.1);
    background-size: cover;
    background-position: center;

    //@include media($mobile) {
    //  @include shift(0);
    //  @include span-columns(12);
    //  width:100%;
    //  background-size: calc(100% - 200px);
    //  background-repeat: no-repeat;
    //}

    @include media($desktop) {
      @include shift(0.2);
      @include span-columns(2.8);
    }

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .article-title {
    @include shift(1);
    @include span-columns(5);
    position: relative;

    @include media($desktop) {
      @include shift(1);
      @include span-columns(7);
    }

    &:after {
      content: '';
      display: block;
      padding-top: 40%;

      //@include media($mobile) {
      //  content:none;
      //}
    }

    .title {
      font-size: em(36);
      color: $dark_very_violet;
      font-weight: lighter;
      line-height: 1.44;
      letter-spacing: -0.32px;
      @include position(absolute, null 0 45px null);
    }
  }

  .cic-1 {
    @include position(absolute, 18px null null 25px);
    @include size(195px 220px);
    background: url('../../images/build/cic-7.png') no-repeat center;
    background-size: 100% auto;
    z-index: -1;
  }

  .cic-2 {
    @include position(absolute, null 25px -28px null);
    @include size(105px);
    background: url('../../images/build/cic-6.png') no-repeat center;
    background-size: 100% auto;
    z-index: -1;
  }

  @include media($desktop) {

    .cic-1 {
      left: -84.5px;
    }

    .cic-2 {
      right: -17px;
    }

    .article-title {

      .title {
        bottom: 0;
      }
    }
  }

  @include media($tablet) {

    .article-title {

      .title,
      .wrapper {
        bottom: inherit;
      }

      .title {
        font-size: em(28);
      }
    }
  }

  @include media($mobile) {

    .cic-1 {
      top: 40px;
      left: -67px;
      @include size(155px 175px);
    }

    .cic-2 {
      bottom: 125px;
      right: -38px;
      @include size(90px);
    }

    .content {
      @include pad(11px 15px 27px);
    }

    .breadcrumb {
      margin-bottom: 29px;
    }

    .image {
      width: 150px;
      margin: 0 auto 34px;
      float: none;
    }

    .article-title {
      width: 100%;
      text-align: center;
      float: none;
      margin: 0 auto !important;
      max-width: 600px;
      @include pad(0 15px);

      &:after {
        content: none;
      }

      .wrapper {
        @include position(relative, inherit inherit inherit inherit);
      }

      .title {
        font-size: em(27);
        line-height: 1.26;
        letter-spacing: -0.24px;
        margin-bottom: 28px;
        @include position(relative, inherit inherit inherit inherit);
      }

      .sub-title {
        font-size: em(12);
        line-height: 1.5;
        margin-bottom: 25px;
      }
    }
  }

  &.event-header {

    .article-title {

      .wrapper {
        @include position(absolute, null 0 0 null);
        //@include span-columns(6.5);
        //@include media($mobile) {
        //  @include span-columns(12);
        //  margin: 0;
        //}
      }

      .title {
        @include position(relative, 0 0 0 0);
        margin-bottom: 16px;
        //@include media($mobile) {
        //  margin-top: -50px;
        //}
      }

      .sub-title {
        font-size: em(15);
        color: $dark_very_grayish_violet_2;
        line-height: 1.53;
      }

      .go-tickets {
        display: none;
      }
    }

    @include media($mobile) {

      .article-title {

        .wrapper {
          @include position(relative, inherit inherit inherit inherit);
        }

        .go-tickets {
          display: inline;
          font-size: em(12);
        }
      }
    }
  }

  &.programme-header {

    &:before {
      background: rgba(255,255,255,0.8);
    }

    .breadcrumb {
      margin-bottom: 109px;
    }

    .content {
      @include pad(30px 0 177px);

    }

    .article-title {
      text-align: center;
      max-width: 650px;
      margin: 0 auto;
      float: none;

      &:after {
        content: none;
      }

      .title {
        @include position(relative, 0 0 0 0);
        margin-bottom: 20px;
        font-size: em(43);
        line-height: 1.21;
        letter-spacing: -0.38px;
        color: $dark_very_violet;
        font-weight: lighter;
      }

      .sub-title {
        font-size: em(16);
        color: $dark_very_grayish_violet_2;
        line-height: 1.43;
        font-weight: lighter;
        letter-spacing: -0.14px;
        max-width: 450px;
        margin: 0 auto;
      }
    }

    .cic-1 {
      @include position(absolute, 43px null null -105px);
      @include size(185px 220px);
      background: url('../../images/build/cic-1.png') no-repeat center;
      background-size: 100% auto;
    }

    .cic-2 {
      @include position(absolute, null -26px -23px null);
      @include size(105px);
      background: url('../../images/build/cic-2.png') no-repeat center;
      background-size: 100% auto;
    }

    @include media($desktop_small) {

      .content {
        @include pad(30px 20px 120px);
      }
    }

    @include media($mobile) {

      .content {
        @include pad(25px 20px);
        margin-bottom: 0;
      }

      .breadcrumb {
        margin-bottom: 29px;
      }

      .article-title {

        .title {
          font-size: em(27);
        }

        .sub-title {
          font-size: em(12);
        }
      }
    }
  }
}