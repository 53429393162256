.node-join-our-team {
  position: relative;

  .content {
    max-width: 650px;
    margin: 17px auto 110px;

    .date {
      font-size: em(13);
      color: $dark_very_grayish_violet;
      line-height: 0.9;
      letter-spacing: -0.12px;
      margin-bottom: 62px;
    }

    .title {
      font-size: em(36);
      font-weight: lighter;
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 46px;
    }

    .sub-title {
      font-size: em(21);
      font-weight: lighter;
      line-height: 1.33;
      letter-spacing: -0.19px;
      margin-bottom: 14px;
    }

    .text {
      font-size: em(15);
      line-height: 1.67;
      color: $dark_very_grayish_violet;
      margin-bottom: 48px;

      p {
        margin-bottom: 25px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          padding-left: 35px;
          margin-left: 5px;
          margin-bottom: 15px;

          &:before {
            content: '';
            border-radius: 50%;
            background: $moderate_magenta;
            @include position(absolute, 0 null 0 0);
            margin: auto;
            @include size(5px);
          }
        }
      }
    }
  }

  @include media($tablet) {

    .entity-content {
      @include pad(15px 30px);
    }

    .breadcrumb {
      position: relative;
      left: 0;
    }

    .content {
      width: 100%;
      max-width: 100%;

      .date {
        margin-bottom: 15px;
      }

      .title {
        font-size: em(25);
        margin-bottom: 15px;
      }

      .sub-title {
        font-size: em(19);
        margin-bottom: 10px;
      }

      .text {
        font-size: em(12);
        margin-bottom: 25px;

        p {
          margin-bottom: 15px;
        }

        ul {

          li {
            margin-bottom: 10px;
          }
        }
      }

      .link-oval {
        font-size: em(12);
      }
    }
  }
}