.node-get-involved {

  .grid {
    @include outer-container();
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;

    .left, .right {
      position: relative;
      width: 50%;
      float: left;

      @include media($mobile) {
        width: 100%;
      }
    }

    .left {
      position: relative;
      z-index: 1;
      overflow: hidden;

      .bg {
        @include position(absolute, 0 0 0 0);
        background-size: cover;
        background-position: center;
        @include transition(all 333ms linear);
        z-index: -2;
      }

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        z-index: -1;
        background-image: linear-gradient(224deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,0.75) 60%, rgba(255,255,255,0.95) 87%);
      }

      &:after {
        content: '';
        display: block;
        padding-top: 100%;
      }

      &:hover {

        .bg {
          @include transform(scale(1.15));
        }
      }

      .wrapper {
        @include pad(10% 7%);
        @include position(absolute, null null 0 0);
      }

      .title {
        font-size: em(43);
        font-weight: normal;
        line-height: 1.21;
        letter-spacing: -0.38px;
        margin-bottom: 11px;
      }

      .text {
        font-size: em(14);
        line-height: 1.64;
        margin-bottom: 47px;
        width: 80%;
        max-width: 600px;
      }

      @include media($tablet) {

        .wrapper {
          @include pad(20px);
        }

        .title {
          font-size: em(35);
        }

        .text {
          width: 100%;
        }
      }

      @include media($mobile) {

        &:after {
          content: none;
        }

        .wrapper {
          @include pad(107px 32px 25px);
          @include position(relative, inherit);
        }

        .title {
          font-size: em(30);
          margin-bottom: 14px;
        }

        .text {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .boxes-area {
    @include outer-container();
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
  }

  .entry-footer {
    @include pad(69px 0 93px);
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .bg {
      @include position(absolute, 0 0 0 0);
      background-size: cover;
      background-position: center;
      @include transition(all 333ms linear);
      z-index: -2;
    }

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255,255,255,0.87);
      z-index: -1;
    }

    &:hover {

      .bg {
        @include transform(scale(1.15));
      }
    }

    .title {
      font-size: em(36);
      font-weight: lighter;
      letter-spacing: -0.32px;
      line-height: 1.44;
      margin-bottom: 15px;
    }

    .text {
      max-width: 420px;
      margin: 0 auto 20px;
      font-size: em(14);
      color: $dark_very_grayish_violet_2;
      line-height: 1.64;
    }

    @include media($mobile) {
      @include pad(34px 32px 33px);

      .title {
        font-size: em(27);
        line-height: 1.11;
        margin-bottom: 14px;
      }

      .text {
        margin: 0 auto 18px;
        font-size: em(11);
        line-height: 1.45;
      }
    }
  }
}