.scrollable {
  position: relative;
}

.scrollable:focus {
  outline: 0;
}

.scrollable .viewport {
  position: relative;
  overflow: hidden;
}

.scrollable .viewport .overview {
  position: absolute;
}

.scrollable .scroll-bar {
  display: none;
}

.scrollable .scroll-bar.vertical {
  position: absolute;
  right: 0;
  height: 100%;
}

.scrollable .scroll-bar.horizontal {
  position: relative;
  width: 100%;
}

.scrollable .scroll-bar .thumb {
  position: absolute;
}

.scrollable .scroll-bar.vertical .thumb {
  width: 100%;
  min-height: 10px;
}

.scrollable .scroll-bar.horizontal .thumb {
  height: 100%;
  min-width: 10px;
  left: 0;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Default skin*/

.scrollable.default-skin {
  padding-right: 10px;
  padding-bottom: 6px;
}

.scrollable.default-skin .scroll-bar.vertical {
  width: 6px;
}

.scrollable.default-skin .scroll-bar.horizontal {
  height: 6px;
}

.scrollable.default-skin .scroll-bar .thumb {
  background-color: black;
  opacity: 0.4;
  border-radius: 3px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.scrollable.default-skin .scroll-bar:hover .thumb {
  opacity: 0.6;
}

/*Gray skin*/

.scrollable.gray-skin {
  padding-right: 17px;
}

.scrollable.gray-skin .scroll-bar {
  border: 1px solid gray;
  background-color: #d3d3d3;
}

.scrollable.gray-skin .scroll-bar .thumb {
  background-color: gray;
}

.scrollable.gray-skin .scroll-bar:hover .thumb {
  background-color: black;
}

.scrollable.gray-skin .scroll-bar.vertical {
  width: 10px;
}

.scrollable.gray-skin .scroll-bar.horizontal {
  height: 10px;
  margin-top: 2px;
}

/*Modern skin*/
.scrollable.modern-skin {
  padding-right: 17px;
}

.scrollable.modern-skin .scroll-bar {
  border: 1px solid gray;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-box-shadow: inset 0 0 5px #888;
  -webkit-box-shadow: inset 0 0 5px #888;
  box-shadow: inset 0 0 5px #888;
}

.scrollable.modern-skin .scroll-bar .thumb {
  background-color: #95aabf;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #536984;
}

.scrollable.modern-skin .scroll-bar.vertical .thumb {
  width: 8px;
  background: -moz-linear-gradient(left, #95aabf 0%, #547092 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #95aabf), color-stop(100%, #547092));
  background: -webkit-linear-gradient(left, #95aabf 0%, #547092 100%);
  background: -o-linear-gradient(left, #95aabf 0%, #547092 100%);
  background: -ms-linear-gradient(left, #95aabf 0%, #547092 100%);
  background: linear-gradient(to right, #95aabf 0%, #547092 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#95aabf', endColorstr='#547092',GradientType=1 )";
}

.scrollable.modern-skin .scroll-bar.horizontal .thumb {
  height: 8px;
  background-image: linear-gradient(#95aabf, #547092);
  background-image: -o-linear-gradient(#95aabf, #547092);
  background-image: -moz-linear-gradient(#95aabf, #547092);
  background-image: -webkit-linear-gradient(#95aabf, #547092);
  background-image: -ms-linear-gradient(#95aabf, #547092);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#95aabf', endColorstr='#547092',GradientType=0 )";
}

.scrollable.modern-skin .scroll-bar.vertical {
  width: 10px;
}

.scrollable.modern-skin .scroll-bar.horizontal {
  height: 10px;
  margin-top: 2px;
}