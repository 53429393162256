.my-events-container {
  @include outer-container(1000px);
  margin: 67px auto 127px;
  @include media($mobile) {
    margin: 35px auto 35px;
    @include outer-container(900px);
  }

  .my-event-details {
    padding: 0 39px;
  }

  h2.dark-bolder {
    margin: 0;
    cursor: pointer;
    font-size: em(36);
    text-transform: capitalize;
    font-weight: lighter;
    padding-left: 58px;
    position: relative;
    @include media($mobile) {
      margin-left:50%;
      padding-left:15px;
      transform:translateX(-50%);
      font-size:rem(27px);

    }

    &:before {
      content: '';
      background: url(../../images/build/my-events.svg) no-repeat center $white;
      background-size: 24px auto;
      border-radius: 50%;
      @include size(40px);
      @include position(absolute, 0 null 0 0);
      margin: auto;
      @include media($mobile) {
        left: -35px;
      }
    }
  }

  .description {
    font-size: em(16);
    font-weight: lighter;
    color: $dark_very_grayish_violet;
    letter-spacing: -0.14px;
    padding-bottom: 55px;
    border-bottom: 1px solid $gray_light;
    @include media($mobile) {
      padding:0 39px 35px;
      margin:0;
    }
  }

  p {
    font-size: em(13);

  }

  .no-events {
    font-size: em(22);
    font-weight: lighter;
  }

  .event_wrapper {
    border-bottom: 1px solid $gray_light;
    padding: 40px 0;
    @include media($mobile) {
      padding: 30px 0;
    }

    h3 {
      margin: 0 0 15px;
      font-size: em(22);
      line-height: 1.27;
      letter-spacing: -0.2px;
      @include media($mobile) {
        font-size: em(20);
        margin: 0;
      }
    }

    p {

      &.light {
        font-size: em(15);
        line-height: 1.67;
        color: $dark_very_grayish_violet;
        margin-bottom: 15px;
        @include media($mobile) {
            line-height: 1.7;
          margin-top: 10px;
        }
      }

      &.small-light {
        font-size: em(13);
        line-height: 1.46;
        max-width:70%;

        font-family: Roboto-Light;
        @include media($mobile) {
          max-width: 100%;
        }
      }
    }

    .registration_item {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .ticket-actions {
      float: right;
      //width: 150px;
      text-align: center;

      a {
        display: block;

        &.active {
          display: none;
        }
      }
      @include media($mobile) {
        display: none;
      }
    }

    .ticket-actions-mobile {
      display: none;
      text-align: center;
      padding-top: 25px;

      a {
        display: inline-block;



      }
      @include media($mobile) {
        display: block;
      }
      .add-detail-button {
        line-height: 2.7;
        margin-left: 15px;
        &.active {
          &:after {
            transform: translateY(11px) rotate(90deg);
          }
        }
      }
    }

    .ticket_details {
      display: none;
    }

    .ticket_line {
      padding: 20px 0;
      margin: 0 32px;
      border-bottom: 1px solid $gray_light;
      position: relative;
      @include media($mobile) {
        margin: 0;
      }
      .ticket_line_edit {
        margin-right:13px;
        &.active {
          &:after {
            transform:translateY(3px) rotate(90deg);
          }
        }
      }
      .ticket_edit_box {
        @include media($mobile) {
          width:119px;
          float:right;
        }
        .ticket_line_edit,.ticket_line_print {
          width:105px;
          margin-bottom:5px;

        }

      }

      &:first-of-type {
        margin-top: 20px;
      }

      .ticket_line_detail {
        overflow-y: hidden;
        width: 100%;
        height: 0;
        @include transition(all 333ms linear);
        @include media($mobile) {
          @include transition(all 0ms);
        }

        form {
          padding: 35px;
          display: inline-block;
          width: 100%;
          @include media($mobile) {
            padding:0;
          }
        }

        &.active {
          height: auto;
          @include media($mobile) {
            padding-top:20px;
            margin-top:15px;
            border-top:solid 1px rgba(242,242,242,1);
          }
        }

      }

      .form_wrap {
        margin-bottom: 20px;
      }

      .select-wrapper {
        width: 90px;
        display: inline-block;
        vertical-align: middle;
        @include media($mobile) {
          width:100%;
          margin-bottom: 10px;
        }
      }

      .field-wrapper {
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 20px;
        @include media($mobile) {
          width:100%;
          margin-bottom: 10px;
        }

        &:nth-of-type(2n) {
          margin-left: 0;
        }

        &.third {
          width: calc((100% - 140px) / 2);
          margin-left: 20px;
          @include media($mobile) {
            width:100%;
            margin:0 0 10px 0;
          }
        }
        &.fourth {
          width: calc((100% / 3) - 6px);
          margin-left: 0;
          margin-right: 0;
          input,select {
            height: 52px;
          }


          @include media($mobile) {
            width:100%;
            margin:0 0 10px 0;
          }
        }

        label {
          @include position(absolute, 18px null null 16px);
          @include transition(all 333ms linear);
          z-index: -1;
        }

        .attendee_checkboxes {
          font-family: Roboto;
          font-size: 12px;
        }

        input[type=text],input[type=number],input[type=email] {
          @include size(100% 50px);
          border: 1px solid $gray_light;
          font-size: em(16);
          font-weight: lighter;
          letter-spacing: -0.14px;
          @include pad(17px 16px 13px);
          z-index: 1;
          @include transition(all 333ms linear);
        }
        input[type=checkbox] {
          -webkit-appearance: checkbox;
          height: 20px;
          width:auto;
        }

        &.active {

          label {
            font-size: em(9);
            top: 10px;
            left: 16px;
          }
        }
      }


      .attendee_update_changes {

        &.hidden {
          display: none;
        }
      }

      &:last-of-type {
        margin-bottom: 50px;
      }
    }

    .print-ticket-button {
      margin-bottom: 10px;
    }

    .link {
      float: right;
      color: $dark_grayish_violet;
      @include transition(all 333ms linear);
      margin-right: 15px;
      font-size: em(13);
      @include media($mobile) {
        float:none;
      }

      &.active {

        &:after {
          transform: rotate(90deg);
          margin: 0 0 0 5px;
        }
      }

      &.new {
        display: none;
      }
    }

    .registration_item {
      overflow: hidden;
    }


    .attendee_name {
      text-transform: capitalize;
    }

    .loading_overlay {
      display: none;
      @include position(absolute, 0 0 0 0);
      background: rgba(255, 255, 255, 0.8);
      z-index: 10;

      &.active {
        display: block;
      }
    }
  }

  .my-event-details {
    overflow: hidden;
    transition: all 153ms linear;
    height: 0;

    &.active {
      height: auto;
    }
  }

}

.profile-options-wrapper {
  background: $white_2;
  padding: 90px 0;

  @include media($mobile) {
    padding: 35px 0;
  }

  .profile-options-container {
    @include outer-container(1000px);
    position: relative;
    padding-bottom: 39px;
    .profile-options_content {

      @include span-columns(12);

      @include media($mobile) {
        @include shift(0);
        @include span-columns(12);
      }

    }

    h2.dark-bolder {
      margin: 0;
      cursor: pointer;
      font-size: em(36);
      text-transform: capitalize;
      font-weight: lighter;
      padding-left: 58px;
      position: relative;
      @include media($mobile) {
        margin-left:50%;
        padding-left:15px;
        transform:translateX(-50%);
        font-size:rem(27px);

      }

      &:before {
        content: '';
        background: url(../../images/build/my-profile.svg) no-repeat center $white;
        background-size: 24px auto;
        border-radius: 50%;
        @include size(40px);
        @include position(absolute, 0 null 0 0);
        margin: auto;
        @include media($mobile) {
          left: -35px;
        }
      }
    }

    .profile-details {
      display: none;
      padding: 0 39px;

      .description {
        margin: 48px 0 30px;
        font-size: em(22);
        line-height: 1.27;
        letter-spacing: -0.2px;
        padding-bottom: 19px;
        border-bottom: 1px solid $gray_light;
        @include media($mobile) {
          font-size: em(20);
          padding-bottom: 15px;
          margin: 31px 0 20px;
          line-height: 1.7;
        }
      }

      form {
        //@include span-columns(6 of 8);
        float: none;
        margin-left: auto;
        margin-right: auto !important;
        @include media($mobile) {
          @include span-columns(12);
        }
      }

      .select-wrapper {
        width: 90px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 20px;
        @include media($mobile) {
          width: 100%;
        }

        .chosen-single {
          font-size: em(16);
          color: $color_main;
        }

        .chosen-drop {
          background: $white;
        }

        .chosen-results {

          li:last-of-type {
            border-bottom: 0;
          }
        }
      }

      .field-wrapper {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 20px;
        position: relative;

        input[type=checkbox] {
          -webkit-appearance: checkbox;
          height:20px;
          width:auto;
        }

        @include media($mobile) {
          width: 100%;
          display: block;
        }

        &.active {

          label {
            font-size: em(9);
            font-weight: lighter;
            @include position(absolute, 8px null null 16px);
          }
        }

        label {
          font-size: em(16);
          color: $color_main;
          @include position(absolute, 17px null null 16px);
          @include transition(all 333ms linear);
          z-index: 0;
        }

        input {
          background: $white;
          @include pad(19px 16px 10px);
          border: 1px solid $gray_light;
          font-size: em(16);
          letter-spacing: -0.14px;
          z-index: 1;

          &.error-field {
            border-color: red;
          }
        }

        &.third {
          margin-left: 20px;
          width: calc((100% - 139px) / 2);
          @include media($mobile) {
            width: 100%;
            display: block;
            margin-left:0;
          }
        }

        &.half {
          margin-right: 20px;
          width: calc((100% - 25px) / 2);
          @include media($mobile) {
            width: 100%;
            display: block;
            margin-left:0;
          }

          &:nth-of-type(2n-1) {
            margin-right: 0;
          }
        }
      }

      .change_password_button {
        margin: 4px 0 32px;
        width: 100%;
        @include media($mobile) {
          width: 100%;
          text-align: center;
          margin: 20px 0;
        }

        a {
          font-size: em(14);
          display: inline-block;
          color: $dark_very_grayish_violet;
        }
      }

      .form-callback,
      .form-required {
        display: none;
        font-size: em(16);
        color: $color_main;
        text-align: center;
        margin: 0 0 20px;
        font-weight: lighter;
      }

      .password_options {
        border: none;
        padding: 0;
        margin: 0;
        display: none;
      }

      .password_options .half {

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        &:nth-of-type(2n-1) {
          margin-right: 20px;
        }
      }
      .newsletter-agree_wrapper {
        display: inline-block;
        position: absolute;
        left:50%;
        bottom:35px;
        @include media($mobile) {
          position: static;
          width:100%;
          display: block;
          text-align: center;
          transform:translateX(-10px);
        }
      }
      .profile_deactivate {
        position: absolute;
        right:53px;
        bottom:0;
        font-size: em(13);
        color: #88868A;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    .profile_update_submit {
      float:right;
      @include media($mobile) {
        float: none;
        transform: translateX(50%);
      }
    }
  }

}

.page-user {

  .region-content {
    @include pad(30px);
    max-width: 650px;
    margin: auto;

    .form-item {
      margin: 0 0 40px;
    }

    label {
      font-size: em(16);
      font-weight: lighter;
    }

    .description {
      font-size: em(14);
      font-weight: lighter;
    }

    input[type="text"],
    input[type="password"] {
      border: 1px solid $gray_light;
      @include pad(10px 15px);
      margin: 10px 0 5px;
    }

    .form-item-persistent-login {
      display: none;
    }

    input[type="submit"] {
      position: relative;
      display: inline-block;
      font-size: em(14);
      text-transform: uppercase;
      background: $moderate_magenta;
      color: $white;
      border-radius: 100px;
      @include pad(8px 18px);
      max-width: 78px;
      text-align: center;
      text-decoration: none;
      outline: none;
      @include transition(all 333ms linear);
    }
  }
}