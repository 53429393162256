.node-type-donation-landing {

  .header_donation {
    position: relative;
    @include pad(104px 0 111px);
    background-size: cover;
    background-position: center;
    z-index: 0;

    @include media($tablet) {
      padding: 54px 0 38px;
    }

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background-image: linear-gradient(227deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0.81) 100%);
      opacity: 0.95;
      z-index: -1;

      @include media($tablet) {
        background: linear-gradient(227deg, rgba(255, 255, 255, 0.80) 100%, rgba(255, 255, 255, 0.80) 100%);
      }
    }

    .cic-1 {
      @include position(absolute, 20px null null 0);
      @include size(184px 260px);
      background: url('../../images/build/cic-3.png') no-repeat center;
      background-size: 100% auto;
      @include transform(rotate(180deg));
    }

    .cic-2 {
      @include position(absolute, null 0 33px null);
      @include size(174px 169px);
      background: url('../../images/build/cic-4.png') no-repeat center;
      background-size: 100% auto;
      @include transform(rotate(180deg));
    }

    .wrapper {
      position: relative;
      @include outer-container(1200px);

      @include media($desktop_small) {
        @include pad(0 20px);
      }

      @include media($tablet) {
        @include pad(0 30px);
      }
    }

    .content {
      max-width: 450px;
      @include position(absolute, null null 30px 0);

      @include media($desktop_small) {
        left: 20px;
      }

      @include media($tablet) {
        position: static;
        max-width: none;
      }

      .title {
        font-size: em(44);
        font-weight: lighter;
        line-height: 1.23;
        letter-spacing: -0.39px;
        margin-bottom: 19px;

        @include media($tablet) {
          font-size: em(30);
          margin-bottom: 23px;
        }
      }

      strong {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.64;
      }

      p {
        font-size: em(13);
        font-weight: lighter;
        line-height: 1.46;
        margin: 19px 0;

        @include media($tablet) {
          margin: 10px 0;
        }
      }
    }

    .right_section {
      width: 50%;
      float: right;

      @include media($desktop_small) {
        width: 45%;
      }

      @include media($tablet) {
        float: none;
        width: 100%;
        margin-top: 33px;
      }

      .donation_type {
        border-left: 2px solid $moderate_magenta;
        @include pad(3px 0 3px 26px);
        margin-bottom: 48px;

        &:last-of-type {
          margin-bottom: 0;
        }

        h2 {
          font-size: em(27);
          margin-bottom: 10px;
          font-weight: lighter;
          letter-spacing: -0.24px;

          @include media($tablet) {
            font-size: em(19);
            margin-bottom: 17px;
          }
        }

        p {
          font-size: em(13);
          font-weight: lighter;
          line-height: 1.46;
          margin-bottom: 21px;

          @include media($tablet) {
            margin-bottom: 12px;
          }
        }

        .donation-call-to-action,
        .sponsor-call-to-action {
          position: relative;
          background: rgba(255, 255, 255, 0.59);
          border-radius: 100px;
          padding: 17px 50px 17px 32px;
          text-transform: uppercase;
          font-size: em(16);
          display: inline-block;
          text-decoration: none;
          color: $dark_very_grayish_violet;

          @include media($tablet) {
            width: 100%;
          }

          &:after {
            content: '';
            position: absolute;
            @include position(absolute, 0 20px 0 null);
            @include size(20px);
            background: url('../../images/build/more-arrow.svg') no-repeat center;
            margin: auto;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .core {
    @include outer-container();
    border-left: 1px solid $gray_light;
    border-right: 1px solid $gray_light;
    border-bottom: 1px solid $gray_very_light_4;

    .block {
      position: relative;
      float: left;
      width: 50%;
      overflow: hidden;

      @include media($mobile) {
        float: none;
        width: 100%;
        padding: 45px 35px;
      }

      &:hover {

        .bg {
          @include transform(scale(1.15));
        }
      }

      .bg {
        @include position(absolute, 0 null null 0);
        background-size: cover;
        background-position: center;
        @include size(100%);
        z-index: -1;
        @include transition(all 333ms linear);
      }

      &.block-2 {
        z-index: 0;

        &:after {
          content: '';
          @include position(absolute, 0 0 0 0);
          @include size(100%);
          background: rgba(153, 69, 132, 0.93);
          z-index: -1;
        }

        .title, .text, .link {
          color: $white;
        }

        .link:after {
          background: url('../../images/build/arrow-magenta.svg') no-repeat center $white;
        }
      }

      &:before {
        content: '';
        display: block;
        padding-top: 50%;
        @include media($mobile) {
          display: none;
        }
      }

      .content {
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        margin: auto;
        max-width: 510px;
        text-align: center;
        @include media($mobile) {
          @include position(static);
          transform: translate(0);
        }
      }

      .title {
        font-size: em(36);
        font-weight: lighter;
        line-height: 1.36;
        letter-spacing: -0.32px;
        margin-bottom: 13px;
        @include media($mobile) {
          font-size: em(27);
        }

      }

      .text {
        font-size: em(14);
        line-height: 1.64;
        margin-bottom: 22px;
        color: $dark_very_grayish_violet_2;
        @include media($mobile) {
          font-size: em(11);
        }
      }
    }
  }

  .additional-information {
    @include outer-container();
    border-left: solid 1px #F2F2F2;
    border-right: solid 1px #F2F2F2;
    @include pad(76px 0 71px);
    //max-width: 650px;
    margin: auto;
    text-align: center;
    @include media($mobile) {
      @include pad(35px 35px 40px);
      max-width: none;
    }

    .title {
      font-size: em(27);
      font-weight: lighter;
      margin-bottom: 24px;
      letter-spacing: -0.24px;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;
      max-width: 650px;
      margin: 0 auto;
      @include media($mobile) {
        font-size: em(12);
      }
    }
  }
}

.donation-amount-select {
  width: 100vw;
  height: 100vh;
  @include position(fixed, -100vh 0 null 0);
  background: rgba(90, 77, 102, 0.9);
  @include transition(all 333ms linear);
  opacity: 0;
  z-index: 200;
  visibility: hidden;

  &:before {
    content: '';
    background: url(../../images/build/bubbles.svg) no-repeat;
    position: absolute;
    top: 10px;
    @include size(150px 300px);
    opacity: 0.3;
  }

  &:after {
    content: '';
    background: url(../../images/build/right-bubble.svg) no-repeat;
    position: absolute;
    bottom: 20px;
    right: 0;
    @include size(80px 105px);
    opacity: 0.3;
  }

  .wrapper {

    @include media($mobile) {
      padding: 0 35px;
      width: 100%;
    }

    @include position(absolute, 40% 0 null 0);
    @include transform(translateY(-50%));
    max-width: 650px;
    margin: auto;
    text-align: center;

    .title {
      font-weight: lighter;
      font-size: em(43);
      color: $white;
      letter-spacing: -0.38px;
      line-height: 1.21;
      margin-bottom: 47px;
      text-align: center;

      @include media($mobile) {
        font-size: em(30);
        margin-bottom: 0;
      }
    }
    .subtitle {
      display: none;

      @include media($mobile) {
        display: block;
        padding: 11px 0 35px;
        font-weight: lighter;
        color: $white;
        max-width: 180px;
        margin: 0 auto;
      }
    }

    .container {
      padding-bottom: 55px;
      margin-bottom: 40px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.37);
      position: relative;

      @include media($mobile) {
        padding-bottom: 30px;
      }

      &.donation-selected {

        .donation-amount {
          opacity: 0.5 !important;

          @include media($tablet) {
            opacity: 1 !important;
          }
        }

        .custom {
          color: $gray_light;
          border: 0;
        }

        .selected {
          opacity: 1 !important;
          box-shadow: 0 0 7px rgba(255, 255, 255, 0.3);
        }
      }

      .donation-amount {
        font-weight: 500;
        font-size: em(16);
        color: $white;
        @include pad(20px 30px 20px 27px);
        text-decoration: none;
        display: inline-block;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
        margin-left: 15px;
        max-width: 170px;
        @include transition(all 333ms linear);

        &.custom {
          @include pad(20px 30px 20px 37px);
        }

        &.selected {
          background: rgba(255, 255, 255, 0.4);
          color: $white;

          &::placeholder {
            color: $white;
          }
        }

        &:first-of-type {
          margin-left: 0;

          @include media($mobile) {
            margin: 0;
          }
        }
      }

      a.donation-amount {

        @include media($mobile) {
          position: relative;
          max-width: 100%;
          display: block;
          margin: 0;
          border-radius: 100px;
          z-index: 2;
          text-align: left;
          background: rgba(255, 255, 255, 0.4);
          &:first-of-type {
            &:after {
              content: '';
              position: absolute;
              width: 25px;
              height: 25px;
              background: url(../../images/build/arrow-down.svg) no-repeat, linear-gradient(to left, $deeppurple 0%, $deeppurple 100%);
              background-position: center;
              border-radius: 100px;
              right: 20px;
            }
          }
          &:not(:first-child) {
            display: none;
            color: $white;
            position: absolute;
            right: 0;
            left: 0;
            &:hover {
              background: rgba(255, 255, 255, 0.4);
            }
          }
          &:nth-of-type(2) {
            top: 65px;
            &:hover {
              background: rgba(255, 255, 255, 0.6);
            }
          }
          &:nth-of-type(3) {
            top: 130px;
            &:hover {
              background: rgba(255, 255, 255, 0.6);
            }
          }
          &:nth-of-type(4) {
            top: 195px;
            &:hover {
              background: rgba(255, 255, 255, 0.6);
            }
          }
          &:hover {
            background: rgba(255, 255, 255, 0.6);
          }
        }
      }

      input.donation-amount {

        @include media($mobile) {
          width: 100%;
          display: block;
          margin: 20px 0 0 0;
          max-width: none;
          transition: all 100ms linear;
          &::placeholder {
            color: rgba(255, 255, 255, 0.2);
          }
          &.donationreposition {
            margin-top: 200px;
          }
        }
      }
    }

    .reset-donation {
      display: none;

      @include media($mobile) {
        display: inline-block;
        margin-right: 22px;
      }
    }

    .custom-donation-fieldgroup {
      position: relative;
      display: inline-block;

      @include media($mobile) {
        display: block;
        margin: 0;
        padding: 0;
      }

      label {
        font-size: em(16);
        color: $white;
        @include position(absolute, 50% null null 30px);
        @include transform(translateY(-50%));
        display: none;
      }

      .donation-amount {
        font-size: em(16);
        color: $white;
        font-weight: normal;

        @include placeholder {
          color: $white;
          font-weight: normal;
        }
      }
      &:before {
        content: attr(data-currency-value);
        font-size: 1em;
        color: $white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 32px;
        opacity: 0;
        transition: all 153ms ease-in;
      }
      &.active {
        &:before {
          opacity: 1;
        }
        input {
          padding-left: 55px !important;

          &::placeholder {
            color: rgba(255, 255, 255, 0.3) !important;
            display: none !important;
            font-size: rem(14) !important;
          }
        }
      }
    }
  }

  &.active {
    //top: 131px; /* DESKTOP */
    top: 0;
    opacity: 1;
    visibility: visible;

    @include media($desktop_small) {
      //top: 113px;
    }

    @include media($tablet) {
      //top: 85px;
    }
  }
}

.loading_state {
  @include position(fixed, 0 0 0 0);
  background: rgba(255, 255, 255, 0.8);
  display: none;

  &:before {
    content: '';
    background: url(../../images/build/Spinner.svg) no-repeat;
    background-size: contain;
    @include size(200px);
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
    margin: auto;
  }

  &.active {
    display: block;
  }

}

.donation-confirmation {
  @include pad(121px 0 234px);
  background: linear-gradient(45deg, rgba(90, 77, 102, 0.73) 0%, rgba(90, 77, 102, 0.81)), url(../../images/build/donation_header.png);
  background-size: cover;
  position: relative;
  @include media($mobile) {
    @include pad(54px 35px 40px);
  }

  &:before {
    content: '';
    background: url(../../images/build/cic-5.png) no-repeat center;
    background-size: contain;
    @include position(absolute, 19px null null -25px);
    @include size(185px 220px);
  }

  &:after {
    content: '';
    background: url(../../images/build/right-bubble.svg) no-repeat;
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 80px;
    height: 105px;
    opacity: 0.3;
  }

  .content {
    @include outer-container(1200px);

    h1, h2, h3, p,
    .link-wrapper {
      max-width: 550px;
      color: $white;
      @include shift(2);
      font-weight: lighter;
      @include media($mobile) {
        @include shift(0);
      }
    }

    h1 {
      font-size: em(44);
      line-height: 1.27;
      letter-spacing: -0.39px;
      @include media($mobile) {
        font-size: em(30);
      }
    }

    h2 {
      font-size: em(36);
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 14px;
      @include media($mobile) {
        font-size: em(19);
      }

    }

    h3 {
      font-size: em(21);
      line-height: 1.42;
      letter-spacing: -0.19px;
      margin-bottom: 15px;
      @include media($mobile) {
        font-size: em(16);
      }
    }

    p {
      font-size: em(15);
      line-height: 1.6;
      font-weight: normal;
      margin-bottom: 42px;
      @include media($mobile) {
        font-size: em(12);
      }
    }
    .link-oval {
      @include media($mobile) {
        margin-bottom: 15px;

      }
    }

    .link {
      margin-left: 35px;
      color: $white;
      @include media($mobile) {
        display: block;
        margin: 0;
        position: relative;
        width: 100px;

      }

      &:after {
        background: url('../../images/build/arrow-magenta.svg') no-repeat center $white;
        @include media($mobile) {
          position: absolute;

        }
      }
    }
  }
}
