.field-type-mailchimp-lists-subscription {
  display: none;
}

.newsletter {
  select {
    background: transparent;
    border: 0;
    color: $white;

  }
  .field-type-text, .field-type-email, .field-type-list-text {
    padding: 4px 30px;
  }
  .field-name-field-age {
    padding: 4px 30px;
    .form-type-select {
      label {
        display: block;
        top: -26px;
        font-size: rem(13px);
      }

    }
  }
}