.node-type-about-us {

  &.i18n-zh-hant {

    .header .title {
      font-weight: 400 !important;
    }

  }

}

.node-about-us {



  .header {
    position: relative;
    @include pad(102px 0 381px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    &:after {
      content: '';
      @include size(100%);
      background: rgba(255, 255, 255, 0.8);
      @include position(absolute, 0 null null 0);
      z-index: -1;
    }

    .wrapper {
      @include outer-container();
    }

    .content {
      text-align: center;
      max-width: 650px;
      margin: 0 auto;
    }

    .title {
      font-size: em(44px);
      font-weight: lighter;
      line-height: 1.23;
      letter-spacing: -0.39px;
      margin-bottom: 23px;
    }

    .text {
      font-size: em(15px);
      line-height: 1.4;
      letter-spacing: -0.32px;
      margin-bottom: 35px;
    }

    .link {
      @include margin(0 40px 0 0);

      &:after {
        @include transform(rotate(90deg));
      }

      &:last-of-type {
        margin: 0;
      }
    }

    @include media($desktop) {
      @include pad(94px 0 127px);
    }

    @include media($tablet) {
      @include pad(53px 30px 42px);

      .content {
        text-align: left;
        max-width: 80%;
        margin-left: 0;
        min-width: 250px;
      }
    }

    @include media($mobile) {

      .title {
        font-size: em(30);
        margin-bottom: 11px;
      }

      .text {
        font-size: em(12);
        margin-bottom: 0
      }

      .link {
        display: none;
      }
    }
  }

  .section-1 {
    z-index: 1;
    position: relative;
    margin-top: -255px;
    border-left:solid 1px $gray_light;
    border-right:solid 1px $gray_light;
    @include outer-container();
    @include transition(all 666ms ease-out);

    .section-box {
      position: relative;
      float: left;
      width: 50%;
      z-index: 1;

      &:before {
        content: '';
        display: block;
        padding-top: 85%;
      }

      .content {
        margin: auto;
        width: 60%;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
      }

      .title {
        font-size: em(36px);
        font-weight: lighter;
        line-height: 1.64;
      }

      h3 {
        font-size: em(15px);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 10px;
      }

      &.left {
        color: $white;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
          content: '';
          @include position(absolute, 0 0 0 0);
          @include size(100%);
          background: url('../../images/build/pattern.png') no-repeat center rgba(74, 43, 102, 0.95);
          background-size: cover;
          z-index: -1;
        }

        .title {
          color: $white;
          margin-bottom: 32px;
        }

        h3 {
          color: $white;
          margin-bottom: 10px;
        }

        p {
          font-size: em(16px);
          margin-bottom: 68px;
          line-height: 1.44;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &.right {
        background: $white;

        .title {
          margin-bottom: 10px;
        }

        p {
          font-size: em(14px);
          line-height: 1.64;
        }
      }
    }

    @include media($desktop) {
      margin-top: 0;

      .section-box {

        .content {
          width: 80%;
        }
      }
    }

    @include media($tablet) {

      .section-box {
        width: 100%;

        &:before {
          content: none;
        }

        .content {
          @include position(relative, inherit inherit inherit inherit);
          @include transform(translateY(0));
          @include pad(42px 30px);
          width: 100%;
        }
      }
    }

    @include media($mobile) {

      .section-box {

        &.left {

          .title {
            font-size: em(27);
            line-height: 1.925;
            margin-bottom: 11px;
          }

          h3 {
            font-size: em(15);
            margin-bottom: 13px;
          }

          p {
            font-size: em(14px);
            margin-bottom: 32px;
            line-height: 1.64;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        &.right {

          .title {
            font-size: em(27);
            margin-bottom: 20px;
          }

          p {
            font-size: em(12);
            line-height: 1.58;
          }
        }
      }
    }
  }

  .section-2 {
    @include outer-container();
    border-left:solid 1px $gray_light;
    border-right:solid 1px $gray_light;

    .three-color-boxes {

      &:nth-of-type(3) {
        background: url('../../images/build/pattern-2.png') no-repeat center;
        background-size: cover;

        &:after {
          content: '';
          @include position(absolute, 0 null null 0);
          @include size(100%);
          background: rgba(0, 0, 0, 0.4);
          z-index: -1;
        }
      }
    }

    @include media($tablet) {

      .three-color-boxes {
        width: 100%;

        &:before {
          content: none;
        }

        .content {
          @include position(relative, inherit inherit inherit inherit);
          @include transform(translateY(0));
          @include pad(43px 30px 40px);
          width: 90%;
          margin-left: 0;
          min-width: 250px;
        }

        .text {
          margin-bottom: 31px;
        }

        .link {
          position: relative;
        }
      }
    }

    @include media($mobile) {

      .three-color-boxes {

        .title {
          font-size: em(20);
          margin-bottom: 27px;
        }

        .text {
          font-size: em(12);
          line-height: 1.58;
        }

        .link {
          font-size: em(12);
        }
      }
    }
  }

  .section-3 {
    z-index: 1;
    position: relative;
    @include pad(45px 0 86px);
    @include outer-container();
    background-position: left center;
    background-size: cover;

    &:before {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      opacity: 0.93;
      background-image: linear-gradient(-128deg, rgba(153, 69, 132, 0.89) 22%, #994584 84%);
      z-index: -1;
    }

    .wrapper {
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      color: white;
    }

    .title {
      font-weight: lighter;
      font-size: em(23);
      line-height: 2.26;
      letter-spacing: -0.2px;
      color: $white;
      text-transform: uppercase;
    }

    .name {
      font-weight: lighter;
      font-size: em(36);
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 25px;
      color: $white;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;

      p {
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        color: $white;
      }
    }

    @include media($mobile) {
      @include pad(35px 30px 135px);

      .title {
        font-size: em(16);
        line-height: 3.25;
        letter-spacing: -0.14px;
      }

      .name {
        font-size: em(27);
        line-height: 1.925;
        margin-bottom: 15px;
      }

      .text {
        font-size: em(12);
        line-height: 1.91;

        p {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .section-4 {
    @include outer-container();
    border-left: solid 1px $gray_light;
    border-right: solid 1px $gray_light;

    .section-box {
      float: left;
      width: 50%;
      transition: background-size 333ms linear;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        padding-top: 50%;
        background: rgba(247, 247, 247, 0.9);
      }
    }

    .content{
      &:hover{
        .image {
          transform: scale(1.15);
        }
      }
    }

    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('../../images/build/about-us-4.jpg') no-repeat center;
      background-size: cover;
      transition: all 333ms linear;
      z-index: -1;

      &:after {
        content: '';
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background-image: linear-gradient(146deg, rgba(0, 0, 0, 0.00) 39%, rgba(0, 0, 0, 0.40) 100%);
      }
    }


    .content {
      position: relative;
      background-size: cover;

      .content-wrapper {
        text-align: center;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        @include pad(0 70px);
      }
    }

    .title {
      font-size: em(36px);
      font-weight: lighter;
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 14px;
    }

    .text {
      max-width: 420px;
      font-size: em(14px);
      line-height: 1.64;
      color: $dark_very_grayish_violet_2;
      margin: 0 auto 32px;
    }

    .link {
      font-size: em(14px);
    }

    @include media($tablet) {

      .content {

        .content-wrapper {
          @include pad(20px);

          .title {
            font-size: em(28);
            margin-bottom: 10px;
          }

          .text {
            font-size: em(13);
            margin-bottom: 10px;
          }

          .link {
            font-size: em(13);
          }
        }
      }
    }

    @include media($mobile) {

      .section-box {
        width: 100%;
      }

      .content {

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        &:after {
          top: -11px;
          left: 0;
          right: 0;
          bottom: inherit;
        }

        .content-wrapper {
          @include pad(30px);
          @include position(relative, inherit inherit inherit inherit);
          @include transform(translateY(0));

          .title {
            font-size: em(27);
            margin-bottom: 13px;
          }

          .text {
            font-size: em(12);
            margin-bottom: 29px;
          }

          .link {
            display: inline-block;
            margin-bottom: 13px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .section-5 {
    @include outer-container();
    border-left: solid 1px $gray_light;
    border-right: solid 1px $gray_light;


    .wrapper {
      float: left;
      width: 50%;
    }

    @include media($mobile) {

      .wrapper {
        width: 100%;
      }
    }
  }
}
