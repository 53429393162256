.node-type-homepage {

  &.i18n-zh-hant {

    .hp-header-box {
      font-weight: 400 !important;
    }

  }

  .main-content {
    @include outer-container();
    border-left:solid 1px $gray_light;
    border-right:solid 1px $gray_light;

    @include media($desktop) {
      border: 0;
    }
  }

  .hp-header {
    position: relative;
    @include pad(115px 0 328px);
    z-index: 0;
    margin-bottom: -215px;
    overflow: hidden;

    .bg {
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      z-index: -1;

      .non-flip {
        float: left;
        @include size(50% 100%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .flip {
        float: left;
        margin: -12px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include transform(scaleX(-1));
        @include filter(blur(10px));
        @include size(calc(50% + 24px) calc(100% + 24px));
      }
    }

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(153, 69, 132, 0.93);
      z-index: -1;
    }

    .content {
      max-width: 550px;
      margin: 0 auto;
      text-align: center;
    }

    .text {
      margin-bottom: 31px;
      color: $white;

      h2 {
        font-weight: lighter;
        font-size: em(23);
        line-height: 2.26;
        text-transform: uppercase;
        letter-spacing: -0.2px;
        margin: 0;
      }

      h1 {
        font-weight: lighter;
        font-size: em(44);
        line-height: 1.18;
        text-transform: capitalize;
        letter-spacing: -0.39px;
        margin-bottom: 20px;
      }

      p {
        font-size: em(15);
        line-height: 1.52;
      }
    }

    .link {
      color: $white;

      &:after {
        background: url('../../images/build/arrow-magenta.svg') no-repeat center $white;
      }
    }

    .cic-1 {
      @include position(absolute, 81px null null 83px);
      @include size(185px 220px);
      background: url('../../images/build/cic-1.png') no-repeat center;
      background-size: 100% auto;
    }

    .cic-2 {
      @include position(absolute, 107px 46px null null);
      @include size(105px);
      background: url('../../images/build/cic-2.png') no-repeat center;
      background-size: 100% auto;
    }

    img {
      display: block;
      width: 100%;
    }

    @include media($desktop) {
      display: none;
    }
  }

  .hp-header-box {
    display: none;
    position: relative;
    float: left;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(153, 69, 132, 0.93);
      z-index: -1;
    }

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .content {
      @include position(absolute, null null 121px 102px);
      max-width: 450px;
    }

    .text {
      margin-bottom: 27px;
      color: $white;

      h2 {
        font-weight: lighter;
        font-size: em(23);
        line-height: 2.26;
        text-transform: uppercase;
        letter-spacing: -0.2px;
        margin: 0;
      }

      h1 {
        font-weight: lighter;
        font-size: em(44);
        line-height: 1.18;
        text-transform: capitalize;
        letter-spacing: -0.39px;
        margin-bottom: 25px;
      }

      p {
        font-size: em(15);
        line-height: 1.52;
      }
    }

    .link {
      color: $white;

      &:after {
        background: url('../../images/build/arrow-magenta.svg') no-repeat center $white;
      }
    }

    .cic-1 {
      @include position(absolute, 21px null null -70px);
      @include size(185px auto);
    }

    .cic-2 {
      @include position(absolute, null 19px 109px null);
      @include size(105px auto);
    }

    img {
      display: block;
      width: 100%;
    }

    @include media($desktop) {
      display: block;

      .cic-2 {
        @include position(absolute, null -52.5px 22px null);
      }
    }

    @include media($desktop_small) {

      .content {
        left: 0;
        right: 0;
        margin: auto;
        bottom: inherit;
        top: 50%;
        @include transform(translateY(-50%));
      }

      .cic-1 {
        @include position(absolute, 15px null null -75px);
        @include size(140px auto);
      }

      .cic-2 {
        @include position(absolute, null -22.5px 15px null);
        @include size(75px auto);
      }
    }

    @include media($tablet) {
      width: 100%;

      &:after {
        padding-top: 50%;
      }

      .content {
        left: 75px;
        right: inherit;
        margin: auto;
        bottom: 25px;
        top: inherit;
        @include transform(translateY(0));
      }

      .text {

        h1 {
          font-size: em(30);
          margin-bottom: 16px;
        }

        p {
          font-size: em(12);
          line-height: 1.9;
        }
      }

      .link {
        font-size: em(12);
      }

      .cic-1 {
        @include position(absolute, 21px null null -70px);
      }

      .cic-2 {
        @include position(absolute, null 82px -50px null);
      }
    }

    @include media($mobile) {

      &:after {
        content: none;
      }

      .content {
        @include position(relative, inherit inherit inherit inherit);
        @include pad(45px 30px);
        max-width: 100%;
      }

      .text {

        h1 {
          margin-bottom: 12px;
        }

        h2 {
          font-size: em(16);
          line-height: 3.25;
          margin-bottom: 0;
        }

        p {
          font-size: em(11);
          line-height: 1.54;
        }
      }

      .cic-1 {
        @include position(absolute, 10px null null -40px);
        @include size(85px auto);
      }

      .cic-2 {
        @include position(absolute, null -24px 9px null);
        @include size(48px auto);
      }
    }
  }

  .hp-about-cancer {
    position: relative;
    float: right;
    width: 50%;
    overflow: hidden;
    z-index: 1;
    background-image: linear-gradient(0deg, #A72F91 0%, #A03893 7%, #7470A0 53%, #41B0AF 100%);

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:hover {

      .bg {
        @include transform(scale(1.1));
      }
    }

    .bg {
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: url('../../images/build/pattern-4.png') no-repeat center;
      background-size: cover;
      opacity: 0.94;
      @include transition(all 333ms linear);
    }

    .content {
      @include position(absolute, 50% 0 null 0);
      @include transform(translateY(-50%));
      max-width: 420px;
      margin: auto;
      color: $white;
      text-align: center;
      z-index: 1;
    }

    .title {
      font-size: em(45);
      font-weight: lighter;
      line-height: 1.1;
      letter-spacing: -0.2px;
      margin: 0 auto 29px;
      text-transform: capitalize;
      max-width: 400px;
    }

    .text {
      font-size: em(15);
      color: $white;
      line-height: 1.53;
      margin-bottom: 23px;
    }

    .link {
      color: $white;

      &:after {
        background: url('../../images/build/arrow-black.svg') no-repeat center $white;
      }
    }

    @include media($tablet) {

      .content {
        max-width: 350px;
      }

      .title {
        font-size: em(30);
        margin: 0 auto 19px;
      }

      .text {
        font-size: em(11);
      }

      .link {
        font-size: em(12);
      }
    }

    @include media($mobile) {
      width: 100%;
    }
  }

  .hp-event {
    position: relative;
    float: left;
    width: 50%;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-top: 50%;
      background-image: linear-gradient(224deg, rgba(255, 255, 255, 0.70) 0%, $white 75%);
      z-index: -1;
    }

    &:hover {

      .hp-event-bg {
        @include transform(scale(1.15));
      }
    }

    .hp-event-bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      @include transition(all 333ms linear);
      z-index: -1;
    }

    .content {
      @include position(absolute, 50% null null 10%);
      @include transform(translateY(-50%));
      max-width: 420px;
    }

    .title {
      font-size: em(40);
      font-weight: lighter;
      line-height: 1.3;
      margin-bottom: 8px;
    }

    .text {
      font-size: em(15);
      color: $dark_grayish_violet;
      line-height: 1.53;
      margin-bottom: 15px;
    }

    @include media($tablet) {

      .content {
        max-width: 350px;
      }

      .title {
        font-size: em(27);
        margin-bottom: 10px;
      }

      .text {
        font-size: em(11);
        margin-bottom: 16px;
      }

      .link {
        font-size: em(12);
      }
    }

    @include media($mobile) {
      width: 100%;
    }
  }

  .hp-twitter {
    position: relative;
    float: left;
    width: 50%;
    background: $white;
    text-align: center;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-top: 50%;
      z-index: -1;
    }

    .icon {
      position: relative;
      @include size(80px);
      background-color: $cyan_moderate;
      background-size: 8px auto;
      border-radius: 50%;
      margin: 0 auto 7px;
      padding: 17px;

      img{
        width: 46px;
        height: auto;
      }
    }

    h3 {
      font-weight: lighter;
      font-size: em(21);
      line-height: 1.33;
      letter-spacing: -0.19px;
      color: $color_main;
      margin-bottom: 6px;
    }

    a {
      color: $moderate_magenta;
      font-size: em(15);
      margin-bottom: 28px;
      display: inline-block;
      text-decoration: none;
    }

    .item {
      margin: 0 15px;
      opacity: 0;
      outline: none;

      &.slick-current {
        opacity: 1;
      }
    }

    .date {
      font-weight: 600;
      font-size: em(15);
      margin-bottom: 17px;
    }

    .text {
      font-size: em(16);
      margin: 0 auto;
      line-height: 1.43;
    }

    .slick-current {

      .text {
        max-width: 350px;
      }
    }

    .content {
      @include position(absolute, 50% 0 null 0);
      @include transform(translateY(-50%));
    }

    @include media($desktop_small) {

      a {
        margin-bottom: 20px;
        font-size: em(14);
      }

      .date {
        margin-bottom: 12px;
        font-size: em(13);
      }

      .text {
        font-size: em(14);
      }
    }

    @include media($tablet) {

      .icon {
        display: none;
      }

      h3 {
        font-size: em(15);
      }

      .slick-dots {
        bottom: -20px;
      }
    }

    @include media($mobile) {
      width: 100%;
      @include pad(43px 0 25px);

      &:after {
        content: none;
      }

      .content {
        @include position(relative, inherit inherit inherit inherit);
        @include transform(translateY(0));
      }

      .twitter-slide {
        @include pad(0 0 30px);
      }

      .icon {
        display: inline-block;
      }

      h3 {
        font-size: em(19);
        margin-bottom: 8px;
      }

      a {
        font-size: em(13);
        margin-bottom: 40px;
      }

      .date {
        font-size: em(15);
        margin-bottom: 24px;
      }

      .text {
        font-size: em(16);
        line-height: 1.435;
      }

      .slick-dots {
        bottom: 0;
      }
    }
  }

  .hp-block-wrapper {
    float: left;
    width: 50%;
    overflow: hidden;

    @include media($mobile) {
      width: 100%;
    }
  }

}

.fc-box {
  width: 50%;
  float: left;
  overflow: hidden;


}
