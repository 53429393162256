.checkout-container,
.donation-details {
  @include outer-container(1200px);
  @include media($mobile) {
    padding: 0 38px;

  }

  fieldset {
    border: none;
  }

  .wrapper {
    @include shift(2);
    @include clearfix();
    @include span-columns(8);
    border-left: 1px solid $gray_light;
    border-right: 1px solid $gray_light;
    @include media($mobile) {
      @include shift(0);
      @include span-columns(8 of 8);
      border: none;

    }

    .left-column {
      @include pad(75px 0);
      @include span-columns(3 of 8);

      .messages {
        display: none;
      }
      @include media($mobile) {
        @include span-columns(8 of 8);
        padding: 0 0 25px 0;
        margin-top: 27px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: -50px;
          right: 100vw;
          height: 1px;
          width: 200vw;
          bottom: 0;
          //border-bottom: solid 1px ;
          background: rgba(242, 242, 242, 1);
        }
      }
    }

    .right-column {
      @include pad(75px 70px 75px 45px);
      @include span-columns(5 of 8);
      border-left: 1px solid $gray_light;
      @include media($mobile) {
        @include span-columns(8 of 8);
        padding: 25px 0 0 0;
        border: none;
      }

      .payment-errors {
        margin-top: 20px;
      }

      .warning {
        border: 1px solid $red;
        background: $white;
        font-weight: lighter;
        font-size: em(14);
        @include pad(15px);
        color: $color_main;
      }
      @include media($mobile) {
        h1 {
          font-size: em(19px);
          text-align: center;
          border-bottom: solid 1px rgba(242, 242, 242, 1);
          padding-bottom: 25px;
        }
      }
    }
  }

  .product_title {
    font-size: em(15);
    margin-bottom: 4px;
    line-height: 1.6;
  }

  .product_description {
    font-size: em(13);
    font-weight: lighter;
    line-height: 1.46;
    margin: 20px 0 19px;
    @include media($mobile) {
      margin: 0 0 10px;
    }

    a {
      color: $dark_very_violet;
    }
  }

  .cart_contents {
    padding: 0 55px;
    overflow: hidden;

    h3, legend,
    .fieldset-legend {
      font-weight: 600;
      color: $moderate_magenta;
      font-size: em(15);
      margin-bottom: 13px;
      text-transform: capitalize;
    }

    .donation_type {
      font-weight: lighter;
      font-size: em(13);
      line-height: 1.46;
    }

    .donation_amount {
      font-size: 22px;
      line-height: 1.27;
      letter-spacing: -0.2px;
      display: inline-block;
      vertical-align: middle;
      @include media($mobile) {
        margin-bottom: 20px;
      }
    }

    .donation_currency {
      font-size: 15px;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
    }

    .total_caption {
      font-weight: lighter;
      font-size: em(13);
      padding-top: 10px;
    }
    @include media($mobile) {
      padding: 0;
    }
  }

  section {
    margin: 48px 0;
    @include media($mobile) {
      margin: 34px 0 0 0;

    }
  }

  .title {
    font-weight: lighter;
    font-size: em(27);
    letter-spacing: -0.24px;
    padding-bottom: 21px;
    border-bottom: 1px solid $gray_light;
    margin-bottom: 40px;
  }

  .sub-title {
    font-weight: 600;
    font-size: em(15);
    margin-bottom: 25px;
  }

  .form-item {
    margin-bottom: 20px;
    position: relative;
    width: 380px;
    @include media($mobile) {
      width: auto;
    }
  }

  input {
    @include size(100% 50px);
    border: 1px solid $gray_light;
    font-size: em(16);
    font-weight: lighter;
    letter-spacing: -0.14px;
    @include pad(17px 16px 13px);
    z-index: 1;
    @include transition(all 333ms linear);
  }

  #card-element {
    border: 1px solid $gray_light;
    padding: 10px;
  }

  label {
    font-size: em(16);
    font-weight: lighter;
    letter-spacing: -0.14px;
  }

  .form-type-textfield {

    label {
      @include position(absolute, 18px null null 16px);
      @include transition(all 333ms linear);
      z-index: -1;
    }

    &.active {

      label {
        font-size: em(9);
        top: 8px;
        left: 16px;
      }
    }
  }

  .card_exp_text {
    display: inline-block;
    vertical-align: middle;
    font-size: em(13);
    font-weight: lighter;
    margin-bottom: 10px;
    line-height: 1.46;
  }

  .card_exp_wrapper {
    display: block;
    position: relative;

    .what_is_cvc {
      @include position(absolute, -28px 86px null null);

      a {
        font-size: em(13);
        text-transform: uppercase;
        text-decoration: none;
        color: $dark_grayish_violet_3;
      }
    }

    .form-item {
      margin: 0 10px 0 0;
    }

    .form-type-select {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 65px;
    }

    .field_cvc {
      display: inline-block;
      vertical-align: middle;
      width: 140px;
    }
  }

  .birth-age {
    margin-bottom: 34px;
    @include media($mobile) {
      margin-bottom: 0;
    }

    .birth_wrapper,
    .age_wrapper {
      display: inline-block;
      vertical-align: middle;

      &.birth_wrapper {
        width: 140px;
        margin-right: 23px;
        @include media($mobile) {
          width: 100%;
        }
      }

      &.age_wrapper {
        width: 215px;
        @include media($mobile) {
          width: 100%;
        }
      }

      .form-item {
        width: 100%;
        margin: 0;
        @include media($mobile) {
          margin-bottom: 20px;
        }
      }

    }
  }

  .receipt {
    margin-bottom: 57px;
    @include media($mobile) {
      margin-bottom: 35px;
    }

    .remark {
      margin-bottom: 16px;
    }

    .form-item {
      margin: 0;
    }
  }

  .chosen-container {
    font-size: em(16);
    color: $color_main;

    .chosen-single {
      @include pad(12px 0 0 0);
      text-align: center;

      div {
        display: none;
      }
    }

    .chosen-results {
      font-size: em(16);
      font-weight: lighter;
      color: $color_main;

      .disabled-result {
        display: none;
      }
    }

    .chosen-drop {
      background: $white;
    }
  }

  select {
    font-size: em(16);
    color: $color_main;
    background: $white;
    border: 1px solid $gray_light;
    @include pad(12px 16px);
    background: url('../../images/build/select_arrow.png') no-repeat calc(100% - 16px) center;
    background-size: 14px auto;
    border-radius: 0;

  }

  .remark {
    font-size: em(13);
    font-weight: lighter;
    margin-bottom: 10px;
  }

  .error {
    font-size: em(14);
    background: transparent;
    @include pad(15px);
    color: $color_main;
    font-weight: lighter;
    border: 1px solid $red !important;
  }

  .error-input {
    border: 1px solid $red;
  }

  .fieldset-legend {
    font-size: em(15);
    font-weight: 600;
    margin-bottom: 20px;
    @include media($mobile) {
      margin-bottom: 0;
    }
  }

}

.cc_details {
  select {
    background: none;
  }
}

.checkout-steps {
  background: $white_2;
  text-align: right;
  @include pad(11px 52px 13px);

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0;
    font-size: em(13);
    margin-left: 35px;
    color: rgba(136, 134, 138, 1);
    &.active {
      color: rgba(69, 65, 71, 1);
    }
    &:first-of-type {
      margin: 0;
    }
    @include media($mobile) {
      margin-left: 15px;
    }
  }
  @include media($mobile) {
    padding: 15px 0;
    margin: 0;
    text-align: center;
  }
}

.checkout-buttons {

  .button-operator {
    display: none;
  }
}

.commerce_payment,
.customer_profile_billing {
  padding: 0;
  margin: 0;

  .form-item-commerce-payment-payment-method {
    display: none;
  }

  legend {
    display: none;
  }

  .commerce-credit-card-expiration {
    margin-bottom: 0;
    margin-right: 20px;
    display: inline-block;
  }

  .form-item-commerce-payment-payment-details-credit-card-exp-month,
  .form-item-commerce-payment-payment-details-credit-card-exp-year,
  .form-item-commerce-payment-payment-details-credit-card-code {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 10px 0 0;
    width: 80px;

    &.form-item-commerce-payment-payment-details-credit-card-code {
      width: calc(100% - 220px);
    }

    label {
      font-size: em(13);
      font-weight: lighter;
      margin-bottom: 10px;
    }
  }

  .commerce-month-year-divider {
    display: none;
  }
}

.customer_profile_billing {

  legend {
    display: block;
    margin-bottom: 20px;
    @include media($mobile) {
      margin: 0;
    }
  }

  .form-type-select {

    label {
      display: none;
    }
  }
}

.fieldset-wrapper {

  .checkout-continue {
    background: rgba(179, 62, 150, 1);
    padding: 7px 19px;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: em(14);
    display: inline;
    width: auto;
  }

  .checkout-cancel {
    display: none;
  }
}

.page-checkout-complete {
  @include media($mobile) {
    text-align: center;
  }

  .complete-wrapper {
    @include outer-container(1200px);
  }

  .complete-container {
    @include shift(2);
    @include span-columns(8);
    @include pad(116px 0 392px);
    @include media($mobile) {
      @include shift(0);
      @include span-columns(8 of 8);
      @include pad(35px 34px 0 34px);
    }
  }

  h2.dark {
    font-weight: lighter;
    font-size: em(36);
    margin-bottom: 18px;
    letter-spacing: -0.32px;
    line-height: 1.44;
    @include media($mobile) {
      font-size: em(27px);
      text-align: left;
    }
  }

  h3.light {
    margin-bottom: 38px;
    max-width: 560px;
    font-size: em(21);
    font-weight: lighter;
    letter-spacing: -0.19px;
    line-height: 1.5;
    @include media($mobile) {
      font-size: em(16px);
      text-align: left;
    }
  }
}

.signup {
  .checkbox {
    &.agree {
      background: transparent;
      position: relative;
      &:after {
        content: '';
        background: #FFF;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        top: 2px;
        left: 2px;
      }
    }
  }
}

.newsletter-agree_wrapper,
.tnc-agree_wrapper {
  margin-bottom: 13px;

  .checkbox {
    @include size(13px);
    border: 1px solid $dark_very_grayish_violet_3;
    border-radius: 50%;
    margin-right: 11px;
    cursor: pointer;

    &.agree {
      background: url('../../images/build/cross.png') no-repeat center;
      background-size: cover;

    }
  }

  .checkbox, span, a {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    font-size: em(13);
    color: $dark_very_grayish_violet;
  }

  a {
    color: $dark_grayish_violet_3;
    text-transform: uppercase;
    margin-left: 8px;
    text-decoration: none;
    font-size: rem(13px);
  }

  &.tnc-agree_wrapper {
    margin-bottom: 49px;
  }
}

.page-checkout {

  form#commerce-checkout-form-checkout {

    #edit-account {
      padding: 0;
      margin-top: 35px;
    }
  }
}