.node-get-in-touch {
  margin-top: 56px;
  @include outer-container();

  @include media($mobile) {
    margin-top: 29px;
  }

  .wrapper {
    @include shift(3);
    @include span-columns(6);

    @include media($desktop_small) {
      @include shift(2);
      @include span-columns(8);
    }

    @include media($tablet) {
      @include shift(1);
      @include span-columns(10);
    }

    @include media($mobile) {
      @include shift(0);
      @include span-columns(12);
    }
  }

  .header {
    text-align: center;
    margin-bottom: 55px;

    .title {
      font-size: em(43);
      font-weight: lighter;
      line-height: 1.21;
      letter-spacing: -0.38px;
      margin-bottom: 7px;

      @include media($mobile) {
        font-size: em(30);
        margin-top: 25px;
      }
    }

    p {
      font-size: em(14);
      line-height: 1.64;

      @include media($mobile) {
        font-size: em(12);
        margin-top: 10px;
      }
    }

    @include media($mobile) {
      margin-bottom: 25px;
      @include pad(0 38px);
    }
  }

  .contact-form {

    .form-item {
      margin: 0;
    }

    .field-type-text,
    .field-type-email,
    .webform-component-textfield {
      position: relative;
      display: inline-block;
      width: calc(50% - 7.5px);
      margin-bottom:15px;
      margin-right: 5px;

      &.field-name-field-first-name,
      &.field-name-field-email
      {

        margin-right: 15px;
      }

      .form-text {
        width: 100%;
      }

      label {
        @include transition(all 333ms ease-out);
        @include position(absolute, 22px null null 16px);
      }

      &.active {

        label {
          top: 8px;
          font-size: em(9);
          line-height: 2.1;
        }
      }

      @include media($mobile) {
        width:100%;

        &.field-name-field-first-name,
        &.field-name-field-email {
          margin-right: 0;
        }
      }
    }

    input[type="text"],
    input[type="email"] {
      background: $white;
      border: 1px solid $gray_light;
      @include pad(27px 16px 10px);

      &.error {
        background: url('../../images/build/error-input.png') no-repeat calc(100% - 17px) center;
        background-size: 14px auto;
      }
    }

    .field-type-text,
    .field-type-email,
    .field-type-list-text,
    .webform-component-text {
      margin-bottom: 15px;

      @include media($mobile) {
        margin-bottom: 10px;
      }
    }

    .chosen-single {

      span {
        color: $dark_very_violet;
        font-size: 16px;
        font-weight: lighter;
      }
    }

    .chosen-drop {

      .chosen-results {
        margin: 0;
        padding: 0;
      }

      .active-result {
        @include pad(20px 0);
        color: $dark_very_violet;
        font-size: 16px;
        font-weight: lighter;
      }
    }

    .field-type-list-text {

      label {
        display: none;
      }

      .form-select {
        border: 1px solid $gray_light;
        @include pad(15px 16px 20px);
        background: url('../../images/build/select_arrow.png') no-repeat calc(100% - 16px) center;
        background-size: 13px auto;
      }
    }

    .field-type-text-long,.webform-component-textarea {
      margin-bottom: 20px;

      label {
        display: none;
      }

      textarea {
        border: 1px solid $gray_light;
        resize: none;
        @include pad(17px 16px);
        font-size: 16px;
        font-weight: lighter;
        letter-spacing: -0.14px;
        color: $dark_very_violet;

        @include placeholder {
          opacity: 1;
          color: $dark_very_violet;
        }
      }

      .grippie {
        display: none;
      }
    }

    .form-required {
      color: $color_main;
      margin-left: -3px;
    }

    .form-actions {
      margin: 30px 0 0;
    }

    .form-submit {
      position: relative;
      display: inline;
      font-size: em(14);
      text-transform: uppercase;
      background: $moderate_magenta;
      color: $white;
      border-radius: 100px;
      @include pad(8px 18px);
      width: 100px;
      box-sizing: border-box;
      text-align: center;
      text-decoration: none;
      outline: none;
      margin-bottom: 63px;
      @include transition(all 333ms linear);

      &:focus,
      &:hover {
        @include pad(9px 22px);
        box-shadow: 0 0 11px 2px rgba(167,47,145,0.50);
      }

      @include media($mobile) {
        margin-bottom: 25px;
      }
    }

    .messages.error {
      display: none;
    }

    @include media($mobile) {
      @include pad(0 38px);
    }
  }

  .contact-infor {
    border-top: 1px solid $gray_light;
    @include pad(50px 0 85px);

    @include media($mobile) {
      @include pad(30px 38px 25px);
    }

    .heading {
      font-size: em(27);
      font-weight: lighter;
      line-height: 1.03;
      letter-spacing: -0.24px;
      margin-bottom: 29px;

      @include media($mobile) {
        font-size: em(19);
        line-height: 1.15;
        margin-bottom: 15px;
      }
    }

    .infor-item {
      display: inline-block;
      width: 220px;
      margin: 0 25px 25px 0;

      @include media($mobile) {
        display: block;
        margin: 0 0 20px 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .sub-title {
      font-family: $font-main;
      font-size: em(15);
      font-weight: 500;
      margin-bottom: 11px;

      @include media($mobile) {
        margin-bottom: 5px;
      }
    }

    a {
      font-size: em(13);
      font-weight: lighter;
      color: $dark_very_violet;
      text-decoration: none;
    }
  }
}
.webform-component-select {
  margin-bottom:15px !important;
  label {
    display: none;
  }

}