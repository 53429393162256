.node-blog-press {

  .header {
    position: relative;
    background-size: cover;
    background-position: center;
    @include pad(163px 0 420px);
    z-index: 0;

    &:after {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.93);
      z-index: -1;
    }

    .content {
      max-width: 550px;
      margin: 0 auto;
      text-align: center;
    }

    .title {
      font-weight: lighter;
      font-size: em(43);
      line-height: 1.21;
      letter-spacing: -0.38px;
      margin-bottom: 20px;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;
    }

    @include media($desktop) {
      display: none;
    }
  }

  .core {
    max-width: 1600px;
    z-index: 1;
    margin: -250px auto 0;

    .view-filters {
      position: relative;
      @include pad(11px 50px);
      border-top: 1px solid $gray_light;
      border-bottom: 1px solid $gray_light;

      .views-widget-filter-field_category_tid {
        float: right;
        padding: 0;
      }

      .views-exposed-widgets {
        margin: 0;
      }

      .chosen-container,
      label[for=edit-field-category-tid] {
        display: none;
      }

      .form-type-bef-link {
        float: right;
        margin-left: 22px;

        a {
          text-decoration: none;
          font-size: em(13);
          color: $dark_grayish_violet;
          text-transform: uppercase;
          @include transition(all 333ms linear);

          &.active,
          &:hover {
            color: $dark_very_grayish_violet;
          }
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .view-content {
      @include clearfix();
      border:solid 1px #F2F2F2;
    }

    @include media($desktop) {
      margin-top: 0;
    }

    @include media($tablet) {

      .view-filters {
        @include pad(9.5px 15px);

        .form-type-bef-link{
          margin-left: 9.5px;

          a {
            font-size: em(12);
          }
        }
      }
    }
  }

  .box {

    p {
      display: none;
    }

    &.w2-h2 {

      .bg:after {
        opacity: 0.9;
        background: $white;
      }
    }

    &.w1-h1 {

      .content  {

        h1 {
          font-size: em(21);
        }

        h3 {
          padding-bottom: 0;
        }

        h4 {
          font-size: em(13);
          color: $dark_very_grayish_violet;
        }
      }
    }

    @include media($desktop_small) {

      &.w2-h2 {

        .content {
          @include pad(0 45px 45px);

          h1 {
            font-size: em(30);
            margin-bottom: 30px;
          }
        }
      }

      &.w1-h1 {

        .content h1 {
          font-size: em(18);
        }
      }
    }

    @include media($tablet) {

      &.w2-h2 {

        .content {
          @include pad(0 30px 30px);

          h1 {
            font-size: em(22);
            margin-bottom: 30px;
          }
        }
      }

      &.w1-h1 {

        .content {
          @include pad(0 15px 20px);

          h1 {
            font-size: em(14);
          }
        }
      }
    }

    @include media($mobile) {

      &.w2-h2 {

        .content {
          @include pad(0);

          h1 {
            font-size: em(27);
          }
        }

        .bg:after {
          opacity: 1;
          background: linear-gradient(224deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,0.95) 84%);
        }
      }

      &.w1-h1 {

        .content {
          @include pad(0 20px 20px);

          h1 {
            font-size: em(16);
          }
        }
      }
    }
  }

  .archive-block {
    display: none;
  }

  .block-newsletter {

    .archive-block {
      display: block;
    }

    @include media($mobile) {
      width: 100%;
    }
  }
}