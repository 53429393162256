.three-color-boxes {
  z-index: 1;
  position: relative;
  float: left;
  width: calc(100% / 3);

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:nth-of-type(1) {
    background: $white;
  }

  &:nth-of-type(2) {
    background: $cyan_dark_moderate;

    .link:after {
      background: url('../../images/build/arrow-cyan.svg') no-repeat center $dark_very_violet;
    }
  }

  &:nth-of-type(3) {
    background-color: $dark_very_violet;
    color: $white;

    .title {
      color: $white;
    }

    .link {
      color: $white;

      &:hover {
        color: $moderate_magenta;
      }

      &:after {
        background: url('../../images/build/arrow-black.svg') no-repeat center $white;
      }
    }
  }

  .content {
    margin: auto;
    width: 75%;
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
  }

  .icon {
    display: block;
    width: 33px;
    margin-bottom: 28px;
  }

  .title {
    font-size: em(22);
    line-height: 1.3;
    letter-spacing: -0.2px;
    margin-bottom: 19px;
    font-weight: normal;
  }

  .text {
    font-size: em(14);
    line-height: 1.64;
    margin-bottom: 37px;
  }

  .link {
    @include position(absolute, null null 0 0);
    font-size: em(15);
  }

  @include media($desktop_small) {

    .content {
      width: 85%;
    }

    .title {
      margin-bottom: 10px;
    }

    .text {
      margin-bottom: 25px;
    }
  }

  @include media($tablet) {
    width: 100%;

    &:before {
      content: none;
    }

    .content {
      padding: 43px 30px 40px;
      @include position(relative, inherit);
      @include transform(translateY(0));
      width: 100%;
    }

    .icon {
      width: 40px;
      margin-bottom: 8px;
    }

    .title {
      font-size: em(20);
      margin-bottom: 10px;
    }

    .text {
      font-size: em(13);
      margin-bottom: 40px;
    }

    .link {
      font-size: em(13);
      @include position(relative, inherit);
    }
  }

  @include media($mobile) {

    .text, .link {
      font-size: em(12);
    }
  }
}