/* ----- PUT ALL YOUR AMAZING MIXINS IN HERE ----- */

// Image replacement
@mixin replace(){
    display: block;
    overflow: hidden;
    height: 0;
}

// Resets the last column number
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin bre-transition(){
  transition: all 0.3s ease-in-out;
}