.node-sponsor-a-girl {

  .header {
    position: relative;
    z-index: 2;
    @include pad(123px 0 127px);
    @include outer-container();
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.8);
      z-index: -1;
    }

    .wrapper {
      max-width: 700px;
      margin: 0 auto;
      text-align: center;
    }

    .title {
      font-size: em(44);
      font-weight: lighter;
      line-height: 1.23;
      letter-spacing: -0.39px;
      margin-bottom: 16px;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;
      margin-bottom: 30px;
    }

    .btn-down {
      @include size(40px);
      background: url('../../images/build/arrow-down.svg') no-repeat center $moderate_magenta;
      background-size: 11px auto;
      border-radius: 50%;
      display: inline-block;
    }

    @include media($mobile) {
      @include pad(152px 44px 49px 30px);
      background-position: right center;

      .wrapper {
        text-align: left;
        max-width: 100%;
      }

      .title {
        font-size: em(30);
        line-height: 1.06;
        letter-spacing: -0.27px;
        margin-bottom: 10px;
      }

      .text {
        font-size: em(12);
        line-height: 1.58;
        margin-bottom: 0;
      }

      .btn-down {
        @include position(absolute, null 0 -20px 0);
        margin: auto;
      }
    }
  }

  .section-1, .section-2,
  .section-3, .section-4,
  .section-5, .section-6 {
    border-left: 1px solid $gray_light;
    border-right: 1px solid $gray_light;

    @include media($desktop) {
      border: 0;
    }
  }

  .section-1 {
    position: relative;
    z-index: 1;
    @include pad(56px 20px 69px);
    @include outer-container();
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    outline: none;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.93);
      z-index: -1;
    }

    .title {
      font-size: em(23);
      font-weight: lighter;
      line-height: 2.26;
      letter-spacing: -0.2px;
      color: $cyan_moderate;
    }

    .text {
      max-width: 650px;
      font-weight: lighter;
      font-size: em(27);
      line-height: 1.41;
      letter-spacing: -0.24px;
      margin: 0 auto;
    }

    @include media($mobile) {
      @include pad(46px 35px 48px);

      .title {
        font-size: em(21);
        margin-bottom: 5px;
        line-height: 1.1;
      }

      .text {
        font-size: em(16);
        line-height: 1.25;
        letter-spacing: -0.14px;
      }

    }
  }

  .section-2 {
    @include outer-container();
  }

  .section-3 {
    position: relative;
    @include outer-container();
    @include pad(78px 6.5% 76px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.8);
      z-index: -1;
    }

    .wrapper {
      max-width: 750px;
    }

    .title {
      font-size: em(36);
      font-weight: lighter;
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 17px;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;

      p {
        margin-bottom: 20px;
      }
    }

    @include media($mobile) {
      text-align: center;
      @include pad(60px 35px 52px);

      &:before {
        background: rgba(255, 255, 255, 0.9);
      }

      .wrapper {
        max-width: 750px;
      }

      .title {
        font-size: em(27);
        line-height: 1.11;
        letter-spacing: -0.24px;
      }

      .text {
        font-size: em(12);
        line-height: 1.91;
      }
    }
  }

  .section-4 {
    @include outer-container();

    .donate-box {
      position: relative;
      width: 100%;
      height: 150px;

      &:after {
        content: '';
        display: block;
        background: $moderate_dark_pink;
      }
    }

    .heading {
      background: $moderate_dark_pink;

      .title {
        @include position(absolute, 50% null null 50%);
        transform: translateY(-50%) translateX(-50%);
        @include pad(0 62px);
        margin: 0 auto;
        font-weight: lighter;
        font-size: em(40);
        color: $white;
        letter-spacing: -0.36px;
      }
    }

    .donate-type {
      background: $white_2;

      &:nth-of-type(2) {
        background: $white;
      }

      .content {
        width: 75%;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        margin: 0 auto;
      }

      .icon {
        display: block;
        margin-bottom: 23px;
      }

      .title {
        font-weight: lighter;
        font-size: em(27);
        line-height: 1.15;
        margin-bottom: 12px;
      }

      .text {
        font-size: em(14);
        color: $dark_grayish_violet;
        line-height: 1.57;
      }
    }

    @include media($desktop_small) {

      .donate-type {

        .content {
          width: 85%;
        }

        .icon {
          margin-bottom: 10px;
        }

        .title {
          font-size: em(20);
          margin-bottom: 10px;
        }

        .text {
          font-size: em(13);
        }
      }
    }

    @include media($tablet) {

      .donate-box {
        width: 50%;
      }

      .heading .title {
        top: 50%;
        @include transform(translateY(-50%));
      }

      .donate-type {
        background: $white_2;

        &:nth-of-type(3) {
          background: $white_2;
        }

        .title {
          font-size: em(30);
        }

        .text {
          font-size: em(15);
        }
      }
    }

    @include media($mobile) {

      .donate-box {
        width: 100%;

        &:after {
          content: none;
        }
      }

      .heading {
        @include pad(25px);
        text-align: center;

        .title {
          padding: 0;
          font-size: em(27);
          @include transform(translateY(0));
          @include position(relative, inherit);
        }
      }

      .donate-slick {
        width: 100%;
        display: inline-block;

        .slick-dots {
          bottom: 15px;
          text-align: center;
        }
      }

      .donate-type {
        outline: none;
        height: auto;
        margin: 0;
        @include pad(15px 35px);

        .content {
          width: 100%;
          @include pad(17px 22px);
          @include transform(translateY(0));
          @include position(relative, inherit);
        }

        .icon {
          margin-bottom: 9px;
        }

        .title {
          font-size: em(19);
          margin-bottom: 5px;
        }

        .text {
          font-size: em(12);
          line-height: 1.83;
        }
      }
    }
  }

  .three-color-boxes {
    .icon-wrap {
      display: inline-block;
      background: $white;
      padding: 12px;
      border-radius: 100px;
      width: 55px;
      height: 55px;
      margin-bottom: 10px;

      .icon {
        margin: 0;
      }
    }
  }

  .section-5 {
    @include outer-container();

    .left, .right {
      width: 50%;
      float: left;
      z-index: 1;
      position: relative;
    }

    .left {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &:after {
        content: '';
        display: block;
        padding-top: 100%;
        background: rgba(74, 43, 102, 0.9);
        z-index: -1;
      }

      .content {
        @include pad(0 20px);
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        max-width: 460px;
        margin: 0 auto;
        color: $white;
        text-align: center;
      }

      .title {
        font-size: em(40);
        line-height: 1.3;
        font-weight: lighter;
        margin-bottom: 16px;
        letter-spacing: -0.36px;
      }

      .text {
        font-size: em(15);
        line-height: 1.53;

        p {
          margin-bottom: 20px;
        }
      }
    }

    .right {

      .donate-way {
        position: relative;
        min-height: 150px;
        border-bottom: 1px solid $gray_light;

        &:last-of-type {
          border-bottom: 0;
        }

        &.toggled {
          height: auto !important;

          .content {
            position: relative;
            @include transform(translateY(0));
          }

          .sub-title {
            display: none;
          }
        }

        &.not-on-focus {
          height: auto !important;

          .title {
            font-size: em(27);
            margin-bottom: 16px;
          }

          .sub-title {
            display: none;
          }
        }
      }

      .content {
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        margin: 0 auto;
        max-width: 510px;
        @include pad(20px);
        @include transition(all 666ms ease-out);
      }

      .title {
        font-size: em(36);
        line-height: 1.4;
        font-weight: lighter;
        margin-bottom: 6px;
        @include transition(all 666ms ease-out);
      }

      .sub-title {
        font-size: em(15);
        line-height: 1.53;
        margin-bottom: 23px;
        font-weight: normal;
        color: $dark_grayish_violet;
      }

      .text {
        display: none;
        font-size: em(15);
        color: $dark_grayish_violet;

        strong {
          font-weight: bold;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }

    @include media($desktop_small) {

      .right {

        .title {
          font-size: em(28);
        }

        .sub-title {
          font-size: em(14);
          margin-bottom: 15px;
        }
      }
    }

    @include media($tablet) {

      .left, .right {
        width: 100%;
      }

      .left {

        &:after {
          @include position(absolute, 0 0 0 0);
          @include size(100%);
          padding-top: 0;
        }

        .content {
          @include pad(35px);
          @include position(relative, inherit);
          @include transform(translateY(0));
        }
      }

      .right {

        .donate-way {
          min-height: 0;
          height: auto !important;
          border-bottom: 1px solid $gray_light;

          &:last-of-type {
            border-bottom: 0;
          }
        }

        .content {
          @include position(relative, inherit);
          @include transform(translateY(0%));
          max-width: 100%;
          @include pad(30px);
        }
      }
    }

    @include media($mobile) {

      .left {

        .title {
          font-size: em(27);
          line-height: 1.11;
          margin-bottom: 17px;
          letter-spacing: -0.24px;
        }

        .text {
          font-size: em(11);

          p:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .right {

        .donate-way {

          &.not-on-focus {

            .title {
              font-size: em(18);
              margin-bottom: 15px;
            }
          }
        }

        .title {
          font-size: em(27);
          line-height: 1.11;
          margin-bottom: 13px;
        }

        .sub-title {
          font-size: em(12);
          line-height: 1.5;
          margin-bottom: 20px;
        }

        .text {
          font-size: em(15);

          p {
            margin-bottom: 15px;
          }
        }

        .link {
          font-size: em(12);
        }
      }
    }
  }

  .section-6 {
    position: relative;
    @include outer-container();
    @include pad(85px 20px 82px);
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    &:before {
      content: '';
      @include size(100%);
      @include position(absolute, 0 0 0 0);
      background: rgba(255, 255, 255, 0.9);
      z-index: -1;
    }

    .content {
      max-width: 420px;
      margin: 0 auto;
    }

    .title {
      font-size: em(36);
      font-weight: lighter;
      line-height: 1.44;
      letter-spacing: -0.32px;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    .text {
      color: $dark_very_grayish_violet_2;
      font-size: em(15);
      line-height: 1.53;
      margin-bottom: 20px;
    }

    @include media($mobile) {
      @include pad(32px);

      .content {
        max-width: 350px;
      }

      .title {
        font-size: em(27);
        line-height: 1.11;
        letter-spacing: -0.24px;
        margin-bottom: 5px;
      }

      .text {
        font-size: em(11);
        line-height: 1.45;
        margin-bottom: 27px;
      }

      .link {
        font-size: em(12);
      }
    }
  }

  .donation-amount-select {
    .donation-amount {
      &:nth-of-type(1), &:nth-of-type(2) {
        display: none !important;
      }
    }
  }
}
