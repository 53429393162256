.stream {
  overflow: hidden;
  width: 100%
}

.box {
  background-position: center;
  background-size: cover;
  @include transition(all 333ms linear);

  &:before {
    @include transition(all 333ms linear);
  }

  &.photo {
    overflow: hidden;
  }

  .content {
    @include transition(all 333ms linear);
  }

  h1 {
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: inherit;
    overflow: auto;
    width: 100%;
  }

  .event-date {
    position: relative;
    font-size: em(14);
    margin-bottom: 15px;
    color: $dark_very_violet;
    padding-left: 8px;

    &:before {
      content: '';
      @include size(2px 18px);
      background: $moderate_magenta;
      @include position(absolute, 0 null 0 0);
      margin: auto;
    }

    @include media($mobile) {
      font-size: em(13);
      margin-bottom: 9px;
    }
  }

  &.w2-h2 {
    @include span-columns(12);
    position: relative;
    overflow: hidden;

    &.right {
      float: right !important;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &:hover {

      .bg {
        @include media($overtablet) {
          @include transform(scale(1.15));
        }

        &:after {
          opacity: 0.8;
        }
      }
    }
    @include media($mobile) {
      width: 100%;

    }

    .bg {
      @include size(100%);
      @include position(absolute, 0 0 0 0);
      @include transition(all 333ms linear);
      background-size: cover;
      background-position: center;

      &:after {
        content: '';
        @include position(absolute, 0 0 0 0);
        @include transition(all 333ms linear);
        @include size(100%);
        background-image: linear-gradient(224deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.95) 84%);
      }
    }

    .event-date {
      font-size: em(25);
      line-height: 2.1;
      letter-spacing: -0.22px;
      margin-bottom: 4px;

      //@include media($mobile) {
      //  font-size: em(20);
      //}
    }

    .content {
      @include pad(0 66px 67px);
      @include position(absolute, null null 0 0);
      //@include position(absolute, 50% null null 0);
      //@include transform(translateY(-50%));
      height: auto;
      max-width: 660px;
      @include media($mobile) {
        @include pad(0 49px 0 21px);

      }

      h1 {
        font-size: em(36);
        margin-bottom: 80px;
        line-height: 1.36;
        letter-spacing: -0.32px;
        color: $color_main;

        //@include media($mobile) {
        //  font-size: em(27);
        //}
      }

      h3 {
        font-size: em(15);
        font-weight: 600;
        color: $moderate_magenta;
      }

      h4 {
        font-size: em(13);
      }

      .link-oval {
        max-width: 30px;
        display: inline-block;
        @include pad(7px 18px);
        color: $white;
      }

      p {
        color: $color_main;
      }
    }

    @include media($mobile) {
      width: 100%;
      @include pad(145px 21px 35px);

      &:after {
        content: none;
      }

      .event-date {
        font-size: em(20);
        line-height: 1.4;
        letter-spacing: -0.18px;
        margin-bottom: 13px;
      }

      .content {
        @include position(relative, inherit inherit inherit inherit);
        @include transform(translateY(0));
        @include pad(0);

        h1 {
          font-size: em(27);
          margin-bottom: 8px;
          line-height: 1.33;
          letter-spacing: -0.24px;
          min-height: 70px;
        }

        p {
          display: none;
        }

        .link-oval {
          font-size: em(12);
        }
      }
    }

  }

  &.w1-h1 {
    @include span-columns(6);
    @include omega(2n);
    position: relative;
    background: $white_2;
    margin: 0;

    @include media($mobile) {
      //@include span-columns(6);
      //@include omega(2n);
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &.photo {

      .content {
        background-size: cover;
        background-position: center;
        //background: $post-image-background;
      }

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        z-index: 1;
        background-image: linear-gradient(146deg, rgba(0, 0, 0, 0.00) 39%, rgba(0, 0, 0, 0.40) 100%);
      }
    }

    &.text {

      .content {
        //top:50%;
        //transform:translateY(-50%);
        bottom: 0;
        height: auto;
        @include pad(0 45px 35px);
        overflow: hidden;

        h1 {
          font-weight: lighter;
          color: $color_main;
          font-size: em(23);
          overflow: hidden;
          margin-bottom: 10px;

          &.substring {
            display: none;
          }
        }

        h3 {
          font-weight: 600;
          font-size: em(15);
          margin-bottom: 6px;
        }

        h4 {
          font-size: em(14);
          color: $dark;
        }

        p {
          font-size: em(14);
          line-height: 1.5;
          color: $dark_grayish_violet;
          margin: 0;
        }

        .link {
          width: auto;
          opacity: 0;
          visibility: hidden;
          @include transition(all 333ms linear);
        }

        @include media($desktop) {
          @include pad(0 30px 25px);

          h1 {

            &.origin {
              display: none;
            }

            &.substring {
              display: inline-block;
              width: 100%;
            }
          }
        }

        @include media($desktop_small) {

          h1 {
            font-size: em(18);
          }

          h3 {
            font-size: em(14);
          }

          h4 {
            font-size: em(13);
            padding-bottom: 10px;
          }

          p {
            font-size: em(11);
          }

          .link {
            font-size: em(11);

            &:after {
              @include size(11px);
            }
          }
        }

        @include media($tablet) {
          @include pad(0 25px 15px);

          h1 {
            font-size: em(16);
            margin-bottom: 0;
          }

          h4 {
            display: none;
          }

          p {
            display: none;
          }
        }

        @include media($mobile) {
          @include pad(0 20px 15px);

          h1 {
            margin-bottom: 10px;
          }

          h3 {
            font-size: em(12);
            padding-bottom: 0;
          }

          p {
            display: block;
            font-size: em(14);
          }
        }

        @include media($lessmobile) {

          h1 {
            margin-bottom: 0;
          }

          p {
            display: none;
          }
        }
      }
    }
  }

  &.w2-h1 {
    @include span-columns(6);
    position: relative;
    background: grey;

    &:after {
      content: "";
      display: block;
      padding-bottom: 50%;
    }
  }

  &.right::before {
    content: '';
    position: absolute;
    top: 50%;
    background: url(../images/build/box-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 25px;
    transform: translateY(-50%) rotate(180deg);
  }

  &.left::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/build/box-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    width: 10px;
    height: 25px;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;

    h1, h3, h4 {
      padding: 0;
      margin: 0;
    }

    h1 {
      font-size: em(21);
    }

    h3 {
      font-size: $category-size;
      color: $category-colour;
      padding: 0 0 4px 0;
    }

    h4 {
      font-size: em(13);
      color: $dark_very_grayish_violet;
      letter-spacing: -0.12px;
      line-height: 0.9;
      padding: 0 0 21px 0;
    }
  }

  .programme-date {
    font-size: rem(13);
    color: $dark_very_grayish_violet;
    margin-bottom: 20px;
    border-left: 2px solid $moderate_dark_pink_2;
    padding-left: 8px;

    @include media($mobile) {
      display: none;
    }

  }

  .programme-category {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    position: absolute;
    top: 35px;
    left: 35px;

    img {
      width: 100%;
      height: auto;
    }

    @media(max-width: 1390px){
      top: 15px;
      left: 25px;
    }

    @include media($desktop_small) {
      width: 24px;
      height: 24px;
      top: 20px;
      left: 20px;
    }
  }

}

.post {
  @include clearfix();
  width: 50%;
  float: left;
  overflow: hidden;

  @include media($mobile) {
    width: 100%;
  }

  &:hover {

    .photo {

      &:before {
        opacity: 0.8;
      }

      .content {
        @include transform(scale(1.15));
      }
    }

    .text {

      .link {
        margin-top: 20px;
        visibility: visible !important;
        opacity: 1 !important;

        @include media($desktop_small) {
          margin-top: 10px;
        }
      }
    }
  }

  .photo + .text {

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: url(../../images/build/box-arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 10px;
      height: 25px;
      left: -9px;
      z-index: 1;
    }
  }

  .text + .photo {

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      background: url(../../images/build/box-arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 10px;
      height: 25px;
      left: -1px;
      z-index: 1;
    }
  }
}

.stream-filter-bar {

  ul {
    text-align: right;

    li {
      display: inline;

      a {
        font-weight: lighter;
        padding: 10px 0;
        text-decoration: none;
        text-transform: uppercase;
        color: $dark_grayish_violet;
        margin-right: 22px;
        font-size: 13px;
      }
    }
  }
}
