.newsletter {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  background: rgba(90,77,102,0.95);
  display: none;
  z-index: 200;

  .messages {
    display: none;
  }

  .newsletter_close {
    @include position(absolute, 50px 50px null null);
    @include size(35px);
    background: url('../../images/build/btn-close.png') no-repeat center;
    background-size: 100% auto;
    cursor: pointer;
  }

  .newsletter_wrapper {
    max-width: 600px;
    margin: 0 auto;
    @include pad(0 20px);
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
  }

  h1 {
    color: $white;
    font-size: em(43);
    font-weight: lighter;
    line-height: 1.21;
    letter-spacing: -0.38px;
    margin-bottom: 18px;
  }

  .group-title {
    color: $white;
    font-size: em(16);
    font-weight: lighter;
    line-height: 3.25;
    letter-spacing: -0.14px;
    border-bottom: 1px solid $gray;
    margin-bottom: 37px;

    &.more-information {
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        @include position(absolute, 0 0 0 null);
        @include size(13px);
        background: url('../../images/build/arrow.svg') no-repeat center $moderate_magenta;
        background-size: 3px auto;
        @include transform(rotate(-90deg));
        @include transition(all 333ms linear);
        border-radius: 50%;
        margin: auto;
      }

      .open {
        display: none;
      }

      .close {
        display: block;
      }

      &.active {

        &:after {
          @include transform(rotate(90deg));
        }

        .open {
          display: block;
        }

        .close {
          display: none;
        }
      }
    }
  }

  .field-type-text,
  .field-type-email,
  .field-type-list-text {
    position: relative;
    display: inline-block;
    width: calc(50% - 15px);
    margin-bottom: 20px;
    padding: 18px 30px;
    background: rgba(211,195,224,0.34);
    border-radius: 100px;

    &.field-name-field-first-name,
    &.field-name-field-email,
    &.field-type-list-text {
      margin-right: 30px;
    }

    &.error-field {
      border: 1px solid red;
    }

    .form-text {
      width: 100%;
    }

    .form-item {
      margin: 0;
      position: inherit;
    }

    label, .form-required {
      font-size: em(16);
      color: $white;
      font-weight: lighter;
      letter-spacing: -0.14px;
      @include transition(all 333ms ease-out);
    }

    input {
      font-size: em(16);
      color: $white;
      padding: 0 !important;
      border: 0;
      background: transparent;
      outline: none;

      &.error {
        border: 0;
      }
    }

    label {
      @include position(absolute, 22px null null 30px);
    }

    &.field-type-list-text label {
      display: none;
    }

    &.field-name-field-date-of-birth {

      label {
        @include position(absolute, -22px null null 30px);
        font-size: em(13);
      }

      input {
        @include placeholder {
          color: $gray_light;
          font-size: em(16);
          font-weight: lighter;
        }
      }
      @include media($mobile) {
        margin-top: 40px;
      }
    }

    &.active:not(.field-name-field-date-of-birth) {

      label {
        top: 4px;
        left: 30px;
        font-size: em(9);
        line-height: 2.1;
        color: $white;
      }
    }
    @include media($mobile) {
      display: block;
      width:100%;
    }
  }

  .chosen-container {
    border: 0;
    box-shadow: none;

    .chosen-single {
      border: 0;
      background: transparent;
      padding: 0;
      @include size(100% auto);
      color: $gray_light;
      box-shadow: none;
    }

    .chosen-drop {
      background: $white;
    }
  }

  .form-submit {
    background: $moderate_magenta;
    @include pad(8px 10px);
    display: block;
    width: 120px;
    margin: 39px auto 20px;
    border-radius: 100px;
    color: $white;
    text-transform: uppercase;
  }

  .remark {
    font-size: em(12);
    color: $gray_light_2;
    text-align: center;
    width: 100%;
  }
}