

// Bourbon grid display. Comment out to remove display.
$visual-grid: false;
$visual-grid-color: #EEEEEE;


// Set to false if you'd like to remove the responsiveness.
$responsive: true;

// Setup the column, grid, and gutter amounts.
$column: 60px;
$gutter: 0px;
$grid-columns: 12;

// We set the max width of the page using the px to em function in Bourbon.
// the first value is the pixel value of the width and the second is the base font size of your theme.
// In this instance we want the max width to be 1088px and on Line 6 of style.scss we have reset the base
// font size to 13px so the first value would be 1088 and the second 13
// ex. em(1088, 13)
$max-width-px: 1600;
$max-width: em($max-width-px, 16);

// Define your breakpoints.
$max-width-fluid: new-breakpoint(max-width $max-width-px + px $grid-columns);
$desktop: new-breakpoint(max-width 1600px 12);
$desktop_small: new-breakpoint(max-width 1220px 12);
$tablet: new-breakpoint(max-width 992px 12);
$overtablet: new-breakpoint(min-width 769px 12);
$mobile: new-breakpoint(max-width 768px 12);
$overmobile: new-breakpoint(min-width 481px 12);
$lessmobile: new-breakpoint(max-width 550px 12);
