.page-user-reset {
  #content-area {
    @include outer-container(600px);
    .password-strength {
      display: block;
    }
    input {
      border:solid 1px;
    }
    .form-item {
      text-align: center;
      position: relative;
      margin-bottom: 65px;
    }
    div.form-item div.password-suggestions {
      width:100%;
      border:none;
      background:$deeppurple;
      color:$white;
      padding:20px;
    }
    div.password-confirm {
      float: none;
      width:100%;
      text-align: center;
      display: none !important;
    }
    .password-strength {
      float: none;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      bottom: -40px;
      transform: translateX(-50%);
      font-family: Roboto-Light;
      padding:0;
      .password-strength-text {
        display:none;
      }
      .password-strength-title {
        margin-right:20px;
        font-size: em(12);
      }
      .password-indicator {
        display: inline-block;
        width:50%;
      }
    }
    .password-indicator div {
      background-color: $deeppurple;
      transition:all 333ms ease;
    }
    .form-item, .form-actions {
      width:100%;
    }
    .form-item {
      width: 100%;
      position: relative;
      display: inline-block;
      @include media($mobile) {
        width:100%;
        margin-bottom: 10px;
      }

      &:nth-of-type(2n) {
        margin-left: 0;
      }

      &.third {
        width: calc((100% - 140px) / 2);
        margin-left: 20px;
        @include media($mobile) {
          width:100%;
          margin:0 0 10px 0;
        }
      }

      label {
        @include position(absolute, 18px null null 16px);
        @include transition(all 333ms linear);
        z-index: -1;
      }

      input {
        @include size(100% 50px);
        border: 1px solid $gray_light;
        font-size: em(16);
        font-weight: lighter;
        letter-spacing: -0.14px;
        @include pad(17px 16px 13px);
        z-index: 1;
        @include transition(all 333ms linear);
      }

      &.active {

        label {
          font-size: em(9);
          top: 10px;
          left: 16px;
        }
      }
    }
  }
}