.entity-bean,
.budget-block,
.strengths-block {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: 50%;
  }

  &:after {
    content: '';
    @include position(absolute, 0 0 0 0);
    @include size(100% 102%);
    background: rgba(247, 247, 247, 0.9);
    z-index: -1;
  }

  &:hover {
    @include media($overtablet) {
      .block-bg {
        @include transform(scale(1.15));
      }
    }
  }

  .block-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    @include transition(all 333ms linear);
    z-index: -1;
  }

  .block-wrapper {
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
  }

  .block-title {
    font-size: em(36);
    font-weight: lighter;
    margin-bottom: 14px;
    letter-spacing: -0.32px;
    line-height: 1.44;
  }

  .block-text {
    color: $dark_very_grayish_violet_2;
    font-size: em(14);
    line-height: 1.64;
    margin-bottom: 18px;
  }

  &.bean-get-involved {

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100% 102%);
      background: rgba(74, 43, 102, 0.95);
      z-index: -1;
    }

    .block-title,
    .block-text, .link {
      color: $white;
    }

    .link::after {
      background-color: $white;
      background-image: url('../../images/build/arrow-black.svg');
    }

    &.color-white {

      .block-title, .block-text, .link {
        color: $dark_very_violet;
      }

      .link {
        &:after {
          background: url("../../images/build/arrow.svg") no-repeat center #B33E96;
        }
      }

      &:after {
        background: rgba(247, 247, 247, 0.9);
      }
    }

    &.color-pink {

      &:after {
        background: rgba(153, 69, 132, 0.93);
      }
    }

  }

  &.bean-corporate-partnerships,
  &.bean-get-the-latest-update {

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100% 102%);
      background: rgba(153, 69, 132, 0.9);
      z-index: -1;
    }

    .block-title,
    .block-text, .link {
      color: $white;
    }

    .link::after {
      background-color: $white;
      background-image: url('../../images/build/arrow-magenta.svg');
    }

    .link:hover {
      color: $color_main;
    }
  }

  &.bean-learn-about-cancer {
    background-color: $dark_very_violet;

    &:after {
      background: rgba(44, 42, 46, 0.6);
    }

    .block-title,
    .block-text, .link {
      color: $white;
    }
  }

  @include media($tablet) {

    .block-wrapper {
      max-width: 350px;
    }

    .block-title {
      font-size: em(27);
      margin-bottom: 16px;
      letter-spacing: -0.24px;
      line-height: 1.11;
    }

    .block-text {
      font-size: em(12);
      line-height: 1.5;
    }

    .link {
      font-size: em(14);
    }
  }

  @include media($mobile) {

    .block-wrapper {
      padding: 0 20px;
    }

    .block-title {
      font-size: em(27);
      margin-bottom: 14px;
    }

    .block-text {
      font-size: em(11);
      line-height: 1.45;
    }

    .link {
      font-size: em(12);
    }
  }
}

.blurblock {
  filter: blur(5px);
}