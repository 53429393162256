.login_overlay {
  overflow: hidden;
  height: 100%;
  z-index: 200;
  @include position(fixed, -100% 0 null 0);
  @include transition(all 333ms ease);
  background: rgba(90,77,102,0.95);

  &.active {
    top: 0;

    //@include media($tablet) {
    //  top: 85px;
    //}
  }

  .newsletter-agree_wrapper {
    margin-bottom: 25px;

    span {
      color: $white;
    }

    .checkbox {
      border: 1px solid $white;
    }
  }

  .checkbox-container {
    overflow: hidden;
    padding: 40px 20px 20px 28px;
    background: rgba(211, 195, 244, 0.34);
    border-radius: 20px;
    position: relative;
    margin-bottom:20px;
    .form-text {
      position: absolute;
      top:20px !important;
    }
    label {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .login_window {
    position: relative;
    border:solid 2px transparent;
    height: 70%;
    overflow: scroll;
    overflow-x: hidden;

    .profile_checkboxes {
      width:50%;
      float:left;
      color:white;
      text-align: left;
      margin-bottom: 10px;
      input[type=checkbox] {
        -webkit-appearance: checkbox;
        height: 12px;
        padding:0;
        margin: 4px 0 0 0;
        float:left;
        width:auto !important;
      }
    }



    @include outer-container(500px);
    //margin-top: 50px;
    margin-top: 120px;
    width: 100%;
    text-align: center;

    @include media($mobile) {
      margin-top: 67px;
      padding: 0 45px;
    }

    .login_options {
      display: inline-block;
      width: 100%;
      position: relative;
      padding-bottom: 46px;
      border-bottom: 1px solid $gray;

      @include media($mobile) {
        padding: 0;
        font-size: 9px;
        padding-bottom: 20px;
        border-bottom: solid 1px rgba(181, 181, 181, 1);
      }

      a {
        position: relative;
        font-weight: lighter;
        font-size: em(43);
        text-decoration: none;
        color: white;
        @include transition(all 333ms linear);

        &:first-of-type {
          padding-right: 38.5px;

          &:after {
            content: '';
            @include position(absolute, 8px 0 null null);
            @include size(1px 35px);
            margin: auto;
            background: $white;

            @include media($mobile) {
              top: -5px;
            }
          }
        }

        &:last-of-type {
          margin-left: 24px;

          @include media($mobile) {
            margin-left: 28px;
          }
        }

        &.active {
          font-weight: normal;
        }
      }
    }

    .forms {
      padding: 55px 0;

      .input-wrap {
        display: block;
        width: 100%;
        position: relative;

        &.active {

          .form-text {
            font-size: em(12);
            @include position(absolute, 12px null null 30px);
          }
        }
      }

      .form-text {
        font-weight: lighter;
        color: $gray_light;
        font-size: em(16);
        line-height: 0.935;
        letter-spacing: -0.14px;
        @include position(absolute, 21px null null 30px);
        @include transition(all 333ms linear);
        z-index: -1;
      }



      input,select {
        padding: 24px 30px 12px;
        background: rgba(211, 195, 244, 0.34);
        border: none;
        border-radius: 100px;
        width: 100%;
        margin: 0 auto 30px;
        color: $white;
        @include transition(all 333ms linear);

        &.error-input {
          background: url('../../images/build/error-input.png') no-repeat calc(100% - 20px) center rgba(211, 195, 244, 0.34);
          background-size: 14px auto;
        }

        @include media($mobile) {
          margin: 0 auto 20px;
          max-height: 50px;
        }

        &::placeholder {
          font-weight: lighter;
          color: $white;
        }
      }
      .select {
        position: relative;
        &:after {
          content:'';
          position: absolute;
          right:15px;
          top:20px;
          width:12px;
          height:12px;
          background:url('../../images/build/arrow-down.svg') no-repeat center center;
        }
      }
      select {
        padding: 13px 30px 12px;
      }

      &.signup {
        transform: translateX(20vw);
        display: none;
        @include transition(all 444ms linear);
        position: absolute;
        width: 100%;

        @include media($mobile) {
          padding: 35px 0 0;
          overflow-y: auto;
          width: calc(100% - 80px);
        }

        .input-wrap {
          width: calc(50% - 13px);
          display: inline-block;

          @include media($mobile) {
            width:100%;
            display:block;
          }

          &:nth-of-type(odd) {
            margin-right: 13px;

            @include media($mobile) {
              margin-right: 0;
            }
          }

          &:nth-of-type(even) {
            margin-left: 13px;

            @include media($mobile) {
              margin-left: 0;
            }
          }
        }

        &.active {
          transform: translateX(0);
          display: block;
          height: calc(70vh - 35px);
          padding-bottom:100px;

        }
      }

      &.signin {
        padding: 79px 40px 0;
        transform: translateX(-20vw);
        opacity: 0;
        @include transition(all 444ms linear);
        position: absolute;
        width: 100%;

        @include media($mobile) {
          padding: 35px 0 0;
          overflow-y: auto;
          width: calc(100% - 80px);
        }

        &.active {
          transform: translateX(0);
          opacity: 1;
        }

        .recover-trigger {
          display: block;
          margin-top: 20px;
          color: $gray_light_2;
          text-transform: uppercase;
          text-decoration: none;
          font-size: em(12);
        }
      }

      &.recoverpassword {
        padding: 79px 40px 0;
        transform: translateX(-100vw);
        opacity: 0;
        transition: all 153ms linear;
        position: absolute;
        width: 100%;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .signup-button {
        display: inline-block;
      }

      .sign-up-msg {
        color: $white;
        font-size: em(16);
        padding: 0 0 30px;
      }
    }
  }
}

.close-button {
  @include position(absolute, 45px 50px null null);
  @include size(35px);
  cursor: pointer;
  background: url('../../images/build/btn-close.png') no-repeat center;
  background-size: 100% auto;
  z-index: 1;

  @include media($mobile) {
    top: 28px;
    right: 23px;
    @include size(24px);
  }
}
