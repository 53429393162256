.node-article {

  .article-content {
    overflow: auto;
    width: 100%;
    @include outer-container(1600px);

    @include media($desktop) {
      @include outer-container(1200px);
    }
  }

  .left-column {
    @include pad(54px 50px);
    border-left: solid 1px $gray_light;
    @include shift(1);
    @include span-columns(2.5, block-collapse);

    h3 {
      font-size: em(15);
      font-weight: 600;
      color: $moderate_magenta;
      margin-bottom: 8px;
    }

    h4 {
      font-size: em(13);
      color: $dark_very_grayish_violet;
    }

    @include media($desktop) {
      @include shift(0);
      @include span-columns(3.2, block-collapse);
    }

    @include media($tablet) {
      @include pad(50px 30px);
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      text-align: center;
      @include pad(28px 20px 25px);
    }
  }

  .right-column {
    @include span-columns(7);
    padding: 63px 133px 90px 120px;
    border-left: solid 1px $gray_light;
    border-right: solid 1px $gray_light;

    .content {
      margin-bottom: 95px;

      h1 {
        font-size: em(21);
        font-weight: lighter;
        line-height: 1.29;
        letter-spacing: -0.19px;
        margin-bottom: 31px;
      }

      p {
        font-size: em(15);
        line-height: 1.67;
        margin-bottom: 26px;
      }

      img {
        display: block;
        width: 95%;
        margin: 50px 0;
        height: auto;
      }
    }

    .author {
      margin-bottom: 70px;

      .label {
        font-size: em(15);
        color: $dark;
        line-height: 1.67;
        letter-spacing: -0.13px;
        margin-bottom: 3px;
      }

      .value {
        font-size: em(21);
        color: $dark;
        font-weight: lighter;
        line-height: 1.12;
        letter-spacing: -0.19px;
      }
    }

    @include media($desktop) {
      @include span-columns(8.8);
    }

    @include media($tablet) {
      @include pad(50px);
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      @include pad(0 14px 30px);

      .content {
        margin-bottom: 0;
        border-top: 1px solid $gray_light;
        @include pad(34px 18px);

        h1 {
          font-size: em(19);
          line-height: 1.15;
          letter-spacing: -0.17px;
          margin-bottom: 17px;
        }

        p {
          font-size: em(12);
        }

        img {
          width: 100%;
          margin: 26px 0;
        }
      }

      .author {
        margin-bottom: 40px;

        .label {
          font-size: em(13);
        }

        .value {
          font-size: em(18);
        }
      }
    }
  }

  .article-block {
    width: 100%;
    display: inline-block;

    .block:before {
      padding-top: 25%;

      @include media($tablet) {
        padding-top: 35%;
      }

      @include media($mobile) {
        padding-top: 50%;
      }
    }
  }
}