.node-strengths {
  position: relative;

  .main-content {
    position: relative;
    @include outer-container();
    @include clearfix();
  }

  .aside {
    width: 25%;
    position: absolute;
    top: 0;
    @include pad(70px 65px);
    background: $white;
    z-index: 10;

    .mobile-nav-current {
      display: none;
    }

    .nav-current {
      @include size(4px 9px);
      @include transition(all 333ms linear);
      background: url('../../images/build/nav-bubble.png') no-repeat;
      background-size: 4px auto;
      @include position(absolute, 0 null null 50px);
    }

    .nav-item {
      display: block;
    }

    a {
      position: relative;
      font-weight: lighter;
      font-size: em(16);
      margin-bottom: 26px;
      display: block;
      color: $dark_very_violet;
      text-decoration: none;

      &:after {
        content: '';
        @include position(absolute, 0 null 0 -11px);
        margin: auto 0;
        @include size(5px 100%);
        @include transition(opacity 333ms ease-in);
        opacity: 0;
      }

      &:hover {
        color: $moderate_magenta;
      }
    }

    @include media($mobile) {
      width: 100% !important;
      @include pad(12px 30px);
      border-top: 1px solid $gray_light;
      border-bottom: 1px solid $gray_light;

      .mobile-nav-current {
        display: block;
        position: relative;
        font-size: 12px;
        font-weight: lighter;
        padding-left: 9px;
        background: url('../../images/build/nav-bubble.png') left 2px no-repeat;
        background-size: 4px auto;
        @include transition(all 333ms linear);
        cursor: pointer;

        &:after {
          content: '';
          @include size(13px);
          border-radius: 50%;
          @include position(absolute, 0 0 0 null);
          margin: auto;
          background: url('../../images/build/arrow.svg') no-repeat center $moderate_magenta;
          background-size: 4px auto;
          @include transform(rotate(90deg));
        }
      }

      .nav-current,
      .nav-item {
        display: none;
      }

      a {
        font-size: 12px;
        margin: 15px 0;
      }
    }
  }

  .wrapper {
    width: 75%;
    float: right;
    border-left: 1px solid $gray_light;

    .strengths-section {
      outline: none;
      padding: 70px 85px;
      border-bottom: 1px solid $gray_light;

      &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }

    .title {
      font-size: em(36);
      font-weight: lighter;
      letter-spacing: -0.32px;
      line-height: 1.44;
      margin-bottom: 25px;
    }

    .sub-title {
      font-size: em(21);
      font-weight: lighter;
      letter-spacing: -0.19px;
      line-height: 1.29;
      margin-bottom: 8px;
    }

    .text {
      font-size: em(15);
      line-height: 1.7;

      p {
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        margin: 20px 0;
        padding: 0;
      }

      li {
        position: relative;
        font-size: em(14);
        margin-bottom: 20px;
        padding-left: 30px;

        &:before {
          content: '';
          @include size(5px);
          border-radius: 50%;
          background-color: $moderate_magenta;
          @include position(absolute, 7px null null 0);
        }

        &:last-of-type {
          margin: 0;
        }
      }

      .strong {
        font-weight: normal;
        color: $dark;
        font-size: em(15);
        line-height: 1.67;
        letter-spacing: -0.13px;
        margin-bottom: 15px;
      }
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      border: 0;
      padding-top: 40px;

      .strengths-section {
        @include pad(34px 30px 45px);
      }

      .title {
        font-size: em(27);
        margin-bottom: 14px;
      }

      .sub-title {
        font-size: em(19);
        margin-bottom: 12px;
      }

      .text {
        font-size: 12px;
        line-height: 1.58;

        p {
          margin-bottom: 15px;
        }

        ul {
          margin: 20px 0;
        }

        li {
          font-size: 12px;
          margin-bottom: 15px;

          &:before {
            top: 4px;
          }
        }

        .strong {
          font-size: em(13);
        }
      }
    }
  }

  .strengths-block {

    &:before {
      padding-top: 25%;
    }

    &:after {
      background: rgba(153,69,132,0.95);
    }

    .block-title,
    .block-text,
    .link {
      color: $white;
    }

    .link::after {
      background-color: $white;
      background-image: url('../../images/build/arrow-black.svg');
    }

    @include media($tablet) {

      &:before {
        padding-top: 35%;
      }
    }

    @include media($mobile) {

      &:before {
        padding-top: 45%;
      }
    }
  }
}
