.rhm_pager {
  @include pad(40px 0);
  text-align: center;

  ul {
    padding: 0;
  }

  .pager li {
    padding: 0;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    @include transition(all 333ms linear);

    &.pager-item,
    &.pager-current {
      position: relative;
      margin: 0 5px;
      border-radius: 50%;
      @include size(35px);

      .page-text {
        margin: auto;
        font-size: em(21);
        font-weight: lighter;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));

        a {
          color: $dark_very_violet;
          text-decoration: none;
          @include transition(all 333ms linear);
        }
      }

      &.pager-current {
        color: $white;
        background: $cyan_moderate_3;

        a {
          color: $white;
        }
      }

      &:hover {
        border: 1px solid $color_main;
      }
    }

    &.pager-next,
    &.pager-previous {
      @include size(6px 14px);
      background: url('../../images/build/pager-next.png') no-repeat center;
      background-size: 100% auto;
      margin: 0 13.5px;

      &.pager-previous {
        @include transform(rotate(180deg));
      }
    }

    &.pager-last,
    &.pager-first {
      @include size(14px);
      background: url('../../images/build/pager-last.png') no-repeat center;
      background-size: 100% auto;
      margin: 0 13.5px;

      &.pager-first {
        @include transform(rotate(180deg));
      }
    }

    &.disable {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(.pager-item) a {
      opacity: 0;
    }
  }

  @include media($mobile) {
    @include pad(24px 0 0);

    .pager li {

      &.pager-item,
      &.pager-current {
        @include size(28px);

        .page-text {
          font-size: em(20);
        }
      }
    }
  }
}