.node-budget-and-spendings-page {

  .header {
    position: relative;
    z-index: 1;
    text-align: center;
    background-size: cover;
    @include pad(153px 20px 115px);
    background-position: center;

    &:before {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.75);
      z-index: -1;
    }

    .wrapper {
      max-width: 420px;
      margin: 0 auto;
    }

    .title {
      font-size: em(44);
      font-weight: lighter;
      margin-bottom: 16px;
      line-height: 1.23;
      letter-spacing: -0.39px;
    }

    .text {
      font-size: em(14);
      color: $dark_very_grayish_violet_2;
      line-height: 1.64;
    }

    @include media($tablet) {
      text-align: left;
      @include pad(161px 32px 38px);

      .wrapper {
        margin: 0;
      }

      .title {
        font-size: em(30);
        margin-bottom: 15px;
        line-height: 1.06;
        letter-spacing: -0.27px;
      }

      .text {
        font-size: em(12);
        line-height: 1.58;
      }
    }
  }

  .year-nav {
    @include pad(32px 10px 26px);
    height: 90px;
    font-size: em(13);
    color: $dark_grayish_violet_2;
    background: $white_2;
    @include clearfix();

    .year-wrapper {
      @include size(800px 100%);
      margin: 0 auto;
    }

    .year-item {
      float: left;
      cursor: pointer;
      margin-top: 13px;
      @include transition(all 333ms linear);

      &.current {
        color: $dark_very_violet;
        font-size: em(30);
        line-height: 1.73;
        letter-spacing: -0.27px;
        margin-top: 0;
      }
    }

    .prev-year {
      float: right;
      text-align: right;

      .year-item {
        margin-right: 47px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    @include media($tablet) {
      display: none;
    }
  }

  .year-nav-mobile {
    display: none;
    @include pad(10px 30px);
    background: $white_2;

    .mobile-drop-down {
      display: none;
    }

    .current-year,
    .year-item {
      cursor: pointer;
      font-size: em(16);
    }

    .current-year {
      position: relative;

      &:after {
        content: '';
        @include size(13px);
        border-radius: 50%;
        @include position(absolute, 0 0 0 null);
        margin: auto;
        background: url('../../images/build/arrow.svg') no-repeat center $moderate_magenta;
        background-size: 4px auto;
        @include transform(rotate(90deg));
      }
    }

    .year-item {
      padding: 5px 0;

      &:first-of-type {
        padding-top: 10px;
      }

      &:hover {
        color: $moderate_magenta;
      }
    }

    @include media($tablet) {
      display: block;
    }
  }

  .budget-tab {
    display: none;

    &.current {
      display: block;
    }
  }

  .budget {
    position: relative;
    @include size(800px auto);
    @include pad(64px 10px 94px);
    margin: 0 auto;

    @include media($tablet) {
      width: 100%;
      @include pad(0);
    }

    .entry-header {
      margin-bottom: 35px;
      @include clearfix();

      .heading,
      .total-spend-text {
        font-size: em(30);
        font-weight: normal;
        line-height: 1.73;
        letter-spacing: -0.27px;

        &.heading {
          float: left;
        }
      }

      .total-spend {
        float: right;
        text-align: right;

        span {
          display: block;
          font-weight: bolder;
          font-size: em(12);
          color: $moderate_magenta;
          line-height: 1.75;
          margin-top: -8px;
        }
      }

      @include media($tablet) {
        margin: 0;
        @include pad(23px 30px);

        .heading,
        .total-spend-text {
          font-size: em(28);
        }
      }

      @include media($mobile) {

        .heading,
        .total-spend {
          text-align: left;
          margin-bottom: 0;
          float: none !important;

          span {
            margin-top: 0;
          }
        }

        .heading,
        .total-spend-text {
          font-size: em(20);
        }
      }
    }

    .entry-content {

      .nav {
        width: 100%;
        margin-bottom: 50px;
        @include clearfix();

        .nav-item {
          background: $moderate_dark_pink_2;
          height: 20px;
          border: 2px solid $white;
          float: left;
          box-sizing: border-box;
          cursor: pointer;
          @include transition(transform 333ms linear);

          &:first-of-type {
            border-radius: 100px 0 0 100px;
          }

          &:last-of-type {
            border-radius: 0 100px 100px 0;
            border-right: 0;
          }

          &.current,
          &.mouseover {
            @include transform(scale(1.25, 1.7));
            background: $moderate_magenta;
            border-radius: 37px;
          }
        }
      }

      .item {

        .spend-item {
          @include pad(47px 26px 45px 11px);
          border-bottom: 1px solid $gray_light;
          cursor: pointer;
          @include clearfix();
          @include transition(all 333ms ease-out);

          &:first-of-type {
            border-top: 1px solid $gray_light;
          }

          .left {
            position: relative;
            float: left;
            width: 100%;
            @include transition(all 333ms ease-out);

            &:after {
              opacity: 0;
              content: '';
              @include size(2px 80%);
              @include position(absolute, 0 null 0 0);
              @include transition(all 333ms ease-out);
              margin: auto;
              background: $moderate_magenta;
            }
          }

          .name, .price {
            font-size: em(18);
            font-weight: normal;
            color: $dark_very_grayish_violet;
            @include transition(all 333ms ease-out);
          }

          .price {
            @include position(absolute, 0 0 null null);
          }

          .desc {
            opacity: 0;
            visibility: hidden;
            height: 0;
            font-size: em(14);
            font-weight: normal;
            color: $dark_very_grayish_violet;
            line-height: 1.64;
            @include transition(all 333ms ease-out);
          }

          &.current,
          &.mouseover {
            @include pad(30px 26px 29px 12px);

            .left {
              padding-left: 24px;

              &:after {
                opacity: 1;
              }

              .name, .price {
                font-size: em(23);
                margin-bottom: 7px;
              }

              .desc {
                height: auto;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }

      @include media($tablet) {

        .nav {
          display: none;
        }

        .item {

          .spend-item {
            @include pad(25px 30px 25px);

            .name, .price {
              font-size: em(17);
            }
          }
        }
      }

      @include media($mobile) {

        .item {

          .spend-item {

            .left{
              padding-left: 18px;

              &:after {
                opacity: 1;
                height: 100%;
              }
            }

            .name, .price {
              font-size: em(15);
            }

            .price {
              opacity: 0;
              visibility: hidden;
              @include position(relative, inherit);
            }

            &.current,
            &.mouseover {
              @include pad(25px 30px 25px);

              .left {
                padding-left: 18px;

                .name, .price {
                  font-size: em(18);
                  margin-bottom: 0;
                }

                .price {
                  opacity: 1;
                  visibility: visible;
                }

                .desc {
                  font-size: em(14);
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }

  .budget-block {

    &:before {
      padding-top: 25%;
    }

    @include media($tablet) {

      &:before {
        padding-top: 35%;
      }
    }

    @include media($mobile) {

      &:before {
        padding-top: 45%;
      }
    }
  }
}