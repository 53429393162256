.social-area {

  .label {
    display: inline-block;
    vertical-align: middle;
    font-size: em(15);
    color: $dark;
    line-height: 1.67;
    letter-spacing: -0.13px;
    text-transform: uppercase;
  }

  .share {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px;
    @include size(50px 40px);
    @include transition(all 333ms linear);
    overflow: hidden;

    .view-area {
      width: 320px;
      @include position(absolute, 0 null null 0);
    }

    .default, .item {
      position: relative;
      float: none;
      outline: none;
      @include size(40px);
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid $cyan_moderate;
      box-sizing: border-box;
      @include transition(all 333ms linear);

      svg, g {
        fill: $cyan_moderate;
      }
    }

    .default {
      background: url('../../images/build/share.png') no-repeat 12px center $cyan_moderate;
      background-size: 13px auto;
    }

    .item {

      &:before {
        content: '';
        @include size(1px);
        @include position(absolute, 0 0 0 0);
        @include margin(auto);
        background-color: $cyan_moderate;
        opacity: 0;
        border-radius: 50%;
        @include transition(all 333ms linear);
      }

      span {
        display: none;
      }

      svg {
        @include position(absolute, 0 0 0 0);
        margin: auto;
      }

      &:last-of-type {
        margin: 0;
      }

      &:hover {

        &:before {
          opacity: 1;
          @include size(100%);
        }

        g {
          fill: $white;
        }
      }
    }

    &:hover {
      width: 320px;
    }
  }

  @include media($mobile) {

    .label {
      display: none;
    }

    .share {
      margin: 0;
      @include size(35px);

      .default,
      .item {
        margin-right: 5px;
        @include size(35px);
      }

      .default {
        background-position: center;
      }
    }
  }
}