.link, .more {
  position: relative;
  display: inline-block;
  font-size: em(15);
  text-transform: uppercase;
  color: $color_link;
  padding-right: 18px;
  text-decoration: none;
  outline: none;
  @include transition(all 333ms linear);

  &:after {
    content: '';
    @include size(13px);
    border-radius: 50%;
    @include position(absolute, 0 0 0 null);
    margin: auto;
    background: url('../../images/build/arrow.svg') no-repeat center $moderate_magenta;
    background-size: 4px auto;
  }

  &:hover {
    color: $moderate_magenta;
  }
  @include media($mobile) {
    font-size: em(12);
  }
}


.link-oval,
.default-button {
  position: relative;
  display: inline-block;
  font-size: em(14);
  text-transform: uppercase;
  background: $moderate_magenta;
  color: $white;
  border-radius: 100px;
  @include pad(8px 18px);
  min-width: 78px;
  text-align: center;
  text-decoration: none;
  outline: none;
  @include transition(all 333ms linear);

  &:focus,
  &:hover {
    @include pad(9px 22px);
    box-shadow: 0 0 11px 2px rgba(167, 47, 145, 0.50);
  }
}

.social-media {
  position: relative;
  display: inline-block;
  @include size(40px);
  margin: 0 5px;
  border: 1px solid $cyan_moderate;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  &:after {
    content: '';
  }
  &:before {
    content: '';
    @include size(1px);
    @include position(absolute, 0 0 0 0);
    @include margin(auto);
    background-color: $cyan_moderate;
    opacity: 0;
    border-radius: 50%;
    @include transition(all 333ms linear);
  }
  &:hover {

    &:before {
      opacity: 1;
      @include size(100%);
    }

    g {
      fill: $white;
    }
  }
}

.post-more {
  font-weight: lighter;
  border-radius: 100px;
  background: $deeppurple;
  padding: 7px 18px;
  color: $white !important;
  text-decoration: none;
  text-transform: uppercase;
}

.more-button {
  font-weight: lighter;
  text-decoration: none;
  color: #454147;
  text-transform: uppercase;
  font-size: rem(12px);
  padding-top: 8px;
  position: relative;

  &:after {
    content: '';
    background: url('../../images/build/more-arrow.svg');
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    right: -15px;

    z-index: 0;
  }
  &.dark {
    color: #fff;
  }
}

.cart-action {
  background: transparent;
  border: none;
  text-transform: uppercase;
  color: rgba(169, 166, 171, 1);
  font-size: 13px;
  padding: 0;

  &:before {
    content: 'X';
    padding-right: 5px;
  }
}

.add-button {
  display: block;
  background: rgba(179, 62, 150, 1);
  width: 15px;
  height: 15px;
  border-radius: 100px;
  text-decoration: none;
  color: #FFF;
  font-weight: lighter;
  padding: 2px 4px;
  line-height: 0.9;
  font-size: rem(12px);

  &:before {
    content: '+';
  }

  &.active {
    padding: 2px 6px;

    &:before {
      content: '-';
    }
  }
}
