.node-type-programme-landing {

  .header {
    position: relative;
    @include pad(162px 0 420px);
    z-index: 0;
    background-size: cover;
    background-position: center;
    text-align: center;
    margin-bottom: -250px;

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.93);
      z-index: -1;
    }

    .title {
      font-size: em(43);
      font-weight: lighter;
      line-height: 1.21;
      letter-spacing: -0.38px;
      margin-bottom: 20px;
    }

    .text {
      max-width: 550px;
      margin: 0 auto;
      line-height: 1.64;
    }

    a {
      @extend .link;
    }

    @include media($mobile) {
      text-align: left;
      padding: 45px 32px 320px;

      .title {
        font-size: rem(30);
      }

      .text {
        font-size: rem(12);

        a {
          font-size: 15px;
        }
      }
    }

  }

  .box {

    &.w2-h2 {

      h1 {
        font-size: em(44);
        font-weight: lighter;
        line-height: 1.22;
        letter-spacing: -0.39px;
        margin-bottom: 16px;
        max-width: 360px;
      }

      h3, h4 {
        display: none;
      }

      p {
        max-width: 330px;
        font-size: em(14);
        margin-bottom: 34px;
      }

      &:after {
        background: linear-gradient(45deg, rgba(255, 255, 255, 0.96) 25%, rgba(255, 255, 255, 0) 100%);
      }

      @include media($desktop_small) {

        h1 {
          font-size: em(35);
        }
      }

      @include media($mobile) {
        @include pad(186px 23px 26px);

        .content {
          @include position(relative, inherit inherit inherit inherit);
        }

        h1 {
          font-size: em(27);
          font-weight: normal;
          line-height: 1.33;
          margin-bottom: 10px;
          letter-spacing: -0.24px;
        }

        p {
          display: inline-block;
          font-size: em(12);
        }

        .link-oval {
          display: block;
        }
      }
    }

    &.w1-h1 {

      &.text {

        .content {
          @include position(absolute, inherit null 0 0);
          @include transform(translate(0));
          padding: 0 35px 8%;
          margin-bottom: 0;
        }

        h1 {
          font-size: em(27);
          font-weight: lighter;
          line-height: 1.14;
          letter-spacing: -0.24px;
          margin-bottom: 17px;
        }

        h3, h4 {
          display: none;
        }

        p {
          font-size: em(14);
          color: $dark_grayish_violet;

          @media(max-width: 1390px) {
            display: none !important;
          }

        }

        @include media($desktop_small) {

          .content {
            padding: 0 30px 8%;
          }

          h1 {
            font-size: em(22);
          }
        }

        @include media($tablet) {

          .content {
            padding: 0 20px 20px;
            top: 25%;
          }

          h1 {
            font-size: em(19);
            margin-bottom: -15px;
          }

          p {
            margin-top: 15px;
            display: block;
            font-size: em(12);
            line-height: 1.2;
          }
        }

        @include media($mobile) {

          &:first-
          .content {
            padding: 0 22px 18px;
          }

          h1 {
            font-size: em(19);
            line-height: 1.15;
          }
        }

        @include media($lessmobile) {

          p {
            display: none;
          }
        }
      }
    }
  }

  .core {
    @include outer-container();
    @include clearfix();
    border: solid 1px #F2F2F2;
    position: relative;
    z-index: 2;
  }

  .programme-block:not(.block-large) {
    width: 50%;
    float: left;

    @include media($mobile) {
      width: 100%;
    }
  }

  .cic-1 {
    @include position(absolute, 24px null null -105px);
    @include size(185px 220px);
    background: url(' . . / . . /images/ build/cic-1 . png ') no-repeat center;
    background-size: 100% auto;
  }

  .cic-2 {
    @include position(absolute, null -52.5px 54px null);
    @include size(105px);
    background: url(' . . / . . /images/ build/cic-2 . png ') no-repeat center;
    background-size: 100% auto;
  }

  .block-large {
    overflow: hidden;
    position: relative;
    height: 360px;

    &:after {
      content: '';
      display: block;
      background: $purple;
      opacity: 0.9;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @include media($tablet) {
        padding-top: 35%;
      }

      @include media($mobile) {
        padding-top: 50%;
      }
    }

    &:hover {

      .block-bg {
        @include transform(scale(1.15));
      }
    }

    .block-bg {
      @include position(absolute, 0 null null 0);
      @include transition(all 333ms linear);
      @include size(100%);
      background-size: cover;
      background-position: center;
    }

    .block-wrapper {
      z-index: 1;
      max-width: 450px;
      margin: 0 auto;
      @include position(absolute, 50% 0 null 0);
      @include transform(translateY(-50%));
      text-align: center;
    }

    .block-title {
      font-size: em(36);
      font-weight: lighter;
      margin-bottom: 17px;
      line-height: 1.44;
      letter-spacing: -0.32px;
      color: $white;
    }

    .block-text {
      font-size: em(14);
      margin-bottom: 25px;
      line-height: 1.64;
      color: $white;
    }

    .link {
      color: $white;
      &:after {
        background-image: url("../../images/build/arrow-black.svg");
        background-color: $white;
      }
    }

    @include media($mobile) {

      .block-wrapper {
        @include pad(0 35px);
      }

      .block-title {
        font-size: em(27);
        margin-bottom: 14px;
        line-height: 1.11;
        letter-spacing: -0.24px;
      }

      .block-text {
        font-size: em(11);
        line-height: 1.45;
      }

      .link {
        font-size: em(12);
      }
    }
  }
}

.node-programme {

  .center-column {
    max-width: 650px;
    margin: 30px auto 95px;
  }

  .content {
    font-size: em(15);
    color: $dark_very_grayish_violet;
    margin-bottom: 65px;

    p {
      margin-bottom: 35px;
    }

    img {
      width: 95%;
      display: block;
    }
  }

  .sponsors-content {
    margin-bottom: 120px;

    .title {
      font-size: em(27);
      font-weight: lighter;
      letter-spacing: -0.24px;
      line-height: 1.03;
      margin-bottom: 50px;
    }
  }

  .bean-get-involved {

    &:before {
      padding-top: 25%;
    }

    &:after {
      background: rgba(255, 255, 255, 0.9);
    }

    .block-title {
      font-size: em(36);
      color: $dark_very_violet;
      margin-bottom: 15px;
      line-height: 1.44;
    }

    .block-text {
      font-size: em(15);
      color: $dark_very_grayish_violet_2;
      line-height: 1.53;
    }

    .link {
      color: $dark_very_grayish_violet;

      &:after {
        background: url(' . . / . . /images/ build/arrow . svg ') no-repeat center $moderate_magenta;
      }
    }
  }

  @include media($tablet) {

    .center-column {
      padding: 0 30px;
    }

    .content {
      margin-bottom: 30px;

      p {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }

    .sponsors-content {
      margin-bottom: 60px;

      .title {
        font-size: em(23);
        margin-bottom: 20px;
      }
    }

    .bean-get-involved {

      &:before {
        padding-top: 35%;
      }
    }
  }

  @include media($mobile) {

    .bean-get-involved {

      &:before {
        padding-top: 50%;
      }
    }
  }
}

.programme-sponsors {

  .sponsorship-type {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .type {
      font-size: em(15);
      line-height: 1.67;
      color: $dark;
      letter-spacing: -0.13px;
      margin-bottom: 30px;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      max-width: 120px;
      margin: 20px 50px 20px 0;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  @include media($mobile) {

    .sponsorship-type {
      margin-bottom: 10px;

      .type {
        margin-bottom: 26px;
      }

      a {
        display: block;
        margin: 0 0 26px;
      }
    }
  }
}

.programme-categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  @include clearfix();
  text-align: right;
  background: $white;
  padding-right: 50px;
  border-bottom: 1px solid #F2F2F2;

  li {

    display: inline-block;
    text-align: center;
    margin-left: 40px;

    a {
      display: block;
      width: 100%;
      color: $dark_very_grayish_violet;
      font-size: rem(13);
      text-decoration: none;
      padding: 16px 0 12px;
      position: relative;

      img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin-right: 10px;
      }

      &.actually-active {
        font-weight: 700;
      }

    }

    &:not(:first-child) {
      a {
        padding-top: 13px;
      }
    }

    img {
      display: inline-block;
      height: 32px;
      width: auto;
      vertical-align: middle;
    }

  }

  @include media($mobile) {

    text-align: left;
    white-space: nowrap;
    overflow: auto;
    padding: 0 20px;

    li {
      margin-left: 0;
      margin-right: 15px;

      a {

        padding: 18px 0 16px;

        img {
          width: 22px;
          height: 22px;
          border-radius: 22px;
        }
      }
    }
  }

}

.past-programme-filters {

  @include clearfix();
  background: $white;
  padding: 15px 40px;

  ul {
    float: right;
    list-style-type: none;
    padding: 0;
    margin: 0;

    a {
      font-size: rem(13);
      color: $dark_very_grayish_violet;
      text-transform: uppercase;
      text-decoration: none;
      padding: 5px 20px;
      display: block;
    }
    .past-programme-filter-toggle {
      transition: opacity 0.2s ease-in-out;
    }
    .past-programme-filter-toggle.not-active {
      opacity: 0.7;
    }
    .past-programme-filter-toggle > a {
      position: relative;

      &:before {
        content: '';
        width: 13px;
        height: 13px;
        background: url(../../images/build/arrow.svg) center center no-repeat $moderate_magenta;
        display: block;
        border-radius: 13px;
        position: absolute;
        right: 0;
        bottom: 6px;
        background-size: 4px auto;
        transform: rotate(90deg);
        transition: all 0.2s ease-in-out;
      }

    }

    li {

      float: left;
      margin-left: 24px;
      position: relative;

      &.active {
        & > a:before {
          transform: rotate(270deg);
        }
      }

      ul {
        display: none;
        position: absolute;
        top: 100%;
        right: -40px;
        width: 190px;
        background: $white;
        float: none;
        margin: 0;
        text-align: center;
        padding: 20px 20px 5px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        li {
          float: none;
          margin: 0;
          border-bottom: 1px solid #F4ECF2;

          &:last-child {
            border-bottom: 0;
          }

          a {
            padding: 10px 0;
            text-transform: none;

            &:hover {
              color: $moderate_magenta;
              transition: all 0.2s ease-in-out;
            }
          }

        }
      }
    }
  }

}

.tid-bg-26 {
  background: $cyan_moderate !important;
}

.tid-bg-27 {
  background: $purple !important;
}

.tid-bg-28 {
  background: $moderate_dark_pink_2 !important;
}
