.site-main-header {
  z-index: 100;
}

.modalopen {
  overflow:hidden !important;
}

#header {
  display: block;
  z-index: 100;
  border-bottom: 1px solid $gary_very_light_2;

  &.is_stuck {

    .header-top {
      padding-bottom: 7px;

      &:after {
        @include size(77px);
        left: 46px;

        @include media($desktop) {
          left: 15px;
        }
      }
    }

    .header-bottom {
      padding-top: 9px;
      padding-bottom: 9px;

      #logo {

        img {
          width: 225px;
        }
      }
    }
  }

  .header-top {
    position: relative;
    @include clearfix();
    @include size(100% auto);
    @include pad(14px calc(2% + 16px) 9px);
    @include transition(all 333ms linear);
    min-height: 43px;
    background: $white;
    overflow: hidden;

    &:before {
      content: '';
      @include size(9999px 1px);
      background: $gray_light;
      @include position(absolute, null null 0 0);
    }

    &:after {
      @include size(95px);
      border-radius: 50%;
      border: 1px solid $gray_light;
      @include position(absolute, 28px null null 40px);
      background: $white;
      @include transition(all 333ms linear);
    }

    #logo {
      display: none;
    }

    .header-top_wrapper {
      float: right;
    }

    .cart, .currency , .language {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &.cart,
      &.currency {
        padding-right: 14px;
        margin-right: 14px;

        &:before {
          content: '';
          @include size(1px 15px);
          background: $gary_very_light_3;
          @include position(absolute, 0 0 0 null);
          margin: auto;
        }
      }
    }

    @include media($desktop) {
      @include pad(12px 21px 8px);

      &:after {
        left: 8px;
      }
    }

    @include media($desktop_small) {
      @include pad(10px 2.5% 7px);

      &:after {
        content: none;
      }

      #logo {
        display: block;
        position: relative;
        float: left;
        //width: 163px;
        width: 200px;

        img {
          display: block;
          width: 100%;
        }
      }

      .header-top_wrapper {
        margin-top: 20px;
      }
    }
  }

  .header-bottom {
    z-index: 1;
    background: $white;
    @include pad(10px 52px 18px);
    @include clearfix();
    @include transition(all 333ms linear);

    #logo {
      position: relative;
      float: left;
      width: 251px;
      //width: 280px;

      img {
        display: block;
        width: 100%;
        @include transition(all 333ms linear);
        position: relative;
        left: -3px;
      }
    }

    #navigation {
      float: right;
      text-align: right;
      //margin-top: 34px;
      //width: calc(100% - 251px);
      margin-top: 65px;
      width: calc(100% - 280px);

      ul {
        padding: 0;
        margin: 0;
      }

      li {
        display: inline-block;
        margin-left: 1.5%;
        @include pad(6px 0);

        a {
          font-size: em(13);
          padding-left: 9px;
          color: $dark_very_violet;
          letter-spacing: 0.28px;
          text-decoration: none;
          text-transform: uppercase;
          @include transition(all 333ms linear);
        }

        &.first {
          margin-left: 0;
        }

        &.last {
          display: none;
          margin-left: 10px;
          background: $white_2;
          @include pad(6px 7px);

          a {
            padding: 0;
          }
        }

        &.active-trail:not(.last) > a,
        &.active:not(.last) > a {
          color: $moderate_magenta;
          background: url('../../images/build/nav-bubble.png') no-repeat left center;
          background-size: 4px auto;
          padding-left: 9px !important;
        }

        &:hover {

          a {
            color: $moderate_magenta;
          }
        }
      }
    }

    @include media($desktop) {
      @include pad(8px 21px 18px);
    }

    @include media($desktop_small) {
      @include pad(18px 2%);

      #logo {
        display: none;
      }

      #navigation {
        float: none;
        text-align: left;
        width: 100%;
        margin: 0;

        li {
          margin-left: 2%;

          &.last {
            margin-left: 2%;
          }

          &:nth-last-child(2) {
            margin-left: 20px;
          }
        }
      }
    }
  }

  @include media($tablet) {
    display: none;
  }
}

#header-mobile {
  display: none;
  z-index: 100;
  background: $white;

  .header-mobile_wrapper {
    position: relative;
    //min-height: 85px;
    min-height: 120px;
    background: $white;
    z-index: 100;
    //@include pad(20px 44px 16px 30px);
    padding: 20px 32px;

    @include media($mobile) {
      @include pad(20px 24px 16px);
    }
  }

  #logo {
    float: left;
    display: block;
    //@include size(163px auto);
    width: 145px;
    height: auto;

    img {
      display: block;
      width: 100%;
    }
  }

  .toggle {
    position: relative;
    //margin-top: 14px;
    margin-top: 32px;
    float: right;
    cursor: pointer;
    @include size(30px 18px);

    &:before, &:after {
      content: '';
      @include size(100% 3px);
      background: $gray_light_2;
      border-radius: 18px;
      @include transition(all 333ms linear);
    }

    &:before {
      @include position(absolute, 0 null null 0);
    }

    &:after {
      @include position(absolute, null null 0 0);
    }

    div {
      @include size(100% 3px);
      background: $gray_light_2;
      border-radius: 18px;
      @include position(absolute, 50% null null 0);
      @include transform(translateY(-50%));
      @include transition(all 333ms linear);
    }

    &.toggled {

      &:before {
        @include transform(rotate(45deg) translate(5px, 5px));
      }

      &:after {
        @include transform(rotate(-45deg) translate(5px, -6px));
      }

      div {
        background-color: transparent;
      }
    }
  }

  .mobile-menu {
    background: $white;
    //@include position(absolute, 85px -300px null null);
    @include position(absolute, 120px -300px null null);
    @include size(275px auto);
    border-top: 1px solid $gray_light_3;
    //height: calc(100vh - 85px);
    height: calc(100vh - 120px);
    @include transition(all 333ms linear);
    z-index: 100;

    &.active {
      right: 0;
    }
  }

  .menu-top {
    @include pad(25px 6px 8px);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 30px;
        padding-left: 35px;

        a {
          font-size: em(13);
          letter-spacing: 0.28px;
          text-transform: uppercase;
          text-decoration: none;
          color: $color_main;
        }

        &.last {
          margin-bottom: 0;
          float: left;
          width: calc((100% - 6px) / 2);
          padding: 14px 0;
          background: $white_2;
          text-align: center;

          &:nth-of-type(2n) {
            margin-right: 6px;
          }
        }

        &.active-trail:not(.last) > a,
        &.active:not(.last) > a {
          color: $moderate_magenta;
          background: url('../../images/build/nav-bubble.png') no-repeat left center;
          background-size: 4px auto;
          padding-left: 9px !important;
        }

        &:hover {

          a {
            color: $moderate_magenta;
          }
        }
      }
    }
  }

  .menu-bottom {
    background: $white;
    @include clearfix();
    position: fixed;
    bottom: 0;
    border-top: 1px solid $gray_light_3;
    width: 100%;

    .cart {
      float: left;
      font-size: em(13);
      font-weight: lighter;
      text-transform: uppercase;
      @include pad(20px 15px 21px 18px);
      border-right: 1px solid $gray_light_3;
    }

    .currency {
      float: left;
      font-size: em(13);
      font-weight: lighter;
      text-transform: uppercase;
      @include pad(20px 23px 21px);
      border-right: 1px solid $gray_light_3;
    }

    .language {
      float: left;
      @include pad(17px 24px 17px 21px);
    }
  }

  @include media($tablet) {
    display: block;
  }
}

.cart {

  a {
    font-size: em(14);
    font-weight: lighter;
    color: $dark_very_violet;
    text-decoration: none;
    text-transform: uppercase;
    background: url('../../images/build/cart.png') no-repeat left center;
    background-size: 13px auto;
    padding-left: 17px;
  }
}

.currency {
  position: relative;
  font-size: em(13);
  font-weight: lighter;
  line-height: 1;
  cursor: pointer;

  .currency-item {
    display: inline-block;
    vertical-align: middle;
    color: $color_main;
    opacity: 0.64;
    text-decoration: none;
    @include transition(all 333ms linear);

    &:first-of-type {
      margin-right: 12px;
    }

    &:hover,
    &.current-currency {
      opacity: 1;
    }
  }

  &.bottom-item {

    .current-currency {
      background: url('../../images/build/currency-arrow.png') no-repeat right center;
      background-size: 9px auto;
      padding-right: 15px;
    }

    .currency-switch {
      display: none;
      border: 1px solid $gray_light_3;
      @include position(absolute, -36px 0 null 0);
      text-align: center;

      a {
        display: block;
        margin: 10px 0;
        text-decoration: none;
        color: $color_main;

        &.hidden {
          display: none;
        }
      }
    }
  }
}

.lang-switcher {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    @include size(22px);
    margin-left: 8px;

    &:first-of-type {
      margin: 0;
    }

    @include media($tablet) {
      margin-left: 22px;
    }

    a {
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background: rgba(255, 255, 255, 0.5);
      }
    }

    img {
      width: 21px;
    }

    &.active {

      a:before {
        content: none;
      }

      img {
        width: 100%;
      }
    }
  }
}
ul.primary {
  border:none;
 // float:right;
  li a,li.active a {
    border:none;
    background:#F2F2F2;
    color:#000;
  }
}
ul.action-links {
  display: none;
}
