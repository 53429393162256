@import url('https://fonts.googleapis.com/css?family=Assistant:300,400,600,700&display=swap');

$teal_desktopsmall: new-breakpoint(max-width 999px 12);
$teal_overdesktopsmall: new-breakpoint(min-width 1000px 12);
$teal_tablet: new-breakpoint(max-width 768px 12);
$teal_overtablet: new-breakpoint(min-width 769px 12);
$teal_mobile: new-breakpoint(max-width 650px 12);
$teal_overmobile: new-breakpoint(min-width 651px 12);

$teal: #3DCCC7;
$light-grey: #FBFBFB;
$body-color: #605A63;
$border-color: #F2F2F2;
$header_height: 100px;
$header_height_tablet: 50px;


.node-type-project-teal, .node-type-project-teal-form {
  #content-area {
    margin-top: $header_height_tablet;

    @include media($teal_overdesktopsmall) {
      margin-top: $header_height;
    }
  }

  section {
    outline: none;
  }

  .anchor {
    position: relative;
    top: -170px;
    display: block;
    visibility: hidden;
  }

  .container--full {
    @include outer-container();
    padding: 0 27px;

    @include media($teal_overmobile) {
      position: relative;
    }
  }

}

.project-teal__nav {
  font-family: 'Assistant', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 10;
  height: $header_height_tablet;
  border-bottom: 1px solid $border-color;


  .container {
    @include outer-container();
    padding: 0 20px;
  }

  .logo {
    height: 48px;
    width: auto;
    margin-right: auto;
  }

  &.hide-logo-1{
    .logo{
      opacity: 0;
    }
  }

  @include media($teal_overdesktopsmall) {
    height: $header_height;
    border-bottom: 0;

    .logo {
      height: $header_height;
    }
  }

  .container {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .project-teal__cta {
    @include media($teal_desktopsmall) {
      margin-right: -20px;
      .project-teal__sign-up-button {
        border-radius: 0;
        padding: 14px 16px;
        height: 50px;
        font-size: rem(12);
        line-height: 1.7;
      }
    }
  }

}

.project-teal__nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 30px;

  li {
    margin-left: 25px;
  }

  a {
    font-size: rem(15);
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: #454147;
    letter-spacing: 0.05em;
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 2px dotted transparent;
    transition: all 0.2s ease-in-out;
  }

  li.active {
    a {
      border-bottom: 2px dotted #CECECE;
    }
  }

  @media(max-width: 1200px) {
    display: none;
  }

}

.project-teal__language {
  margin-right: 30px;
}

.node-project-teal {
  color: $body-color;
  font-family: 'Assistant', sans-serif;

  .container {
    @include outer-container();
    padding: 0 20px;
  }

  h1 {
    font-size: rem(46);
    color: $teal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media($teal_overdesktopsmall) {
      font-size: rem(66);
      margin-bottom: -10px;
    }

  }

  a {
    color: $teal;
  }

}

a.project-teal__sign-up-button, button.project-teal__sign-up-button {
  display: inline-block;
  background: url(../../images/build/teal/pattern.jpg) center center no-repeat;
  color: $white;
  font-size: rem(15);
  text-transform: uppercase;
  font-weight: 700;
  padding: 18px 40px;
  border-radius: 100px;
  box-shadow: 0 5px 6px rgba(88, 190, 199, 0.2);
  text-decoration: none;
  border: 0;
  outline: none;
  transition: all 0.2s ease-in-out;

  &--back {
    background: #F2F2F2;
    color: $body-color;
    box-shadow: none;

    &:hover {
      background: #E6E6E6;
    }
  }

  @include media($teal_desktopsmall) {
    font-size: rem(13);
    padding: 14px 32px;
  }
}

.project-teal__header {
  height: 281px;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-color: #FAFAFA;

  .container {
    height: 281px;
    max-width: 1200px;
    position: relative;

    @include media($teal_desktopsmall) {
      padding-left: 58px;
      padding-right: 58px;
    }
    @include media($teal_mobile) {
      padding-left: 27px;
      padding-right: 27px;
    }


  }

  @include media($teal_overdesktopsmall) {
    height: 527px;
    .container {
      height: 527px;
    }
  }
  @include media($teal_mobile) {
    height: 382px;

    .container {
      height: 382px;
    }
  }

  @media(min-width: 1800px) {
    background-size: 1500px auto;
    background-repeat: no-repeat;
    height: 700px;
    .container {
      height: 700px;
    }
  }


  .text {
    position: relative;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    @include media($teal_mobile) {
      transform: translateY(0);
      top: auto;
      padding-top: 220px;

      h1 {
        color: $body-color;
        font-size: rem(36);
      }
    }
  }


  &:not(.hide-overlay-1){
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: radial-gradient(circle at 70% 57%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.85) 49%);
      z-index: 2;
    }
  }

  &:after {
    left: 40%;
    opacity: 0.15;
    background-image: linear-gradient(211deg, #F88383 0%, rgba(248, 180, 195, 0.00) 93%);
    z-index: 1;
  }

  @include media($teal_mobile) {
    background-size: 557px auto;
    background-repeat: no-repeat;
    background-position: 86% top;
  }

  &.hide-title-1{
    .text{
      opacity: 0;
    }
  }

}

.project-teal__header-social {
  position: absolute;
  right: 20px;
  top: 25px;
  display: flex;
  align-items: center;
  line-height: 1;

  .ssk-group {

    margin-left: 10px;

    a {
      background: $white;
      width: 34px;
      height: 34px;
      border-radius: 34px;
      margin-left: 5px;

      &:before {
        font-size: 19px;
        color: $teal;
      }

      &:hover {
        &:before {
          color: $teal;
        }
      }
    }
  }

  a:hover {
    circle {
      fill: $teal;
    }

    g {
      fill: $white;
    }
  }


  @include media($teal_desktopsmall) {
    top: 20px;


    li {
      margin-left: 0;
      margin-right: 5px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
  @include media($teal_mobile) {
    top: auto;
    right: auto;
    bottom: 35px;
    left: 27px;
    z-index: 5;

    ul {
      margin: 0;
    }
  }
}

.project-teal__header-social-label {
  font-size: rem(13);

  @include media($teal_mobile) {
    display: none;
  }
}

.project-teal__header-inner {
  background: url(../../images/build/teal/banner-cover.png) bottom center no-repeat;
  background-size: contain;
  position: relative;
  z-index: 5;

  .container--full {
    z-index: 2;
  }

  &:before {
    content: url(../../images/build/teal/butterfly-2.png);
    position: absolute;
    top: 20%;
    left: 42%;

    @include media($teal_desktopsmall) {
      transform: scale(0.5);
      top: -10px;
    }
    @include media($teal_mobile) {
      left: -10px;
    }

  }

  &:after {
    content: url(../../images/build/teal/butterfly-3.png);
    position: absolute;
    left: 4%;
    bottom: 15%;

    @include media($teal_desktopsmall) {
      transform: scale(0.6);
      bottom: 5%;
    }

    @include media($teal_mobile) {
      left: auto;
      right: -20px;
      bottom: 40%;
    }

  }

  @include media($teal_mobile) {
    background: url(../../images/build/teal/teal-mobile-header.png) bottom -25px center no-repeat;
  }

}

.teal__container {
  max-width: 825px;
  padding: 0 27px;
  margin: 0 auto;

  @include media($teal_desktopsmall) {
    max-width: 560px;

    &--break {
      max-width: none;
    }
  }
}

.project-teal__section-title {
  font-size: rem(36);

  @include media($teal_desktopsmall) {
    font-size: rem(31);
  }
  @include media($teal_mobile) {
    font-size: rem(21);
  }
}

.project-teal__subtitle {

  font-size: rem(17);
  line-height: 1.2;

  @include media($teal_overdesktopsmall) {
    font-size: rem(19);
  }

}

.project-teal__body {
  margin: 70px 0;

  @include media($teal_mobile) {
    margin: 30px 0;
  }
}

.project-teal__body-links {
  padding-top: 48px;
  overflow: hidden;

  a {
    font-size: rem(15);
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 auto;
    @include media($teal_desktopsmall) {
      float: left;
      clear: left;
      margin-bottom: 10px;
    }
  }

  .project-teal__modal-link {
    padding-bottom: 3px;

    &:hover {
      border-bottom: 2px dotted $teal;
    }
  }

  .icon {
    display: inline-block;
    margin-right: 10px;
    @include media($teal_desktopsmall) {
      display: none;
    }
  }

  .file-info {
    display: inline-block;
  }

  .file-size {
    font-size: rem(12);
    color: #B5BDBD;
    font-weight: 600;
  }

  @include media($teal_overdesktopsmall) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include media($teal_mobile) {
    padding-top: 30px;
  }
}

.project-teal__body-body {
  font-size: rem(17);

  p {
    font-size: inherit;
  }

  .read-more {
    font-size: rem(13);
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    margin-top: 25px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 25px;

    span {
      font-size: rem(24);
      font-weight: 600;
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 4px;
    }


  }

  @include media($teal_desktopsmall) {
    font-size: rem(15);
  }

  @include media($teal_overmobile) {
    .read-more {
      display: none;
    }

  }
}

.project-teal__media {
  background: url(../../images/build/teal/flowers-1.png) bottom -20px left -46px no-repeat $light-grey;
  margin-left: 40px;
  margin-right: 40px;
  padding: 65px 0 65px;
  position: relative;

  &:before {
    content: url(../../images/build/teal/butterfly-1.png);
    position: absolute;
    top: -53px;
    right: 6%;
  }

  &:after {
    content: url(../../images/build/teal/flowers-2.png);
    position: absolute;
    bottom: -113px;
    left: 145px;
  }

  .slick-dots {
    text-align: center;
    bottom: -35px;

    li {
      width: 9px;
      height: 9px;
      background: #E9F1F2;

      &.slick-active {
        transform: scale(1);
        background: $teal;
      }

    }
  }

  @include media($teal_desktopsmall) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 47px;
    background-size: 150px auto;
    background-position: bottom 10px left 5px;

    &:before {
      transform: scale(0.6);
      right: 3%;
    }
    &:after {
      transform: scale(0.4);
      left: 40px;
      bottom: -70px;
    }
  }
  @include media($teal_mobile) {
    padding-top: 35px;
  }
}

.project-teal__media-item {
  width: 827px;
  margin: 0;
  outline: none;
  max-width: calc(100vw - 40px);

  .project-teal__media-item-inner {
    transform: scale(1);
    transition: all 0.5s ease-in-out;
    box-shadow: 5px 5px 10px rgba(206, 206, 206, 0.45);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(251, 251, 251, 0.8);
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }

    height: 0;
    padding-top: 56.25%;
    overflow: hidden;

    img, iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  iframe {
    width: 100%;
    height: 463px;
    display: block;
    opacity: 0;
    z-index: -1;
  }

  &.has-video {

    .project-teal__media-item-inner {
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        width: 60px;
        height: 60px;
        background: url(../../images/build/teal/video-button.png) top center no-repeat;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
      }

      &:hover {
        &:before {
          background-position: bottom center;
        }
      }
    }
  }

  &.has-short-video {

    .project-teal__media-item-inner {
      box-shadow: none;
      height: auto;
      padding-top: 0;

      &:after {
        content: none;
      }

      img, iframe {
        position: relative;
        top: initial;
        left: initial;
        width: 380px;
        height: 675px;
        border: 0;
        opacity: 1;
        margin: 0 auto;
        z-index: 1;
      }
    }
  }

  &.slick-current {
    .project-teal__media-item-inner {
      &:after {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
      }
    }

    &.has-video.show-video {
      iframe {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  &:not(.slick-current) {

    cursor: pointer;

    .project-teal__media-item-inner {
      transform: scale(0.9);
      box-shadow: 5px 10px 10px rgba(206, 206, 206, 0);
    }

    .project-teal__media-label {
      opacity: 0;
    }
  }

  @include media($teal_desktopsmall) {
    width: 650px;

    iframe {
      height: 363px;
    }
  }
  @include media($teal_mobile) {
    max-width: 100vw;
    margin: 0;
  }
}

.project-teal__media-label {
  text-align: center;
  font-size: rem(21);
  opacity: 1;
  margin-top: 15px;
  transition: opacity 0.5s ease-in-out;

  @include media($teal_desktopsmall) {
    font-size: rem(17);
  }
}

.project-teal__steps {
  padding: 100px 0 60px;
  max-width: 100vw;
  overflow: hidden;

  @include media($teal_desktopsmall) {
    padding: 60px 0 30px;
  }
}

.project-teal__steps-text {
  font-size: rem(17);
  font-weight: 600;
  margin-bottom: 40px;

  @include media($teal_desktopsmall) {
    font-size: rem(15);
  }
}

.project-teal__steps-subtitle {
  font-size: rem(17);
  font-weight: 700;
}

.project-teal__steps-list {
  padding: 0;
  margin: 50px -7px 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

}

.project-teal__steps-item {
  background: $light-grey;
  text-align: center;
  padding: 0 20px 20px;
  margin: 0 7px 32px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(33.3333% - 14px);
  flex-basis: calc(33.3333% - 14px);

  .project-teal__steps-read-more {
    display: none;
  }

  @include media($teal_mobile) {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &.count-2 {
    max-width: calc(100% - 14px);
    flex-basis: calc(100% - 14px);

    .project-teal__steps-item-content-wrap {
      display: flex;
    }

    .project-teal__steps-item-content {
      flex: 0 0 calc(50% - 25px);
      padding-left: 20px;
      padding-right: 20px;

      @include media($teal_mobile) {
        padding-left: 0;
        padding-right: 0;

        .project-teal__steps-read-more {
          display: block;
          text-align: center;
          margin-top: 15px;
        }

        .project-teal__steps-item-text {
          font-size: rem(11);
        }
      }
    }

    @include media($teal_mobile) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .divider {
    position: relative;
    width: 50px;

    &:before {
      content: '';
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 50%;
      border-left: 1px solid rgba(151, 151, 151, 0.15);
    }
  }

  .divider-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    background: $light-grey;
    color: #9ADFDC;
    font-size: rem(15);
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0;
    text-align: center;
  }
}

.project-teal__steps-number {
  background: url(../../images/build/teal/pattern.jpg) center center no-repeat;
  color: $white;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  display: inline-block;
  font-size: rem(16);
  font-weight: 600;
  padding-top: 6px;
  position: relative;
  top: -11px;
}

.project-teal__steps-item-title {
  font-size: rem(15);
  font-weight: 700;
  margin-bottom: 5px;
}

.project-teal__steps-item-text {
  font-size: rem(15);
  font-weight: 600;


}

.project-teal__steps-item-content {
  position: relative;
  padding-bottom: 70px;

  @include media($teal_desktopsmall) {
    padding-bottom: 60px;
  }
}

.project-teal__steps-link {
  color: $teal;
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(13);
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  right: 0;
  letter-spacing: 0.02em;

  span {
    border-bottom: 2px dotted $teal;
  }
}

.project-teal__faqs {
  position: relative;

  &:after {
    content: url(../../images/build/teal/butterfly-4.png);
    position: absolute;
    bottom: -70px;
    right: 5%;

    @include media($teal_desktopsmall) {
      transform: scale(0.44);
      right: 0;
      bottom: -90px;
    }
  }
}

.project-teal__faqs-list {
  margin-bottom: 30px;
}

.project-teal__faq-item {

  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }

  .letter {
    font-size: rem(18);
    font-weight: 700;
  }

  .text {
    padding-left: 5px;
  }

  .project-teal__faq-q {
    display: flex;
    font-size: rem(17);
    font-weight: 700;
    position: relative;
    cursor: pointer;

    @include media($teal_desktopsmall) {
      font-size: rem(15);
    }

    .letter {
      color: $teal;
    }

    .arrow {
      position: absolute;
      right: 0;
      top: 2px;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background: $border-color;
      line-height: 0.6;
      text-align: center;
      transition: all 0.2s ease-in-out;

      polygon {
        transition: fill 0.2s ease-in-out;
        fill: #454147;
      }
    }
  }

  .project-teal__faq-a {
    font-size: rem(15);
    font-weight: 600;
    margin-top: 5px;
    display: none;

    @include media($teal_desktopsmall) {
      font-size: rem(14);
    }

    .letter {
      color: #F8B5C4;
    }
  }

  &.active {
    .project-teal__faq-q {
      .arrow {
        transform: rotate(180deg);

        polygon {
          fill: $teal;
        }
      }
    }
  }
}

.project-teal__faqs-hidden {
  display: none;
}

.project-teal__toggle-faqs {
  font-size: rem(15);
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;

  @include media($teal_desktopsmall) {
    font-size: rem(13);
  }

}

.project-teal__partners {
  padding-top: 100px;

  @include media($teal_mobile) {
    padding-top: 50px;
  }
}

.project-teal__partners-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7px;
  margin-right: -7px;

  @include media($teal_desktopsmall) {
    justify-content: left;
  }

}

.project-teal__partner {

  background: $light-grey;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 25px;
  flex: 0 1 calc((100% / 3) - 14px);
  text-align: center;
  padding: 20px 0 30px;
  width: calc(33.333% - 14px);
  display: inline-block;
  color: $body-color !important;
  text-decoration: none;

  .project-teal__partner-text {
    padding: 0 20px;
    margin-top: 10px;
    font-size: rem(15);
    line-height: 1.4;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @include media($teal_desktopsmall) {
    flex: 0 1 calc((100% / 2) - 14px);
    margin-bottom: 15px;
  }
  @include media($teal_mobile) {
    .project-teal__partner-text {
      font-size: rem(12);
    }
  }
}

.project-teal__sign-up {
  background: url(../../images/build/teal/curve-bottom.jpg) center bottom no-repeat;
  background-size: 100% auto;
  padding: 120px 0;
  text-align: center;

  @include media($teal_mobile) {
    padding: 50px 0;
  }

}

.project-teal_sign-up-text {
  font-size: rem(15);
  font-weight: 600;
  max-width: 550px;
  margin: 0 auto;
  margin-bottom: 37px;

  @include media($teal_desktopsmall) {
    font-size: rem(14);
  }
}

.project-teal__contact {
  background: $light-grey;
  padding: 42px 0;
  border-bottom: 1px solid #F2F2F2;

  .teal__container {
    position: relative;

    @include media($teal_overmobile) {
      display: flex;
      justify-content: center;
    }

    &:before {
      content: url(../../images/build/teal/butterfly-6.png);
      position: absolute;
      left: -84px;
      bottom: -76px;

      @include media($teal_desktopsmall) {
        left: 0px;
        transform: scale(0.6);
        bottom: -90px;
      }
    }

  }

  @include media($teal_mobile) {
    padding: 25px 0;
  }

}

.project-teal__contact-block {
  color: $body-color;

  @include media($teal_mobile) {
    margin-bottom: 20px;
  }

  &:nth-child(2) {
    @include media($teal_overmobile) {
      margin: 0 50px;
    }
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.project-teal__contact-label {
  font-size: rem(15);
  font-weight: 700;
  margin-bottom: 10px;

  @include media($teal_desktopsmall) {
    font-size: rem(13);
  }
  @include media($teal_mobile) {
    margin-bottom: 0;
  }
}

.project-teal__contact-text {
  font-size: rem(14);
  font-weight: 500;

  @include media($teal_desktopsmall) {
    font-size: rem(13);
  }
}

.project-teal__footer {

}

.project-teal__footer-text {

  font-size: rem(12);
  color: #9C9687;
  text-transform: uppercase;
  font-weight: 500;
  padding: 40px 0 20px;

  a {
    color: #9C9687;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media($teal_overmobile) {
      display: flex;
      justify-content: center;
    }
    @include media($teal_mobile) {
      text-align: center;
    }
  }

  li {
    margin: 0 10px;
    @include media($teal_mobile) {
      margin-bottom: 10px;
      font-size: rem(11);
    }
  }

  @include media($teal_mobile) {
    padding: 25px 0 0;
  }
}

.project-teal__footer-social {

  padding: 20px 0 40px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  li {
    margin: 0 5px;
  }

  circle {
    transition: fill 0.2s ease-in-out;
  }

  a:hover {

    circle {
      fill: #69BFBC;
    }

  }

  @include media($teal_mobile) {
    padding: 10px 0 25px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

#project-teal__modal--clinics {

  padding: 50px 60px 0;

  h2 {
    font-size: rem(21);
    font-weight: 500;
    margin-bottom: 20px;
  }

}

.project-teal__clinics {
  list-style-type: none;
  padding: 0;
  margin: 0 -60px;
  max-width: 642px;
}

.project-teal__clinic {
  display: flex;
  padding: 30px 60px;
  font-size: rem(13);

  &:nth-child(2n -1) {
    background: #FBFBFB;
  }

  .clinic__header-title {
    font-size: rem(15);
    font-weight: 500;
    margin-bottom: 2px;
  }

  .clinic__header {
    flex-grow: 1;
  }

  .clinic__info {
    flex: 0 0 298px;
  }

  .clinic__list-item {
    display: flex;
    padding: 2px 0;
  }

  .clinic__address {
    margin-bottom: 8px;
  }

  .clinic__list-item-label {
    font-weight: 500;
  }

  .clinic__list-item-value {
    margin-left: auto;
  }

  .clinic__phone {
    border-bottom: 1px solid $border-color;
    padding-bottom: 6px;
    margin-bottom: 6px;
  }
}

#project-teal__modal--info {
  padding: 60px 40px 60px 60px;
  overflow: hidden;

  h2 {
    font-size: rem(21);
    font-weight: 500;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  @include media($teal_mobile) {
    padding: 42px 20px;
  }
}


#project-teal__modal--info-inner {
  max-height: calc(100vh - 220px);
  overflow: auto;
  padding-right: 10px;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 100%;
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    display: block;
    background: $teal;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 7px;
  }
}

.project-teal__modal {
  display: none;
  max-width: 800px;
  padding: 60px;
  color: #454147;
  font-size: rem(15);
  max-height: 100vh;

  h2 {
    font-size: rem(21);
    color: #454147;
  }

  p {
    margin-bottom: 15px;
  }

  .fancybox-close-small {
    outline: none;
    right: 27px;
    top: 13px;

    @include media($teal_mobile) {
      top: 5px;
      right: 9px;
    }

    &:after {
      content: url('../../images/build/teal/modal-close.svg');
      background: none;
      outline: none;
      width: 20px;
      height: 20px;
    }
  }

}

.fancybox-slide > div {
  @include media($teal_mobile) {
    margin: 0;
  }
}


.project-teal__partner-image {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 8px;

  img  {
    object-fit: cover;
    object-position: center;
  }
}
