.block-newsletter {
  position:relative;
  @include span-columns(6);
}

.block-getinvolved {
  position:relative;
  @include span-columns(6);


  .content {
    position:absolute;
    top:50%;

  }
  &:after {
    content:"";
    display:block;
    padding-bottom: 50%;
    background:linear-gradient(0deg, rgba(74,43,102,0.95), rgba(74,43,102,0.95)),url(../../images/build/pagination-background.png);
    background-size: cover;
    background-repeat:no-repeat;
  }
}
.events-no-results {
  background:linear-gradient(30deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%),url(../../images/build/no-event-background.jpg);
  background-size: cover;
  background-repeat:no-repeat;
  min-height: 450px;
  position:relative;
  .content-wrapper {
    position:absolute;
    top:50%;
    left:10%;
    transform:translateY(-50%);
    max-width:510px;
    h1 {
      margin-bottom:17px;
    }
    p {
      margin-bottom:25px;
    }
  }
}