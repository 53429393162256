input,
select {
  margin: 0;
  width: 100%;
  outline: none !important;
  font-weight: lighter;
  background: transparent;
  border-radius: 0;
  border: solid 1px $gray_light;
  font-size: em(16);
  padding:0 0 0 16px;
  height: 50px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.webform-component-select {
  position: relative;
  &:after {
    content:'';
    position: absolute;
    right:15px;
    top:50%;
    transform: translateY(-50%) rotate(90deg);
    @include size(14px);
    background:url(../../images/build/more-arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;

  }
}

label {
  font-size: em(16);
  font-weight: lighter;
  line-height: 0.9;
  letter-spacing: -0.14px;
}

textarea {
  outline: none !important;
}

//.field-wrapper {
//  position: relative;
//  display: inline-block;
//  width:49%;
//  overflow: hidden;
//  padding:5px;
//  &.half {
//    width:50%;
//  }
//  &.third {
//    width:33%;
//  }
//  &.quarter {
//    width:25%;
//  }
//
//  input {
//    font-family: Roboto-Regular;
//    font-size: rem(16px);
//    color:rgba(0,0,0,1);
//    background:transparent;
//
//  }
//  input[type=text],input[type=password] {
//    border: solid 1px rgba(242,242,242,1);
//    padding:12px 16px 16px;
//    display: block;
//    width: 100%;
//
//    &:focus {
//      outline: none;
//    }
//
//  }
//  &:before {
//    font-family: Roboto-Light;
//    content: attr(data-label);
//    position: absolute;
//    top:50%;
//    transform: translateY(-50%);
//    left:16px;
//    color: rgba(44,42,46,1);
//    line-height: 2.11;
//    transition:all 153ms linear;
//    z-index:0;
//  }
//  &.active {
//    input[type=text],input[type=password] {
//      padding:18px 16px 10px 16px;
//    }
//    &:before {
//      font-family: Roboto-Light;
//      content: attr(data-label);
//      position: absolute;
//      top:5px;
//      transform: translateY(0);
//      left:16px;
//      font-size: rem(9px);
//      color: #2C2A2E;
//      line-height: 2.11;
//    }
//  }
//  &.full-width {
//    display: block;
//    width:100%;
//    input {
//      width:100%;
//    }
//  }
//}
//.select-wrapper {
//  width:150px;
//  display: inline-block;
//  .chosen-single {
//    transform:translateY(-25px);
//  }
//}
//.form-item {
//  &.form-type-textfield {
//    position: relative;
//    label {
//      position: absolute;
//      left:20px;
//      top:50%;
//      transform:translateY(-50%);
//      color:rgba(0,0,0,0.8);
//      transition: all 153ms ease;
//      &.active {
//        top:5px;
//        transform: translateY(0);
//        left:16px;
//        font-size: rem(9px);
//        color: #2C2A2E;
//        line-height: 2.11;
//        &+input {
//          padding:18px 16px 10px 16px;
//        }
//      }
//    }
//    input {
//      font-family: Roboto-Regular;
//      border: solid 1px rgba(242,242,242,1);
//      padding:12px 16px 16px;
//      font-size: rem(16px);
//      color:rgba(0,0,0,1);
//      background:transparent;
//
//    }
//  }
//  &.form-select {
//    display: inline-block;
//  }
//&.small {
//  width: 137px;
//  display: inline-block;
//  margin-right:10px;
//}
//  &.medium {
//    width: 30%;
//    display: inline-block;
//    margin-right:10px;
//  }
//}


.chosen-container {

  .chosen-single {
    font-weight: lighter;
    background: white;
    border-radius: 0;
    border: solid 1px $gray_light;
    font-size: em(16);
    padding:12px 0 0 16px;
    height: 50px;
    box-shadow: none;

    span {
      margin-right: 0;
    }

    &:after {
      content:'';
      position: absolute;
      right:15px;
      top:50%;
      transform: translateY(-50%) rotate(90deg);
      @include size(14px);
      background:url(../../images/build/more-arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.no-arrow:after {
      display: none !important;
    }

    div {
      display: none;
    }
  }

  &.chosen-container-active .chosen-single {
      background: $white;
      border: solid 1px $gray_light;
  }

  .chosen-drop {
    border: solid 1px $gray_light;
    box-shadow: none;
    font-size: em(16);
    font-weight: lighter;
    background: $white;

    .chosen-results {

      .active-result {
        border-bottom: solid 1px $gray_light;
        padding: 10px 0;
        text-align: center;

        &.highlighted {
          color: $moderate_magenta;
          background: $white;
        }
      }
    }
  }
}