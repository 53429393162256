.node-learn-about-cancer {
  @include clearfix();

  .navigation {
    position: relative;
    z-index: 1;
    float: left;
    @include pad(80px 65px 250px);
    @include size(375px 100vh);
    min-height: 600px;

    &:before, &:after {
      content: '';
      @include size(100%);
      @include position(absolute, 0 null null 0);
    }

    &:before {
      opacity: 0.94;
      background-image: linear-gradient(131deg, #A72F91 0%, #A03893 7%, #7470A0 53%, #41B0AF 100%);
      z-index: -2;
    }

    &:after {
      background: url('../../images/build/pattern-3.png') no-repeat center;
      background-size: cover;
      z-index: -1;
    }

    .nav {
      position: relative;
    }

    .nav-current {
      @include size(4px 9px);
      @include transition(all 333ms linear);
      background: url('../../images/build/nav-bubble.png') no-repeat;
      background-size: 4px auto;
      @include position(absolute, 9px null null 0px);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .nav-item {
      position: relative;
      padding-left: 10px;
      color: $white;
      font-size: em(16);
      letter-spacing: -0.14px;
      line-height: 1.75;
      margin-top: 25px;
      font-weight: lighter;
      cursor: pointer;
      @include transition(all 333ms linear);

      &.current,
      &:hover {
        text-shadow: -0.2px -0.2px 0 #FFF,
        0.2px -0.2px 0 #FFF,
        -0.2px 0.2px 0 #FFF,
        0.2px 0.2px 0 #FFF;
      }
    }

    .sub-item {
      cursor: pointer;
      color: $white;
      font-size: em(14);
      letter-spacing: -0.14px;
      line-height: 1.2;
      margin-top: 15px;
      padding-left: 25px;
    }

    .get-in-touch {
      color: $gray_light;
      font-size: em(16);
      letter-spacing: -0.14px;
      line-height: 1.75;
      font-weight: lighter;
      text-decoration: underline;
      padding-left: 10px;
      margin-top: 25px;
      display: inline-block;
    }

    .mobile-nav-current,
    .mobile-nav-arrow {
      display: none;
    }

    @include media($tablet) {
      width: 300px;
      @include pad(40px 35px);
    }

    @include media($mobile) {
      z-index: 10;
      width: 100%;
      float: none;
      @include pad(18px 25px 17px);
      min-height: 1px;
      height: auto;
      font-size: 14px;

      &.is_stuck {
        top: 83px !important;
      }

      .mobile-nav-current {
        font-size: em(12);
        font-weight: lighter;
        color: $white;
        line-height: 1;
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
          content: '';
          @include size(13px);
          @include position(absolute, 0 0 0 null);
          margin: auto;
          background: url('../../images/build/arrow.svg') no-repeat center 3.5px $moderate_magenta;
          background-size: 4px auto;
          @include transform(rotate(90deg));
          border-radius: 50%;
        }
      }

      .nav {
        display: none;
        @include pad(24px 15px 46px);
      }

      .nav-current {
        left: 0;
        top: 33px;
      }

      .nav-item {
        padding-left: 0;
        font-size: em(14);
        letter-spacing: -0.12px;
        line-height: 1.85;
        margin-bottom: 15px;
      }

      .get-in-touch {
        font-size: em(14);
        letter-spacing: -0.14px;
        line-height: 2;
        padding-left: 0
      }

      .mobile-nav-arrow {
        display: block;
        @include size(30px);
        @include position(absolute, null 0 -22px 0);
        margin: auto;
        background: url('../../images/build/arrow.svg') no-repeat center 7.5px $moderate_magenta;
        background-size: 9px auto;
        @include transform(rotate(-90deg));
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .nav-item:first-of-type {
      display: none;
    }
  }

  .wrapper {
    float: right;
    width: calc(100% - 375px);

    .page-title, .page-text, .item-text {
      max-width: 80%;
    }

    .page-title {
      font-weight: lighter;
      font-size: em(44);
      line-height: 1.23;
      letter-spacing: -0.39px;
      margin-bottom: 19px;
    }

    .page-text {
      font-size: em(15);
      line-height: 1.6;
      margin-bottom: 75px;
    }

    .item-title {
      position: relative;
      font-weight: lighter;
      font-size: em(40);
      line-height: 1.44;
      letter-spacing: -0.32px;

      @include pad(135px 20px);
      margin-bottom: 0;
      text-align: center;
      background-position: center;
      background-size: cover;
      z-index: 1;

      &:before {
        content: '';
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background: rgba(247, 247, 247, 0.87);
        z-index: -1;
      }
    }

    .item-text {
      font-size: em(15);
      line-height: 1.6;
    }

    .qna {
      position: relative;
      padding: 50px 85px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .question {
      font-weight: bold;
      font-size: em(21);
      line-height: 1.29;
      letter-spacing: -0.19px;
      margin-bottom: 16px;
    }

    .answer {
      font-size: em(15);
      line-height: 1.67;
    }

    .image {
      position: relative;
      @include size(100% 360px);
      background-size: cover;
      background-position: center;
      @include transition(all 333ms ease-out);

      &:after {
        content: '';
        @include size(100%);
        @include position(absolute, 0 0 0 0);
        background-image: linear-gradient(134deg, rgba(255, 255, 255, 0.00) 11%, rgba(255, 255, 255, 0.40) 100%);
      }
    }

    .general > .content-wrapper {
      @include pad(75px 85px 95px);
    }

    .cancer-item {
      border-bottom: 1px solid $gray_light;

      &:last-of-type {
        border-bottom: 0;
      }

      img {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }
    }

    .block {
      position: relative;
      @include pad(94px 20px);
      text-align: center;
      background-position: center;
      background-size: cover;
      z-index: 1;

      &:before {
        content: '';
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background: rgba(247, 247, 247, 0.87);
        z-index: -1;
      }

      .block-wrapper {
        max-width: 450px;
        margin: 0 auto;
      }

      .block-title {
        font-weight: lighter;
        font-size: em(36);
        line-height: 1.44;
        letter-spacing: -0.32px;
        margin-bottom: 16px;
      }

      .block-text {
        font-size: em(14);
        line-height: 1.64;
        margin-bottom: 20px;
        color: $dark_very_grayish_violet_2;
      }
    }

    .qna-wrapper {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:before {
        content: '';
        z-index: 0;
        @include size(100%);
        @include position(absolute, 0);
        background: rgba(255, 255, 255, 0.8);
      }

      * {
        z-index: 1;
        position: relative;
      }
    }

    @include media($tablet) {
      width: calc(100% - 300px);

      .page-title, .page-text, .item-text {
        max-width: 95%;
      }

      .general > .content-wrapper {
        @include pad(40px);
      }

      .item-title {
        padding: 100px 20px;
      }

      .qna {
        padding: 40px;
      }

      .block {
        @include pad(40px 20px);
      }
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      margin-top: 35px;

      .page-title {
        font-size: em(30);
        line-height: 1.06;
        letter-spacing: -0.27px;
        margin-bottom: 11px;
      }

      .page-text {
        font-size: em(12);
        line-height: 1.6;
        margin-bottom: 45px;
      }

      .item-title {
        font-size: em(27);
        line-height: 1.1;
        letter-spacing: -0.24px;
        padding: 65px 20px;
      }

      .item-text {
        font-size: em(12);
      }

      .qna {
        padding: 25px 35px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .question {
        font-size: em(19);
        line-height: 1.15;
        letter-spacing: -0.17px;
        margin-bottom: 14px;
      }

      .answer {
        font-size: em(12);
        line-height: 1.58;
      }

      .image {
        @include size(100% 225px);
      }

      .page-title, .page-text, .item-text {
        max-width: 100%;
      }

      .general {
        margin-bottom: 14px;

        .content-wrapper {
          @include pad(25px 30px 35px);
        }
      }

      .block {
        @include pad(35px 30px);

        .block-title {
          font-size: em(27);
        }

        .block-text {
          font-size: em(12);
        }

        .link {
          font-size: em(12);
        }
      }
    }

    .general {
      display: none;
    }
  }
}