.breadcrumb {
  padding: 0;
  margin: 0;
  @include position(absolute, 0 null null 3%);

  a, li {
    font-size: em(10);
    font-weight: 600;
    letter-spacing: 0.21px;
    color: $dark_grayish_violet;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}