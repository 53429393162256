/** PROJECT TEAL FORM **/
.node-teal-form{
  padding: 100px 0 0;

  @include media($teal_mobile){
    padding: 50px 0;
  }

}

.teal__container--form{
  max-width: 992px;
}
.node-teal-form__header{
  text-align: center;
  position: relative;

  &:before{
    content: url(../../images/build/teal/butterfly-5.png);
    position: absolute;
    left: -110px;
    top: 40px;

    @include media($teal_mobile){
      display: none;
    }
  }

  h1.node-teal-form__title{
    color: $body-color;
    font-size: rem(51);
    line-height: 1.1;
    margin-bottom: 20px;

    @include media($teal_mobile){
      font-size: rem(24);
    }
  }
  .node-teal-form__body{
    font-size: rem(19);
  }

}
.teal-form{
  margin: 75px 0;

  @include media($teal_mobile){
    margin: 40px 0;
    margin-left: -27px;
    margin-right: -27px;
    max-width: 100vw;
  }
}
.teal-form__steps{
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
  background: $light-grey;



  li{

    text-align: center;
    font-size: rem(15);
    font-weight: 600;
    padding: 23px;
    overflow: hidden;
    white-space: nowrap;

    @include media($teal_mobile){
      padding: 12px 23px;
    }

    span{
      position: relative;
      z-index: 2;
    }

    &:first-child{
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% + 35px);
    }
    &:last-child{
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% - 107px);

      @supports(display: grid){
        flex-basis: calc(50% - 35px);
      }
    }

    &:first-child{
      color: $white;
      position: relative;
      &:before, &:after{
        content: '';
        position: absolute;
        width: 500px;
        height: 500px;
        background: url(../../images/build/teal/pattern.jpg) top right repeat;
        transform: rotate(45deg);
        right: 105px;
        top: -216px;
        z-index: 1;

        @include media($teal_mobile){
          top: -228px;
          right: 102px;
        }
      }
    }

  }

  &.prev{
    background: url(../../images/build/teal/pattern.jpg) top right repeat;
    li{
      color: $white;

      &:first-child{
        &:before{
          background: #fff;
        }
        &:after{
          opacity: 0.7;
        }

        @include media($teal_mobile){
          display: none;
        }

      }

      &:last-child{
        @include media($teal_mobile) {
          flex: 0 0 100%;
          text-align: left;
        }
      }
    }
  }
}
.teal-form__content{
  padding: 70px 20px;

  &:not(.active){
    display: none;
  }

  @include media($teal_mobile){
    padding: 20px 20px 70px;
  }
}

.teal-form__content, .teal-form__complete{
  position: relative;
  background: $light-grey;
  overflow: visible;

  &:before{
    content: url(../../images/build/teal/flowers-3.png);
    position: absolute;
    bottom: 53px;
    right: -40px;
  }
  &:after{
    content: url(../../images/build/teal/flowers-4.png);
    position: absolute;
    bottom: 185px;
    right: -33px;
    z-index: -1;
  }

  @include media($teal_mobile){
    &:before{
      transform: scale(0.6);
      bottom: -50px;
      right: 0;
    }
    &:after{
      z-index: 1;
      transform: scale(0.5);
      right: -17px;
      bottom: 23px;
    }
  }
}

.teal-form__content-inner{
  max-width: 585px;
  margin: 0 auto;
}

.teal-form__intro{
  font-size: rem(17);

  @include media($teal_mobile){
    font-size: rem(15);
    line-height: 1.2;
  }
}


.teal-form{
  #{$all-text-inputs}, select, .selectize-input{
    background: $white;
    border: 1px solid $border-color;
    font-size: rem(17);
    font-weight: 400;
    box-shadow: none;
    transition: border 0.2s ease-in-out;
    padding: 9px 16px 0;


    &:focus{
      border: 1px solid $teal;
      box-shadow: inset 0px 0px 0px 1px $teal;
    }

    @include placeholder{
      color: transparent;
      font-size: rem(17);
      font-weight: 400;
    }

    @include media($teal_mobile){
      font-size: rem(15);
    }
  }

  .focussed{
    .selectize-input{
      border: 1px solid $teal;
      box-shadow: inset 0px 0px 0px 1px $teal;
    }
  }

  .selectize-input{
    padding: 0 16px;
    input{
      opacity: 0 !important;
    }
    .item{
      padding: 21px 0 12px;
    }
  }
  .selectize-input > input{
    font-size: rem(17);
    font-weight: 400;
    color: $body-color;
    padding: 0;
  }
  .selectize-dropdown{
    border: 1px solid $border-color;
    .option{
      font-size: rem(17);
      border-bottom: 1px solid $border-color;
      padding: 10px 16px;
      &:hover{
        background: #9ADFDC;
      }
    }
  }
  .district-field{
    transition: opacity 0.2s ease-in-out;
  }
  .district-field:not(.active){
    pointer-events: none;
    opacity: 0.4;
  }
  .req-fields-info{
    font-size: rem(12);
  }
}


.teal-form__group-label{
  font-size: rem(17);
  margin-bottom: -23px !important;

  @include media($teal_mobile){
    font-size: rem(15);
    margin-bottom: -18px !important;
  }
}

.form-teal__fields{
    margin: 30px -10px;
    display: flex;
    flex-wrap: wrap;

  .form-item{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 10px);
    max-width: calc(100% - 10px);
    margin: 5px;
    position: relative;

    &.teal-form__checkboxes--large{
      margin-top: 0;

      @supports(display: grid){
        top: 0;
      }
    }

    label{
      position: absolute;
      font-weight: 400;
      transition: all 0.2s ease-in-out;
      left: 16px;
      z-index: 2;
      pointer-events: none;
      padding-right: 50px;
      font-size: rem(9);
      top: 9px;

      @include media($teal_mobile){
        //font-size: rem(15);
      }
    }

    input.placeholder-shown:not(:focus){
      & + label {
        top: 18px;
        font-size: rem(17);
      }
    }

    .selectize-control:not(.focussed):not(.changed){
      & + label {
        top: 18px;
        font-size: rem(17);
      }
    }




    .selectize-input > input{
      @include placeholder(){
        color: transparent;
      }
    }

    @include media($teal_overmobile){
      &.half{
        flex-basis: calc(50% - 10px);
      }
      &.third{
        flex-basis: calc(33.3333% - 10px);
      }
      &.two-third{
        flex-basis: calc(66.6666% - 10px);
      }
    }

    &.error{
      input, select{
        border-color: tomato;
      }
    }
    &.tall{
      .selectize-input{
        padding-top: 10px;
      }
      label{
        top: 22px;
      }
    }
  }

  .teal-form__checkboxes{

    margin-top: 30px;

    & > .form-item{
      margin-bottom: 15px;

      &.error{
        background: none;
        label{
          color: tomato;
        }
      }
    }

    &--large{
      input[type="checkbox"] + label{
        font-size: rem(15) !important;
        font-weight: 600;

      }
      input[type="checkbox"] + label:before{
        top: 2px;
      }
    }

    @include media($teal_mobile){
      margin-top: 20px;
    }

  }

  input[type="checkbox"]{
    display: none;

    & + label{
      position: relative;
      padding-left: 30px;
      font-size: rem(12) !important;
      font-weight: 400;
      line-height: 1.4;
      pointer-events: all;

      &:before{
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #C7C7C7;
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;
      }
    }

    &:checked{
      & + label{
        &:before{
          background: url(../../images/build/teal/tick.svg) center center no-repeat $teal;
          background-color: $teal;
        }
      }
    }
  }

}

.teal-form__actions{
  button:not(:last-child){
    margin-right: 25px;
  }

  @include media($teal_mobile){
    text-align: center;

    button:not(:last-child){
      margin-right: 10px;
    }
  }
}

.fancybox-slide > .teal-modal__content{
  max-width: 809px;
  padding: 83px 56px;
  font-family: 'Assistant', sans-serif;
  color: $body-color;

  h2{
    font-size: rem(21);
    font-weight: 600;
  }
  p{
    font-size: rem(15);
    line-height: 1.4;
    margin-bottom: 30px;
    font-weight: 600;
  }
  a{
    color: $teal;
    font-size: rem(15);
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px dotted $teal;
  }
}

.teal-form__complete{
  background: #FBFBFB;
  padding: 115px 20px;
  display: none;
  margin: 75px 0 100px;

  @include media($teal_mobile){
    margin: 35px -27px 20px;
    padding: 40px 20px;
  }
}
.teal-form__complete-inner{
  max-width: 585px;
  margin: 0 auto;
}
.teal-form__complete-id{
  position: relative;
  font-size: rem(18);
}
.teal-form__complete-title{
  font-size: rem(19);
  font-weight: 600;

  @include media($teal_mobile){
    font-size: rem(15);
  }
}
.teal-form__complete-text{
  font-size: rem(17);
  margin-bottom: 41px;
  @include media($teal_mobile){
    font-size: rem(15);
  }
}
