/* ----- PUT ALL YOUR AMAZING VARIABLES IN HERE ----- */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('//fonts.googleapis.com/earlyaccess/notosanstc.css');

$white: #FFF;
$white_2: #FAFAFA;
$white_3: #F8F8F8;

$gray: #B5B5B5;
$gray_light: #F2F2F2;
$gray_light_2: #CECECE;
$gray_light_3: #F1F1F1;
$gary_very_light: #FEFEFE;
$gary_very_light_2: #F5F5F5;
$gary_very_light_3: #EBEBEB;
$gray_very_light_4: #EDEDED;

$dark: #000;
$dark_gray: #979797;
$dark_grayish_violet: #A19EA3;
$dark_grayish_violet_2: #8F8C91;
$dark_grayish_violet_3: #A9A6AB;
$dark_very_grayish_violet: #3D3940;

$dark_very_violet: #2C2A2E;
$dark_grayish_violet: #88868A;
$dark_very_grayish_violet: #454147;
$dark_very_grayish_violet_2: #605A63;
$dark_very_grayish_violet_3: #353239;

$violet_dark_grayish: #827289;

$red: red;

$moderate_magenta: #B33E96;
$moderate_dark_pink: #A35790;
$moderate_dark_pink_2: #994584;

$cyan_moderate: #4EB6C1;
$cyan_moderate_2: #51AEB8;
$cyan_moderate_3: #50B2BD;
$cyan_dark_moderate: #41B0AF;

$color_main: $dark_very_violet;
$color_link: $dark_very_grayish_violet;
$purple: #4A2B66;

$font_main: 'Roboto', 'Noto Sans TC', sans-serif;
$font_helvetica: 'Helvetica', 'Roboto', sans-serif;

/* Buttons */
$deeppurple: $moderate_magenta;

/* Post Block styling */
$title-size: 16px;
$featured-title-size: em(36);
$featured-category-size: em(15);
$featured-postedon-size: em(13);
$category-size: 0.6em;
$featured-category-size: 2em;
$postedon-size: 0.6em;
$postedon-colour: $dark_very_grayish_violet;
$category-colour: $moderate_magenta;
$post-image-background: $moderate_magenta;
