#footer {
  @include pad(37px 0 26px);
  @include outer-container(2000px);
  text-align: center;
  border-top: 1px solid $gray_light;

  .footer-menu {
    @include pad(11px 0);

    ul {
      margin: 0;
      list-style: none;
      padding: 0;
    }

    li {
      display: inline-block;
      margin-right: 14px;
      font-size: em(12);
      letter-spacing: -0.26px;
      text-transform: uppercase;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: $dark_very_grayish_violet;
      }
    }
  }

  .footer-site-identity {
    @include pad(11px 0 13.5px);
    color: $dark_very_grayish_violet;

    .dot, .site-by, .copyright {
      display: inline-block;
      vertical-align: middle;
    }

    .dot {
      @include size(3px);
      border-radius: 50%;
      background: $dark_very_grayish_violet;
      margin: 0 9px;
    }

    .site-by, .copyright {
      display: inline-block;
      font-size: em(11);
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: $dark_very_grayish_violet;
      }
    }
  }

  .footer-social-media {
    @include pad(13.5px 0 11px);

    ul {
      margin: 0;
      list-style: none;
      padding: 0;
    }

    li {
      display: inline-block;
      @include size(40px);
      margin: 0 10px;
      background: $cyan_moderate_2;
      border-radius: 50%;

      a {
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        @include size(100%);
      }
    }
  }

  @include media($mobile) {
    border: 0;
    @include pad(23px 0 8px);

    .footer-menu {
      position: relative;
      @include pad(30px 0 23px);
      border-top: 1px solid $gray_light;
      border-bottom: 1px solid $gray_light;

      li {
        display: block;
        margin: 0 0 22px;
      }
    }

    .footer-site-identity {
      position: relative;
      @include pad(31px 0 29px);
      border-bottom: 1px solid $gray_light;

      .site-by,
      .copyright {
        display: block;

        &.site-by {
          margin-bottom: 18px;
        }
      }

      .dot {
        display: none;
      }
    }

    .footer-social-media {
      @include pad(26px 0 22px);
    }
  }
}