.node-our-team-page,.page-our-team {
  .header {
    z-index: 1;
    position: relative;
    overflow: hidden;
    @include pad(164px 0);

    .background {
      position: relative;
      z-index: -2;
      @include clearfix();
      @include position(absolute, 0 null null 0);
      @include size(100%);
      overflow: hidden;

      &:before {
        content: '';
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background: rgba(255, 255, 255, 0.8);
        z-index: 1;
      }

      div {
        float: left;
        z-index: -3;
        background-size: cover;
        background-repeat: no-repeat;

        &.unflipped {
          @include size(50% 100%);
        }

        &.flipped {
          margin: -12px;
          overflow: hidden;
          @include transform(scaleX(-1));
          @include filter(blur(18px));
          @include size(calc(50% + 24px) calc(100% + 24px));
        }
      }
    }

    .wrapper {
      position: relative;
      text-align: center;
      @include outer-container();

      &:after {
        content: '';
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        @include size(497px);
        margin: auto;
        z-index: -1;
        background-image: radial-gradient(50% 95%, rgba(255, 255, 255, 0.70) 50%, rgba(255, 255, 255, 0.00) 96%);
      }
    }

    .title {
      font-size: em(44px);
      font-weight: lighter;
      line-height: 1.23;
      letter-spacing: -0.39px;
      margin-bottom: 23px;
    }

    .text {
      max-width: 655px;
      font-size: em(15px);
      line-height: 1.6;
      margin: 0 auto;
    }

    @include media($mobile) {
      @include pad(152px 30px 34px);

      .background {

        div.unflipped {
          @include size(100%);
          background-position: center;
        }

        div.flipped {
          display: none;
        }
      }

      .wrapper {
        text-align: left;

        &:after {
          content: none;
        }
      }

      .title {
        font-size: em(30);
      }

      .text {
        width: 85%;
        min-width: 250px;
        font-size: em(12);
        line-height: 1.6;
        margin-left: 0;
      }
    }
  }

  .our-team {
    @include outer-container();

    .filter {
      display: block;
      @include pad(14px 63px 12px);
      @include clearfix();

      .mobile-cur-filter {
        display: none;
      }

      .filter-btn {
        float: right;
        font-size: em(13);
        margin-left: 46px;
        cursor: pointer;
        color: $dark_grayish_violet;
        text-transform: uppercase;
        @include transition(all 333ms linear);

        &.current {
          color: $dark_very_grayish_violet;
        }

        &:last-of-type {
          margin-left: 0;
        }

        &:hover {
          color: $moderate_magenta;
        }
      }

      @include media($tablet) {
        @include pad(14px 25px 12px);
      }

      @include media($mobile) {
        @include pad(10px 30px 9px);

        .mobile-cur-filter {
          position: relative;
          display: block;
          font-size: em(12);
          color: $dark_very_grayish_violet;
          text-transform: uppercase;
          cursor: pointer;

          &:after {
            content: '';
            @include size(13px);
            @include position(absolute, 0 0 0 null);
            margin: auto;
            background: url('../../images/build/arrow.svg') no-repeat center 3.5px $moderate_magenta;
            background-size: 4px auto;
            @include transform(rotate(90deg));
            border-radius: 50%;
          }
        }

        .filter-list {
          display: none;
          width: 100%;

          .filter-btn {
            display: block;
            float: none;
            font-size: em(12);
            margin: 10px 0;
          }
        }
      }
    }

    .item {
      @include pad(41px 0 43px);
      width: 25%;
      min-height: 355px;
      float: left;
      text-align: center;
      border: 1px solid $gray_light;

      &:hover {

        .image .bg {
          @include transform(scale(1.2));
        }
      }

      .image {
        @include size(110px);
        overflow: hidden;
        margin: 0 auto 25px;
        border-radius: 50%;
        border: 10px solid $white_2;

        .bg {
          background-size: cover;
          background-position: center;
          @include size(100%);
          @include transition(all 333ms linear);
        }
      }

      .type {
        font-size: em(15);
        font-weight: 500;
        color: $moderate_magenta;
        margin-bottom: 9px;
      }

      .name {
        font-size: em(22);
        font-weight: normal;
        line-height: 1.27;
        letter-spacing: -0.2px;
        margin-bottom: 3px;
      }

      .job-title {
        font-size: em(14);
        color: $dark_very_grayish_violet_2;
        font-weight: normal;
        line-height: 1.64;
        margin-bottom: 39px;
      }

      .link {
        font-size: em(14);
      }

      .text {
        display: none;
      }

      @include media($tablet) {
        width: 33.33%;
      }

      @include media($mobile) {
        width: 50%;

        .type {
          font-size: em(13);
          margin-bottom: 6px;
        }

        .name {
          font-size: em(20);
          margin-bottom: 7px;
        }

        .job-title {
          margin-bottom: 14px;
        }

        .link {
          font-size: em(12);
        }
      }

      @include media($lessmobile) {
        width: 100%;
      }
    }

    .view-content {

      @include media($mobile) {
        @include clearfix();
      }
    }

    .block {
      left: inherit !important;
      right: 0;
      z-index: 1;
      width: 50%;

      &:after {
        content: '';
        @include size(100%);
        @include position(absolute, 0 null null 0);
        background: rgba(255, 255, 255, 0.9);
        z-index: -1;
      }

      .wrapper {
        @include position(absolute, 50% 0 null 0);
        margin: auto;
        @include transform(translateY(-50%));
      }

      .title {
        font-size: em(36);
        font-weight: lighter;
        letter-spacing: -0.32px;
        line-height: 1.44;
      }

      .text {
        display: block;
        font-size: em(14);
        line-height: 1.64;
        color: $dark_very_grayish_violet_2;
        max-width: 420px;
        margin: 0 auto 15px;
      }

      @include media($tablet) {
        display: inline-block;
        position: relative;
        width: 100%;
        min-height: 1px;

        .wrapper {
          margin: auto;
          @include transform(translateY(0));
          @include position(relative, 50% inherit inherit inherit);
        }

        .title {
          font-size: em(27);
          margin-bottom: 14px;

          .text {
            display: block;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
