body {
  margin: 0;
  overflow-x: hidden;
}

#content-header {

  .tabs {
    position: absolute;
    right: 0;
    z-index: 100;
  }
}

.default-node {
  @include outer-container();
  @include pad(82px 20px 80px);

  .node-inner {
    max-width: 700px;
    margin: 0 auto;
  }

  h1 {
    font-size: em(43);
    font-weight: lighter;
    line-height: 1.23;
    letter-spacing: -0.39px;
    margin-bottom: 17px;
  }

  .content {

    div {
      margin-bottom: 46px;
    }

    a {
      color: $color_main;
      text-decoration: none;
    }

    p {
      font-size: em(15);
      line-height: 1.6;
      margin: 0 0 28px;
    }

    h2 {
      font-size: em(27);
      font-weight: lighter;
      line-height: 1.04;
      letter-spacing: -0.24px;
      margin-bottom: 21px;
    }

    h3 {
      font-size: em(21);
      font-weight: lighter;
      line-height: 1.33;
      letter-spacing: -0.19px;
      margin: 28px 0 10px;
    }

    h4 {
      font-size: em(13);
      font-weight: normal;
      line-height: 1.54;
      letter-spacing: -0.12px;
      margin: 6px 0 30px;
    }

    ul {
      margin: 20px 0 30px;
      padding: 0;
      list-style: none;

      li {
        padding-left: 30px;
        margin-bottom: 5px;
        line-height: 1.7;
        background: url('../../images/build/dot.png') no-repeat 5px 11px;
        background-size: 5px auto;
      }
    }

    strong {
      font-size: em(15);
      color: $moderate_magenta;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  @include media($tablet) {
    @include pad(40px 30px);
  }

  @include media($mobile) {
    @include pad(27px 30px);

    h1 {
      font-size: em(30);
      margin-bottom: 10px;
    }

    .content {

      div {
        margin-bottom: 25px;
      }

      p {
        font-size: em(12);
        margin: 0 0 20px;
      }

      h2 {
        font-size: em(19);
        line-height: 1.15;
        letter-spacing: -0.17px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: em(16);
        line-height: 1.25;
        letter-spacing: -0.14px;
        margin: 25px 0 10px;
      }

      h4 {
        margin: 6px 0 20px;
      }

      ul {
        margin: 15px 0;

        li {
          padding-left: 25px;
          font-size: em(12);
        }
      }

      strong {
        font-size: em(14);
      }
    }
  }
}