.node-type-event-landing {

  .header {
    position: relative;
    @include pad(162px 0 420px);
    z-index: 0;
    background-size: cover;
    background-position: center;
    text-align: center;
    margin-bottom: -250px;

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.93);
      z-index: -1;
    }

    .title {
      font-size: em(43);
      font-weight: lighter;
      line-height: 1.21;
      letter-spacing: -0.38px;
      margin-bottom: 20px;
    }

    .text {
      max-width: 550px;
      margin: 0 auto;
      line-height: 1.64;
    }

    @include media($desktop) {
      display: none;
    }
  }

  .header-no-result {
    position: relative;
    background-size: cover;
    background-position: center;
    @include pad(99px 0 93px);
    z-index: 0;

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background-image: linear-gradient(-229deg, rgba(255, 255, 255, 0.50) 9%, $white 96%);
      border: 1px solid $dark_gray;
      z-index: -1;
    }

    .content {
      @include outer-container(1200px);
    }

    .text {
      max-width: 510px;
    }

    strong {
      font-size: em(36);
      letter-spacing: -0.32px;
      line-height: 1.3;
      font-weight: normal;
    }

    p {
      max-width: 430px;
      font-size: em(14);
      line-height: 1.64;
      margin-bottom: 24px;
    }
  }

  .core {
    @include outer-container();
    @include clearfix();
    border: solid 1px #f2f2f2;

    .view {
      @include clearfix();

      .view-content {
        &:after {
          content: "";
          clear: both;
          display: table;
        }
      }
    }
  }

  .event-block {
    width: 50%;
    float: left;

    @include media($mobile) {
      width: 100%;
    }
  }

  .event-block-large {
    display: inline-block;
    position: relative;
    width: 100%;
    @include pad(115px 0);
    background-size: cover;
    background-position: center;
    z-index: 0;

    &.no-result {
      float: left;
      width: 50%;
    }

    &:after {
      content: '';
      z-index: -1;
      @include position(absolute, 0 0 0 0);
      background: rgba(74, 43, 102, 0.9);
      @include size(100%);
    }

    .block-wrapper {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
      color: $white;
    }

    .block-title {
      font-size: em(40);
      font-weight: lighter;
      margin-bottom: 14px;
      line-height: 1.3;
      letter-spacing: -0.36px;
    }

    .block-text {
      font-size: em(14);
      line-height: 1.64;
      margin-bottom: 15px;
    }

    .link {
      color: $white;

      &:after {
        background: url('../../images/build/arrow-black.svg') no-repeat center $white;
      }
    }

    @include media($mobile) {
      @include pad(37px 30px 36px);

      .block-title {
        font-size: em(27);
        margin-bottom: 0;
        line-height: 1.92;
        letter-spacing: -0.24px;
      }

      .block-text {
        font-size: em(11);
        line-height: 1.54;
        margin-bottom: 20px;
      }

      .more {
        font-size: em(12);
      }
    }
  }

  .box.w2-h2 {

    h1 {
      margin-bottom: 14px;
    }

    p {
      margin-bottom: 21px;
    }
  }

  .box.w1-h1 {

    &.photo {

      .content {
        top: 50%;
        @include transform(translateY(-50%));
        background-position: center;
      }
    }

    .content {

      h1 {
        font-size: em(21);
      }

      @include media($tablet) {

        h1 {
          font-size: em(18);
        }
      }

      @include media($mobile) {
        @include pad(0 20px 20px);

        h1 {
          font-size: em(16);
        }
      }
    }
  }

  .post {

    &:hover {

      .photo {

        &:before {
          opacity: 0.8;
        }

        .content {
          @include media($overtablet) {
            @include transform(scale(1.15) translateY(-50%));
          }
        }
      }
    }
  }
}

.events-filter {
  padding: 14px 43px;
  width: 100%;
  text-align: right;
  background: white;
  position: relative;
  @include media($mobile) {
    text-align: center;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: em(13);
    color: rgba(69, 65, 71, 1);
    transition: all 333ms linear;

    &:first-of-type {
      padding-right: 30px;
    }

    &:hover {
      color: $moderate_magenta;
    }
  }
}

.node-event {

  .article-content {
    overflow: auto;
    width: 100%;
    @include outer-container(1600px);

    @include media($desktop) {
      @include outer-container(1200px);
    }
  }

  .left-column {
    border-left: solid 1px $gray_light;
    @include pad(82px 50px);
    @include shift(1.5);
    @include span-columns(2.5, block-collapse);

    .event-title {
      font-weight: lighter;
      font-size: em(20);
      letter-spacing: -0.18px;
      line-height: 1.5;
      margin-bottom: 8px;
      color: $dark;
    }

    .event-date {
      font-weight: lighter;
      font-size: em(24);
      letter-spacing: -0.22px;
      line-height: 1.5;
      margin-bottom: 8px;
      color: $dark;
    }

    .event-time {
      font-weight: lighter;
      font-size: em(18);
      letter-spacing: -0.16px;
      line-height: 1.39;
      padding-left: 20px;
      background: url('../../images/build/clock.png') no-repeat left center;
      background-size: 13px;
      color: $dark;
      margin-bottom: 10px;
    }

    @include media($desktop) {
      @include shift(0);
      @include span-columns(3, block-collapse);
    }

    @include media($tablet) {
      @include pad(82px 30px);

      .event-date {
        font-size: em(20);
      }

      .event-time {
        font-size: em(16);
      }
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      text-align: center;
      @include pad(28px 20px 25px);

      .event-date {
        font-size: em(19);
      }

      .event-time {
        font-size: em(12);
        display: inline;
      }

      .go-tickets {
        display: none;
      }
    }
  }

  .right-column {
    @include span-columns(7);
    padding: 93px 138px 93px 99px;
    border-left: solid 1px $gray_light;
    border-right: solid 1px $gray_light;

    .general {
      margin-bottom: 38px;
    }

    .event-details {
      border-left: solid 2px $moderate_magenta;
      padding-left: 26px;
      margin-bottom: 88px;

      .event-details-item {
        &:not(:last-of-type) {
          margin-bottom: 35px;
        }
      }

      .address {
        font-weight: lighter;
        font-size: em(18);
        color: $dark;
        line-height: 1.39;
        letter-spacing: -0.16px;
        margin-bottom: 28px;
      }

      .item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 80px;
        color: $dark;

        .time {
          font-size: em(15);
          line-height: 1.67;
          letter-spacing: -0.13px;
          margin: 0;
        }

        .action {
          font-size: em(18);
          font-weight: lighter;
          line-height: 1.39;
          letter-spacing: -0.16px;
          margin: 0;
          @include media($mobile) {
            display: inline-block;
            padding-left: 20px;
          }
        }
      }
    }

    .additional-information {
      margin-bottom: 95px;
    }

    .speaker-wrapper {
      overflow: hidden;
      margin-bottom: 48px;

      &:last-of-type {
        margin-bottom: 112px;
      }

      .title {
        margin-bottom: 42px;
      }

      .speakers {
        display: flex;
        flex-wrap: wrap;
        gap: 48px;
        align-items: flex-start;
      }

      .speaker {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 24px;
        width: calc((100% - 48px) / 2);
        margin-bottom: 24px;

        .left {
          width: 65px;
          overflow: hidden;

          img {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 65px;
            object-fit: cover;
            object-position: center;
          }
        }

        .right {
          width: calc(100% - 65px - 24px;);
          float: none;

          h3 {
            font-size: em(18);
            line-height: 1.39;
            letter-spacing: -0.16px;
            color: $dark;
            margin-bottom: 7px;
          }

          h4 {
            font-size: em(14);
            line-height: 1.5;
            color: $dark_very_grayish_violet;
            margin-bottom: 8px;
          }

          .more {
            font-size: em(12);
          }
        }
      }

      .speaker-slider {
        display: none;
      }
    }

    .ticket-information {
      //margin-bottom: 100px;

      .title {
        margin-bottom: 29px;
      }

      .product-type {
        position: relative;
        margin-bottom: 60px;
      }

      .timeslot-wrapper {
        display: none;

        &.active {
          display: block;
        }
      }

      .timeslots-hide{
        display: none;
      }

      .product-event {
        p {
          margin-bottom: 0;

          &:last-of-type {
            margin-bottom: 15px;
          }
        }

        h3 {
          margin-bottom: 5px;

          &:not(:first-of-type) {
            margin-top: 20px;
          }
        }
      }

      .product-date {
        display: inline-block;
        cursor: pointer;

        .product-dateslot {
          color: black;
          background: #F0F0F0;
          padding: 10px;
          width: 50px;
          display: inline-block;
          border-radius: 2px;
          margin-right: 5px;
          text-align: center;
          margin-bottom: 10px;
          line-height: 0.9;

          span {
            text-transform: uppercase;
            font-size: 11px;
          }

          &.unavailable {
            opacity: 0.3;
            cursor: not-allowed;
          }

          &.active {
            color: white;
            background: #AE2F8B;
          }
        }


      }

      .product-timeslot {


        color: black;
        background: #F0F0F0;
        padding: 10px 18px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 5px;
        margin-bottom: 10px;
        width: 90px;
        text-align: center;
        cursor: pointer;

        &.active {
          color: white;
          background: #AE2F8B;
        }

        &.unavailable {
          opacity: 0.3;
          cursor: not-allowed;
        }

      }

      .price, .p-name {
        color: $dark;
        font-size: em(18);
        margin-bottom: 6px;
        line-height: 1.39;
        letter-spacing: -0.16px;
        max-width: calc(100% - 150px);

        &.p-name {
          font-weight: lighter;
          margin-bottom: 2px;
        }
      }

      .desc {
        font-size: em(14);
        line-height: 1.64;
      }

      .product-line {
        position: relative;
        margin-bottom: 25px;

        &.drinks {

          .p-name, .price {
            width: calc(100% - 280px);
          }

          .counter_wrapper {
            right: 170px;
          }

          .team_wrapper {
            width: 150px;
            @include position(absolute, 0 0 null null);

            select {
              height: 41px;
              padding: 0 16px;
            }
          }
        }
      }

      .counter_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        @include media($mobile) {
          position: static;
          margin-top: 16px;
        }
      }

      .counter {
        @include position(absolute, 0 0 null null);
        text-align: center;
        border: solid 1px $gray_light;
        @include size(100px 41px);
        padding: 9px 12px 8px;

        .number_spinner {
          width: 35px;
          overflow-y: hidden;
          margin: auto;

          .number {
            margin-top: 0;
            font-size: em(16);
            line-height: 1.5;
          }
        }

        .minus, .plus {
          @include size(19px);
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          @include transition(all 333ms linear);

          &.minus {
            left: 12px;
            background: url('../../images/build/minus.svg') no-repeat center;
          }

          &.plus {
            right: 12px;
            background: url('../../images/build/plus.svg') no-repeat center;

            &.active {
              background: url('../../images/build/plus-white.svg') no-repeat center $moderate_magenta;
            }
          }
        }
      }

      .products-totals {
        text-align: right;
      }

      .total, .addtocartbtn {
        display: inline-block;
        vertical-align: middle;
      }

      .total {
        font-size: em(14);
        color: $dark_grayish_violet;
        line-height: 1.5;
        margin-right: 24px;

        .displayamount, .symbol {
          text-transform: lowercase;
          color: $color_main;
        }
      }
    }

    .product-type {
      .default-button {
        margin-top: 50px;
      }
    }

    .sponsors-content {
      margin-top: 50px;
      margin-bottom: 80px;

      .title {
        margin-bottom: 50px;
      }
    }

    .disclaimer {
      margin-bottom: 100px;

      .title {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: em(27);
      line-height: 1;
      font-weight: lighter;
      letter-spacing: -0.24px;
      margin-bottom: 20px;
      text-transform: capitalize;
      //@include media($mobile) {
      //  font-size: 19px;
      //}
    }

    .sub-title {
      font-size: em(21);
      color: $dark_very_grayish_violet;
      line-height: 1.33;
      font-weight: lighter;
      letter-spacing: -0.19px;
      margin-bottom: 12px;
    }

    .text {
      font-size: em(15);
      line-height: 1.6;
    }

    @include media($desktop) {
      @include span-columns(9);
    }

    @include media($tablet) {
      @include pad(93px 50px 93px 70px);

      .event-details {

        .item {
          margin-right: 50px;

          .action {
            font-size: em(16);
          }
        }
      }
    }

    @include media($mobile) {
      width: 100%;
      float: none;
      @include pad(0 14px);

      .content {
        border-top: 1px solid $gray_light;
        @include pad(34px 18px);
      }

      .general {
        margin-bottom: 27px;
      }

      .event-details {
        padding-left: 14px;
        margin-bottom: 50px;

        .address {
          font-size: em(15);
          line-height: 1.46;
          letter-spacing: -0.13px;
          margin-bottom: 14px;
        }

        .item {
          display: block;
          margin: 0;

          .time {
            font-size: em(13);
            line-height: 1.92;
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            width: 60px;
          }

          .action {
            font-size: em(15);
            line-height: 1.66;
            letter-spacing: -0.13px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .additional-information {
        margin-bottom: 60px;
      }

      .speaker-wrapper {
        margin-bottom: 32px;

        .title {
          margin-bottom: 16px;
        }

        .speakers {
          display: block;
        }

        .speaker {
          width: 100%;
          margin-bottom: 32px;
        }
      }

      .ticket-information {
        //margin-bottom: 95px;

        .title {
          margin-bottom: 16px;
        }

        .product-type {
          margin-bottom: 38px;
        }

        .p-name {
          font-size: em(16);
          margin-bottom: 4px;
          max-width: 100%;
        }

        .price {
          max-width: 100%;
        }

        .desc {
          margin-bottom: 16px;
        }

        .counter {
          position: relative;
          top: inherit;
          border: solid 1px $gray_light;
          @include size(120px 50px);
          padding: 13px 21px 12px;
        }

        .products-totals {
          text-align: left;
        }

        .total {
          margin-right: 17px;
        }
      }

      .sponsors-content {
        margin-top: 24px;
        margin-bottom: 50px;

        .title {
          margin-bottom: 24px;
        }
      }

      .disclaimer {
        margin-bottom: 49px;

        .title {
          margin-bottom: 17px;
        }
      }

      .title {
        font-size: em(19);
        line-height: 1.15;
        letter-spacing: -0.17px;
        margin-bottom: 17px;
      }

      .sub-title {
        font-size: em(16);
        line-height: 1.25;
        letter-spacing: -0.14px;
        margin-bottom: 5px;
      }

      .text {
        font-size: em(12);
      }
    }
  }

  .block-large {
    width: 100%;
    display: inline-block;

    .block:before {
      padding-top: 25%;

      @include media($tablet) {
        padding-top: 35%;
      }

      @include media($mobile) {
        padding-top: 50%;
      }
    }
  }
}


.sectionhead {
  margin-top: 30px !important;
  margin-bottom: 10px !important;

  &.agerange {
    margin-top: 15px !important;
  }
}

.form-wrap {
  &.half {
    width: 50%;
    padding: 0 17px 20px 0;
    display: inline-block;
  }

  position: relative;

  .invalid {
    position: absolute;
    font-size: 11px;
    background: red;
    padding: 1px 5px;
    border-radius: 2px;
    color: white;
    top: -20px;
    transform: translateY(5px);
    opacity: 0;
    transition: all .4s ease-in-out;

    &.visible {
      transform: translateY(0);
      opacity: 1
    }

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      background: red;
      position: absolute;
      display: block;
      bottom: -3px;
      left: 12px;
      z-index: -1;
    }
  }
}

.agerange_wrapper {
  display: flex;

  .form-wrap {

    input[type=radio] {
      display: none;
    }

    input[type=radio] + label {
      padding: 10px 20px;
      background: #F0F0F0;
      transition: all .3s linear;
      border-radius: 2px;
      margin-right: 5px;
      cursor: pointer;
      font-size: 16px;
      color: black;
    }

    input[type=radio]:checked + label {
      background: #AE2F8B;
      color: white;
    }
  }

}

.go-tickets {
  margin-bottom: 80px;
}

.go-link-internal {
  margin-bottom: 80px;
}

.checkin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  h1 {
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.success_wrapper {
    background: lightgreen;

    h1 {
      color: black;
    }
  }

  &.alert_wrapper {
    background: lightsalmon;

    h1 {
      color: black;
    }
  }
}

.register-button {
  margin-top: 20px;
}

input[name=event_email] {
  transition: all .4s ease-in-out;
}

.incorrect {
  border: solid 1px red;
}

.title-pre {
  font-size: 18px;
  margin-bottom: 10px;

}

.event-flipbook {
  width: 100%;
  margin: 60px auto;

  .event-flipbook-container {
    position: relative;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .fullscreen-link {
    display: inline-block;
    margin-top: 8px;
    color: $moderate_magenta;
  }
}
