.node-job-application-form-page {

  @include media($mobile) {
    @include pad(0 30px);
  }

  header {
    text-align: center;
    margin: 56px auto 45px;
    max-width: 590px;

    .title {
      font-size: em(43);
      font-weight: lighter;
      margin-bottom: 7px;
      line-height: 1.21;
      letter-spacing: -0.38px;
    }

    .text {
      font-size: em(14);
      line-height: 1.64;
    }

    @include media($mobile) {
      margin: 30px auto;

      .title {
        font-size: em(30);
      }

      .text {
        font-size: em(12);
      }
    }
  }

  .form {
    max-width: 590px;
    margin: 0 auto 50px;
    border-bottom: 1px solid $gray_light;

    .sub-title,
    .field-name-field-resume legend {
      width: 100%;
      font-size: em(21);
      font-weight: lighter;
      line-height: 1.33;
      letter-spacing: -0.19px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $gray_light;
    }

    .field-type-text,
    .field-type-email {
      position: relative;
      display: inline-block;
      width: calc(50% - 7.5px);
      margin-bottom: 14px;
      border: 1px solid $gray_light;

      &.field-name-field-first-name,
      &.field-name-field-email {
        margin-right: 15px;
      }

      .form-text {
        width: 100%;
      }

      label, .form-required {
        font-size: em(16);
        color: $color_main;
        font-weight: lighter;
        letter-spacing: -0.14px;
        @include transition(all 333ms ease-out);
      }

      .form-item {
        margin: 0;
      }

      input {
        font-size: em(16);
        color: $color_main;
        padding: 12px 15px 6px;
        height: 50px;
      }

      label {
        @include position(absolute, 18px null null 16px);
      }

      &.active {

        label {
          top: 6px;
          font-size: em(9);
          line-height: 2.1;
        }
      }
    }

    .form-type-select {

      label {
        display: none;
      }

      select {
        border: 1px solid $gray_light;
        @include pad(21px 17px);
        font-size: em(16);
        color: $color_main;
        font-weight: lighter;
        letter-spacing: -0.14px;
      }
    }

    .field-type-text-long {
      margin-bottom: 20px;

      label {
        display: none;
      }

      textarea {
        border: 1px solid $gray_light;
        resize: none;
        @include pad(17px 16px);
        font-size: em(16);
        font-weight: lighter;
        letter-spacing: -0.14px;
        color: $color_main;
        margin-bottom: 75px;

        @include placeholder {
          opacity: 1;
          color: $color_main;
        }
      }

      .grippie {
        display: none;
      }
    }

    .field-name-field-resume {

      &.isMobile_upload {

        .form-type-managed-file {
          border: 0;
          @include size(auto);

          &:before {
            content: none;
          }

          label {
            display: block;
            font-size: em(14);
          }

          input[type="file"] {
            @include size(auto);
            font-size: em(13);
            color: $color_main;
            margin-top: 15px;
          }

          input[type="submit"] {
            margin: 0;
            float: right;
          }

          input[type="file"],
          input[type="submit"] {
            display: block;
            opacity: 1;
          }
        }
      }

      .dragdropfile-processed {
        outline: none;

        &:before {
          content: none;
        }
      }

      fieldset, .form-item {
        border: 0;
        margin: 0;
        padding: 0;
      }

      label, .fieldset-description {
        display: none;
      }

      .dragdropfile-table-wrapper {
        height: auto;
        overflow: visible;
        background: $white;
        box-shadow: none;

        .tabledrag-handle,
        .tabledrag-toggle-weight-wrapper {
          display: none;
        }

        table {
          width: 100%;
          margin-bottom: 32px;

          thead {
            display: none;
          }

          tbody {
            border: 0;
          }

          tr {
            border: 0;
            background: $white;
            padding: 0;
            @include size(100% 50px);

            td > .form-submit,
            .file-size, .file-icon,
            .form-type-textfield {
              display: none;
              padding: 0;
            }

            a {
              display: block;
              width: 100%;
              position: relative;
              font-size: em(16);
              font-weight: lighter;
              letter-spacing: -0.14px;
              color: $color_main;
              text-transform: capitalize;
              text-decoration: none;
              @include pad(17px 0 17px 17px);

              &:after {
                content: '';
                @include size(13px);
                border-radius: 50%;
                background-color: $moderate_magenta;
                @include position(absolute, 50% 0 null null);
                @include transform(translateY(-50%));
              }
            }
          }
        }
      }

      .form-type-managed-file {
        cursor: pointer;
        position: relative;
        @include size(100% 150px);
        border: 2px dashed $dark_very_grayish_violet_2;

        &:before {
          content: 'Drag File Here';
          text-transform: uppercase;
          font-size: em(27);
          font-weight: lighter;
          line-height: 1.1;
          letter-spacing: -0.24px;
          color: $dark_very_grayish_violet;
          @include position(absolute, 50% 0 null 0);
          @include transform(translateY(-50%));
          text-align: center;
        }

        input[type="file"] {
          @include size(100% 150px);
        }

        input[type="file"],
        input[type="submit"] {
          display: none;
          opacity: 0;
        }
      }
    }

    .fieldset-description {
      font-size: em(13);
      font-weight: lighter;
      line-height: 1.46;
      margin: 15px 0 0;
      text-align: right;
    }

    .form-submit {
      position: relative;
      display: inline-block;
      font-size: em(14);
      text-transform: uppercase;
      background: $moderate_magenta;
      color: $white;
      border-radius: 100px;
      @include pad(7px 0);
      width: 78px;
      text-align: center;
      text-decoration: none;
      outline: none;
      margin: 75px 0 63px;
    }

    .description {
      display: none;
    }

    @include media($mobile) {
      margin: 0 auto 30px;

      .sub-title,
      .field-name-field-resume legend {
        font-size: em(15);
      }

      .field-type-text,
      .field-type-email {
        width: 100%;
        margin-bottom: 15px;

        label, .form-required {
          font-size: em(14);
        }

        input {
          padding: 14px 15px 4px;
          font-size: em(14);
        }
      }

      .form-type-select {

        select {
          @include pad(21px 17px);
          font-size: em(14);
          background: url('../../images/build/select_arrow.png') no-repeat calc(100% - 17px) center;
          background-size: 13px auto;
        }
      }

      .field-type-text-long {

        textarea {
          font-size: em(14);
          margin-bottom: 50px;
        }
      }

      .field-name-field-resume {

        .dragdropfile-table-wrapper {

          table {

            tr {

              a {
                font-size: em(14);
              }
            }
          }
        }

        .form-type-managed-file {

          &:before {
            font-size: em(15);
          }
        }
      }

      .fieldset-description {
        font-size: em(12);
      }

      .form-submit {
        font-size: em(13);
      }
    }
  }

  .information {
    max-width: 590px;
    margin: 0 auto 85px;

    .infor-title {
      font-size: em(27);
      font-weight: lighter;
      line-height: 1.1;
      letter-spacing: -0.24px;
      margin-bottom: 29px;
    }

    .infor-item {
      display: inline-block;
      width: calc(50% - 80px);
      margin-bottom: 23px;

      &:nth-of-type(2n) {
        margin-left: 25px;
      }
    }

    .item-title {
      font-size: em(15);
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 13px;
    }

    a {
      font-size: em(13);
      font-weight: lighter;
      line-height: 1.46;
      color: $dark_very_violet;
      text-decoration: none;
    }

    @include media($mobile) {
      margin: 0 auto 35px;

      .infor-title {
        font-size: em(20);
        margin-bottom: 15px;
      }

      .infor-item {
        width: 100%;
        margin-bottom: 15px;

        &:nth-of-type(2n) {
          margin-left: 0;
        }
      }

      .item-title {
        font-size: em(14);
        margin-bottom: 10px;
      }

      a {
        font-size: em(12);
      }
    }
  }
}