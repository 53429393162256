.background-404 {
  position: relative;
  min-height: 580px;
  @include pad(58px 0);
  background: linear-gradient(to left, rgba(153, 69, 132, 0.90) 0%, rgba(153, 69, 132, 0.90)), url('../../images/build/404.jpg') no-repeat;
  background-size: cover;

  .cic-1 {
    @include position(absolute, 18px null null -62px);
    @include size(185px 220px);
    background: url('../../images/build/cic-1.png') no-repeat center;
    background-size: 100% auto;
  }

  .cic-2 {
    @include position(absolute, null -41px -25px null);
    @include size(105px);
    background: url('../../images/build/cic-2.png') no-repeat center;
    background-size: 100% auto;

  }

  .circle {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    @include position(absolute, 50% 0 null 0);
    @include size(450px);
    @include transform(translateY(-50%));
    margin: auto;

    .content {
      color: $white;
      max-width: 360px;
      text-align: center;
      margin: auto;
      @include position(absolute, 50% 0 null 0);
      @include transform(translateY(-50%));

      h1 {
        font-size: em(43);
        font-weight: lighter;
        margin-bottom: 18px;
      }

      p {
        font-size: em(16);
        line-height: 1.43;
      }

      .link {
        font-size: em(15);
        margin-top: 35px;
        color: $white;

        &:after {
          background: url('../../images/build/arrow-magenta.svg') no-repeat center $white;
        }
      }
    }
  }

  @include media($mobile) {
    @include pad(89px 25px);

    .cic-1 {
      @include position(absolute, 13px null null -32px);
      @include size(105px 130px);
    }

    .cic-2 {
      @include position(absolute, null -30px 30px null);
      @include size(60px);
    }

    .circle {
      @include size(280px);

      .content {
        max-width: 235px;

        h1 {
          margin-top: 20px;
          font-size: em(30);
          margin-bottom: 13px;
        }

        p {
          font-size: em(12);
          line-height: 1.5;
        }
      }
    }
  }
}